import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import './SignIn.css';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const session = sessionStorage.getItem('supabaseSession');
    if (session) {
      navigate('/app');
    }
  }, [navigate]);

  const handleSignIn = async () => {
    const { data: { user, session }, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      console.error('Error signing in:', error);
    } else {
      console.log('User signed in:', user);
      sessionStorage.setItem('supabaseSession', JSON.stringify(session));
      navigate('/app');
    }
  };

  return (
    <div className="signin-container">
      <img src="/logo_home.png" alt="Logo" style={{width: "150px", marginBottom: "2vh" }} />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="signin-input"
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="signin-input"
      />
      <button onClick={handleSignIn} className="signin-button">Sign In</button>
      {/* <button onClick={() => navigate('/signup')} style={{color: "#8a8a8a", backgroundColor: "transparent", border: "none", cursor: "pointer", paddingTop: "10px"}}>or Signup</button> */}

    </div>
  );
};

export default SignIn;