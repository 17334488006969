import React, { useState } from 'react';
import { callUploadEndpoint, feedBrain } from '../api';
import './UploadButton.css'; 
import { FilePlus } from 'react-feather';
import { useMyContext } from '../contexts/Context'; // Adjust the path as necessary


function UploadButton() {
  const { updateFileUploaded, brain, fileUploaded } = useMyContext();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState('');

  async function handleUpload(event) {
    event.preventDefault();
    if (!selectedFile) {
      console.log('No file selected.');
      return;
    }

    try {
      //await feedBrain(selectedFile, brain);
      const uploadResponse = await callUploadEndpoint(selectedFile, title, brain);
      updateFileUploaded();
      setShowPopup(false); 
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <div className="upload-container">
      <button className="file-upload-label" onClick={(e) => {e.preventDefault(); setShowPopup(!showPopup)}}>
        <FilePlus size={20} className='icon'/>
      </button>
      {showPopup && (
        <div className="upload-popup">
          <input type="file" accept="application/pdf,image/jpeg,image/png" onChange={(e) => setSelectedFile(e.target.files[0])} />
          <button onClick={(e) => {e.preventDefault();handleUpload(e)}}>Upload</button>
        </div>
      )}
    </div>
  );
}

export default UploadButton;