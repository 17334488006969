import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Plot = () => {
  const [chartData, setChartData] = useState({
    labels: ['Addis Ababa', 'Amhara', 'Oromia', 'Tigray', 'SNNPR'],
    datasets: [
      {
        label: 'Soil Conservation',
        data: [12, 19, 15, 8, 11],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
      {
        label: 'Reforestation',
        data: [15, 12, 20, 10, 14],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Water Management',
        data: [8, 15, 12, 9, 7],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Sustainable Agriculture',
        data: [10, 17, 13, 11, 9],
        backgroundColor: 'rgba(255, 206, 86, 0.6)',
      },
    ]
  });

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Prevention Efforts in Ethiopia by Region',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Number of Researchers'
        }
      },
    },
  };

  return (
    <div style={{ width: '100%', margin: '0px' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default Plot;