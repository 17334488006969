import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Brains.css'; 
import { Plus, Play, MoreVertical } from 'react-feather';
import Assistant from './Assistant';
import { useMyContext } from '../contexts/Context';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { supabase } from '../services/supabaseClient';
import { insertBrain, getBrains } from '../services/brainService';
import BrainPopupMenu from './BrainPopupMenu';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';



const modalBackdropStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(20px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#2323234a',
  padding: '20px',
  borderRadius: '0px',
  maxWidth: '800px',
  minWidth: '600px',
  width: '100%',
  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
  border: '0.5px solid #323232',
  color: '#fcfbf2',
  zIndex: 1100, // Ensure the modal content is above the backdrop
};

const Brains = ({ onCardClick, passBrainName }) => {
  const { handleSetBrain, brain } = useMyContext();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState(''); 
  const [description, setDescription] = useState(''); 
  const [cards, setCards] = useState([]);
  const [brainData, setBrainData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [projectDetails, setProjectDetails] = useState('');
  const [autoBrain, setAutoBrain] = useState(false); 
  const [additionalInputs, setAdditionalInputs] = useState([]);


  const handleAddInput = (label) => {
    setAdditionalInputs([...additionalInputs, label]);
  };

  const renderAdditionalInputs = () => {
    return additionalInputs.map((label, index) => (
      <div key={index} className="modal-input-wrapper">
        <label>{label}</label>
        {label == 'Topic'? <p style={{fontSize: '12px', color: '#9a7bc9'}}>Run topic and gap analysis</p> : null}
        <textarea className="modal-input-large" />
      </div>
    ));
  };

  useEffect(() => {
    const fetchBrains = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const userId = session.user.id;
        const brains = await getBrains(userId);
        setBrainData(brains);
        setCards(brains);
      }
    };

    fetchBrains();
  }, [anchorEl]);

  const selectBrain = (brain) => {
    for (let i = 0; i < brainData.length; i++) {
      if (brainData[i].title === brain) {
        let user_id = brainData[i].user_id;
        let id = brainData[i].id;
        const concat = id + '-' + user_id;
        console.log("brain: ", concat);
        return concat;
      }
    }
    return brain;
  };

  const handleCardClick = (card) => {
    const selectedBrain = selectBrain(card.title);
    handleSetBrain(selectedBrain);
    onCardClick(card);
    passBrainName(card.title);
    navigate(`/research/?brain=${card.title}`);
  };

  const selectBrainPopup = (brain) => {
    handleSetBrain(currentBrain => {
      return brain;
    });
  };

  const addNewCard = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      const userId = session.user.id;
      const newCard = {
        user_id: userId,
        title,
        description,
        concepts: 0,
        ideas: 0,
        files: 0,
        outline: projectDetails,
        outline_state: projectDetails,
        auto: autoBrain
      };
      const success = await insertBrain(newCard);
      if (success) {
        setCards([...cards, newCard]);
        setShowModal(false);
        setTitle('');
        setDescription('');
        setProjectDetails('');
        setAutoBrain(false);

      }
    }
  };

  const handleMenuOpen = (event, card) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedCard(card);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCard(null);
  };

  const handleDelete = async (brainId) => {
      setCards(cards.filter(card => card.id !== brainId));
      handleMenuClose();
  };

  return (
    <div className="brains-container">
      <div className="brains-header">
        <h2>Projects<span style={{color: '#469037'}}>.</span></h2>
        <div className="modal-wrapper">
          <Plus size={24} className="plus-icon" onClick={() => setShowModal(true)} />
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            BackdropProps={{ style: modalBackdropStyle }}
          >
            <Box sx={modalContentStyle}>
              <h2 id="modal-title">New Project</h2>
              <div className='modal-input-wrapper'>
                <div className="modal-input-wrapper" style={{marginBottom: '10px'}}>
                  <label>Title</label>
                  <textarea className="modal-input-large" value={title} onChange={(e) => setTitle(e.target.value)}/>
                </div>
            {renderAdditionalInputs()}
            <div className="additional-input-buttons">
              <button className="text-button" onClick={() => handleAddInput('Topic')}> <Plus size={10} />Topic</button>
              <button className="text-button" onClick={() => handleAddInput('Overview')}> <Plus size={10} />Overview</button>
              <button className="text-button" onClick={() => handleAddInput('Goals')}> <Plus size={10} />Goals</button>
              <button className="text-button" onClick={() => handleAddInput('Methodology')}> <Plus size={10} />Methodology</button>
              <button className="text-button" onClick={() => handleAddInput('Requirements')}> <Plus size={10} />Content Requirements</button>
            </div>
            <button className="modal-button" onClick={addNewCard}>Create</button>
          </div>
            </Box>
          </Modal>
        </div>
      </div>
      <div className="grid-container">
        {cards.map((card) => (
          <div key={card.id} className="brain-card" style={{borderRadius: '0px'}} onClick={() => handleCardClick(card)}>
            <div className="card-header">
              <p>{card.title}</p>
            </div>
            <div className="card-header">
              <MoreVertical onClick={(event) => {selectBrainPopup(card.title); handleMenuOpen(event, card);}} />
            </div>
          </div>
        ))}
      </div>
      <BrainPopupMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        onDelete={handleDelete}
        brain={brain}
        brains={cards}
      />
    </div>
  );
};

export default Brains;