import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Product.css'; 
import TopNav from './components/TopNav'; 

function Papers() {
  const papers = [
    { title: 'Simulating 500 million years of evolution with a language model', description: 'This paper presents novel quantum computing algorithms that significantly improve computational efficiency for complex problems.', date: 'July 02, 2024', id: 'simulating-500-million-years-of-evolution-with-a-language-model' },
    { title: 'The Empirical Impact of Neural Parameter Symmetries, or Lack Thereof', description: 'This paper explores the effects of neural parameter symmetries on model performance and training dynamics.', date: 'June 20, 2024', id: 'the-empirical-impact-of-neural-parameter-symmetries-or-lack-thereof' },
    { title: 'Let Your Graph Do the Talking: Encoding Structured Data for LLMs', description: 'A groundbreaking study using language models to simulate and analyze evolutionary processes over an extended timeframe.', date: 'Feb 08, 2024', id: 'let-your-graph-do-the-talking-encoding-structured-data-for-llms' },

  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="product-container">
      <TopNav />
      <section className="overview-section" style={{padding: '0px 20px 20px 20px', border: '0.5px solid #4CAF50', borderRadius: '0px'}}>
        <h1>Shadow<span style={{color: '#4CAF50'}}>.papers</span></h1>
        <p>As part of our mission to keep researchers at the cutting-edge of their industry we're offering a <span style={{color: '#4CAF50'}}>.papers</span> program that tracks the latest research papers and turns them into interactive reports that you can use to explore the papers in a more engaging format.</p>
      </section>
      <section className="overview-section">
        <ul className="product-list">
          {papers.map((paper, index) => (
            <li key={index} className="product-item" onClick={() => toggleDropdown(index)}>
              <div className="product-header">
                <h2>
                  <Link to={`/paper/${paper.id}`} style={{color: '#4CAF50', textDecoration: 'none'}}>
                    {paper.title}
                  </Link>
                </h2>
                <div className="counter-controls">
                  <span style={{color: '#4CAF50'}}>{paper.date}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default Papers;