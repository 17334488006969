import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './FileStore.css'; 
import { Plus, Play, MoreVertical, Search, Upload } from 'react-feather';
import Assistant from './Assistant';
import { useMyContext } from '../contexts/Context';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { supabase } from '../services/supabaseClient';
import { getFiles, findPapers, feedBrain, savePaper, embedPaper } from '../api.js';
import BrainPopupMenu from './BrainPopupMenu';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import UploadButton from './UploadButton';
import moment from 'moment';





const modalBackdropStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(20px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#2323234a',
  padding: '20px',
  borderRadius: '8px',
  maxWidth: '800px',
  minWidth: '800px',
  width: '100%',
  maxHeight: '90vh', // Maximum height is 80% of the viewport height
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
  border: '0.5px solid #323232',
  color: '#fcfbf2',
  zIndex: 1100, // Ensure the modal content is above the backdrop
};

const FileStore = ({ onCardClick, getAllBrains }) => {
  const { handleSetBrain, brain } = useMyContext();
  const [showModal, setShowModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [title, setTitle] = useState(''); 
  const [description, setDescription] = useState(''); 
  const [cards, setCards] = useState([]);
  const [brainData, setBrainData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [projectDetails, setProjectDetails] = useState('');
  const [autoBrain, setAutoBrain] = useState(false); 
  const [files, setFiles] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [userBrains, setUserBrains] = useState([]);
  const [showPopup, setShowPopup] = useState(false);


  const handleOpenUploadModal = () => setShowUploadModal(true);
  const handleCloseUploadModal = () => setShowUploadModal(false);



  useEffect(() => {
    const fetchFiles = async () => {
      const response = await getFiles();
      if (response.data && response.data.files) {
        const fileNames = response.data.files.map(file => file.name);
        setFiles(fileNames);  // Update the state with the list of file names
      }
    };

    fetchFiles();
  }, []);

  useEffect(() => {
    const fetchBrains = async () => {
      let brain_list = []
      const response = getAllBrains();
      response.then(result => {
        result.forEach(brain => {
            brain_list.push({title: brain.title, id: brain.id, user_id: brain.user_id, brain_id: brain.id + '-' + brain.user_id})
  
        });
        setUserBrains(brain_list)
    });
      
    };

    fetchBrains();

  }, [showSearchModal]);

  const selectBrain = (brain) => {
    handleSetBrain(currentBrain => {
      for (let i = 0; i < brainData.length; i++) {
        if (brainData[i].title === brain) {
          let user_id = brainData[i].user_id;
          let id = brainData[i].id;
          const concat = id + '-' + user_id;
          console.log("brain: ", concat);

          return concat;
        }
      }
      return brain;
    });
  };

  const selectBrainPopup = (brain) => {
    handleSetBrain(currentBrain => {
      return brain;
    });
  };

  const addNewCard = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      const userId = session.user.id;
      const newCard = {
        user_id: userId,
        title,
        description,
        concepts: 0,
        ideas: 0,
        files: 0,
        outline: projectDetails,
        outline_state: projectDetails,
        auto: autoBrain
      };
    }
  };

  const handleMenuOpen = (event, card) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedCard(card);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCard(null);
  };

  const handleDelete = async (brainId) => {
      setCards(cards.filter(card => card.id !== brainId));
      handleMenuClose();
  };


  function UploadModal({ open, handleClose, brain }) {
    const [selectedFile, setSelectedFile] = useState(null);
 
  

  
    const handleUpload = async () => {
      if (!selectedFile) {
        console.log('No file selected.');
        return;
      }
  
      try {
        await feedBrain(selectedFile, brain);
        handleClose(); // Close modal after upload
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };
  
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="upload-modal-title"
        aria-describedby="upload-modal-description"
        BackdropProps={{ style: modalBackdropStyle }}

      >
        <Box sx={modalContentStyle}>
          <h2 id="upload-modal-title">Upload File</h2>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: "20px"}}>
          <input style={{padding: "10px", marginBottom: "10px", marginRight: "10px", borderRadius: "5px", border: "1px solid #323232"}} type="file" accept="application/pdf,image/jpeg,image/png" onChange={(e) => setSelectedFile(e.target.files[0])}/>
          <button className="search-modal-button" onClick={handleUpload}>Upload</button>
          </div>
        </Box>
      </Modal>
    );
  }

const PaperCard = ({ key, paper }) => {
  const [showAbstract, setShowAbstract] = useState(false);
  const [showDetails, setShowDetails] = useState(false);


  const handleDownload = async () => {
    if (paper.openAccessPdf) {
      const response = await feedBrain(paper.openAccessPdf.url, 'Main');
      console.log('Download response:', response);
    }
  };

  const handleAddTo = async (paper, brain_id) => {
    await savePaper([paper]); 
    console.log('saved paper')
    setTimeout(async () => {
      const embedded_content = await embedPaper([paper], brain_id);
      console.log('embedded paper');
      return embedded_content;
  }, 3000);

};


  return (
    <div className="paper-card" id={key}>
      <h3><Link to={paper.url} target="_blank" rel="noopener noreferrer">{paper.title}</Link></h3>
      <div>Authors: {paper.authors.map(author => author.name).join(', ')}</div>
      <p className="paper-details">{paper.abstract || 'No abstract available'}</p>
      <button onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? 'Hide Details' : 'See More Details'}
      </button>
      {userBrains.map((brain, index) => (
                              <button onClick={() => handleAddTo(paper, brain.brain_id)} style={{border: "1px solid green", marginLeft: "10px"}}>Add to {brain.title}</button>

                    ))}
      {showDetails && (
        <div className="paper-details">
          {paper.tldr ? <p>TLDR: {paper.tldr.text}</p> : null}
          {paper.publicationTypes ? <p>Journal types: {paper.publicationTypes.map(type => type).join(', ')}</p> : null}
          {paper.fieldsOfStudy ? <p>Fields of Study: {paper.fieldsOfStudy.map(field => field).join(', ')}</p> : null}
          {paper.citationCount ? <p>Citations: {paper.citationCount}</p> : null}
          {paper.referenceCount ? <p>References: {paper.referenceCount}</p> : null}
          <p>Year: {paper.year}</p>
          <p>DOI: {paper.externalIds.DOI}</p>
          <p>ID: {paper.paperId}</p>

          {paper.openAccessPdf && (
            <>
              <Link to={paper.openAccessPdf.url} target="_blank" rel="noopener noreferrer">PDF</Link>
              {/* <button onClick={handleDownload}>Download</button> */}
            </>
          )}
        </div>
      )}
    </div>
  );
};

  const handleSearch = async () => {
    try {
      const response = await findPapers(searchQuery);
      if (response.data) {
        setSearchResults(response.data);
        console.log(JSON.stringify(response.data))
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Failed to fetch papers:', error);
      setSearchResults([]);
    }
    setSearchQuery('');
  };

  return (
    <div className="file-container">
      <div className="brains-header">
        <h2>Content<span style={{color: '#469037'}}>.</span></h2>
        <div className="modal-wrapper">
          <Search size={24} className="plus-icon" onClick={() => setShowSearchModal(true)} style={{ marginRight: '10px' }} />
          <Upload size={24} className="plus-icon" onClick={handleOpenUploadModal} />
          <UploadModal open={showUploadModal} handleClose={handleCloseUploadModal} brain={'Main'} />          
        <Modal
        open={showSearchModal}
        onClose={() => setShowSearchModal(false)}
        aria-labelledby="search-modal-title"
        aria-describedby="search-modal-description"
        BackdropProps={{ style: modalBackdropStyle }}
      >
        <Box sx={modalContentStyle}>
          <h2 id="search-modal-title">Search for papers</h2>
          <div className='search-input-group'>
          <input
            className="search-modal-input"
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Enter search term..."
            style={{ padding: '10px', marginBottom: '10px', marginRight: '10px' }}
          />
          <button className="search-modal-button" onClick={handleSearch}>Search</button>
          </div>
          <div id="search-modal-description" style={{ marginTop: '20px' }}>
          {searchResults.map((paper, index) => (
            <PaperCard key={index} paper={paper} />
          ))}
          </div>
        </Box>
      </Modal>
        </div>
      </div>
      <div className="file-store-container">
      {files.map((file) => (
           <div key={1} className="file-card">
           <div className="card-header">
             <p>{file}</p>
           </div>
           <div className="card-header">
             <Plus size={20} className="plus-icon" onClick={() => setShowModal(true)} />
             <MoreVertical size={20}  />
           </div>
         </div>
        ))}
          {/* <div key={card.id} className="card" onClick={() => { selectBrain(card.title); onCardClick(card); }}>
            <div className="card-header">
              <p>{card.title}</p>
            </div>
            <div className="card-header">
              <Plus size={20} className="plus-icon" onClick={() => setShowModal(true)} />
              <MoreVertical size={20} onClick={(event) => {selectBrainPopup(card.title); handleMenuOpen(event, card);}} />
            </div>
          </div> */}
      </div>
      <BrainPopupMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        onDelete={handleDelete}
        brain={brain}
        brains={cards}
      />
    </div>
  );
};

export default FileStore;