import React, { useState, useEffect } from 'react';
import { supabase } from '../../services/supabaseClient';
import { useNavigate } from 'react-router-dom';
import './SignUp.css';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const session = sessionStorage.getItem('supabaseSession');
    if (session) {
      navigate('/app');
    }
  }, [navigate]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 3000); // 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or if error changes
    }
  }, [error]);

  const handleSignUp = async () => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          display_name: name,
        },
      },
    });

    if (error) {
      setError(error.message);
    } else {
      // Update the user's profile with the display name
      const { error: updateError } = await supabase.auth.updateUser({
        data: { name },
      });

      if (updateError) {
        setError(updateError.message);
      } else {
        console.log('User signed up and profile updated:', data.user);
        navigate('/app');
      }
    }
  };

  return (
    <div className="signup-container">
      <img src="/logo_home.png" alt="Logo" style={{width: "150px", marginBottom: "2vh" }} />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="signup-input"
      />
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="signup-input"
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="signup-input"
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleSignUp} className="signup-button">Sign Up</button>
      <button onClick={() => navigate('/signin')} style={{color: "#8a8a8a", backgroundColor: "transparent", border: "none", cursor: "pointer", paddingTop: "10px"}}>or Login</button>
    </div>
  );
};

export default SignUp;