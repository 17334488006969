import React, { useState } from 'react';
import './Home.css'; 
import TopNav from './components/TopNav'; 
import { Link } from 'react-router-dom';
import HomeCard from './components/HomeCard';
import Footer from './components/Footer';
import heroBG from './heroBG.png';
import { CpuChipIcon, SparklesIcon, Square3Stack3DIcon, DocumentIcon, ClockIcon, ViewfinderCircleIcon, CircleStackIcon, ArrowPathRoundedSquareIcon, CursorArrowRaysIcon, ArrowDownIcon, CubeTransparentIcon, ShareIcon } from '@heroicons/react/24/solid';







function Home() {
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [showNiceMessage, setShowNiceMessage] = useState(false);


  const handleEarlyAccessClick = () => {
    setShowEmailInput(true);
  };

  const handleKeyPress = () => {
      if (email == ''){
        setShowEmailInput(false);
        return;
      }
      fetch('https://formspree.io/f/xvoevekg', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, name: name })
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setShowEmailInput(false);
        setShowNiceMessage(true);
        setTimeout(() => {
          setShowNiceMessage(false);
        }, 3000);
      })
      .catch(error => console.log(error));
    
  };


  return (
    <div className="home-container" style={{ backgroundImage: `url(${heroBG})` }}>
      {/* <TopNav /> */}
      <section className="hero-section">

        <div className="content">
          <div className="titles">
          <h2 className="text-primary" style={{ fontFamily: 'Inter, sans-serif'}}>A highly flexible reporting engine for all your reporting needs          
          <div style={{position: 'static', bottom: '0', left: '50%', height: '100px'}}>
          <ArrowDownIcon style={{color: '#fcfbf2', width: '50px', height: '50px'}} />
          </div>
</h2>
          {/* <p className="tagline">10x your R&D process. Shadow is an AI-native research engine that orchestrates each stage of the research cycle, consolidates data sources, and automates core processes to accelerate discovery.</p> */}
          
          </div>
          <div style={{textAlign: 'left'}}>
      </div>
        </div>

      </section>

      <section className="cards-section" >
     <div className="feature-card">
          <p className="text-primary" style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif'}}>For professionals who love their work, but hate reporting</p>
          {/* <p>Shadow supports every stage of the research process on one platform. From literature sourcing to publication, Shadow makes it easy to manage your research projects and move faster.</p> */}
      </div>
      <div class="card-sections-container">
      <HomeCard 
              title="AI Kernel"
              description="Get our suite of reporting-specific micro-services that can be orchestrated into a modular solution built on your data to power your reporting processes."
              link="/example"
              linkText=""
              className="square"
              examples={[]}
              icon={CpuChipIcon}
            />
    <HomeCard 
              title="Auto-reporting"
              description="Benefit from automated reporting workflows that let you focus on the real work. Shadow’s auto-reporting system is designed to keep you compliant without lifting a finger."
              link="/example"
              linkText=""
              className="square"
              examples={[]}
              icon={SparklesIcon}
            />
      </div>
      <div class="card-sections-container">
      <HomeCard 
              title="Data Orchestration"
              description="Unify all of your data into a single source with state of the art knowledge graphs. Shadow makes it easy to transform multiple data silos into a single source of truth. Configurable to any data and security requirements."
              link="/example"
              linkText=""
              className="square"
              examples={[]}
              icon={Square3Stack3DIcon}
            />
    <HomeCard 
              title="Custom Solutions"
              description="Get the exact reporting experience you want. Shadow is interface agnostic and designed to adapt to your needs and workflow."
              link="/example"
              linkText=""
              className="square"
              examples={[]}
              icon={CursorArrowRaysIcon}
            />
      </div>
      </section>

      <section className="cards-section" style={{marginBottom: '10vh', marginTop: '10vh'}}>
        <div className="deploy-container">
          <div className="deploy-text">
            <p className="text-primary" style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif', display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "5px"}}><CpuChipIcon style={{width: '40px', height: '40px', color: '#469037'}}/> AI Kernel</p>
            <p className="primary-shadow" style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif'}}>next-gen reporting engine</p>
            <p>Deploy our modular AI micro-services designed to level-up your reporting and minimize manual data handing. Our AI Kernel offers a suite of specialized services that can be seamlessly orchestrated to meet your exact data requirements and reporting obligations.</p>
          </div>
        </div>
        <div class="card-sections-container">
              <HomeCard 
              title="Data synchronization"
              description="Bring all your data into one place."
              link="/"
              linkText=""
              className="square"
              examples={[]}
              icon={CircleStackIcon}
            />
            <HomeCard 
              title="Data Transformation"
              description="Build a single source of truth."
              link="/"
              linkText=""
              className="square"
              examples={[]}
              icon={CubeTransparentIcon}
            />
              <HomeCard 
              title="Graph Construction"
              description="Generate a context rich report graph."
              link="/"
              linkText=""
              className="square"
              examples={[]}
              icon={ShareIcon}
            />
      </div>
      <div class="card-sections-container">
            <HomeCard 
              title="Automatic Updates"
              description="Our kernel autonomously checks for changes in your data."
              link="/"
              linkText=""
              className="square"
              examples={[]}
              icon={ArrowPathRoundedSquareIcon}
            />
            <HomeCard 
              title="Retrieval"
              description="Extract the complex insights you need for accurate reporting without the hassle."
              link="/"
              linkText=""
              className="square"
              examples={[]}
              icon={ViewfinderCircleIcon}
            />
      </div>
      </section>
      <section className="cards-section" style={{marginBottom: '10vh', marginTop: '10vh',  border: "3px dashed #469037"}}>
        <div className="deploy-container">
          <div className="deploy-text">
            <p className="text-primary" style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif', display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "5px"}}><SparklesIcon style={{width: '40px', height: '40px', color: '#469037'}}/> Auto-reporting</p>
            <p className="primary-shadow" style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif'}}>because reporting sucks</p>
            <p>Build reports on your report graph automatically. Provide the report parameters, set the reporting schedule, and Shadow will automatically generate a report using the latest data from your graph. Get your reports on time, every time.</p>
          </div>
        </div>
        <div class="card-sections-container">
              <HomeCard 
              title="Templating"
              description="Populate any standard report with your data"
              link="/"
              linkText=""
              className="square"
              examples={[]}
              icon={DocumentIcon}
            />
            <HomeCard 
              title="Scheduling"
              description="Get your reports exactly when you need them."
              link="/"
              linkText=""
              className="square"
              examples={[]}
              icon={ClockIcon}
            />
      </div>
      </section>
      <section className="hero-section" style={{borderBottom: 'none'}}>
        <div className="content">
          <div className="titles">
          <h2 className="text-primary" style={{fontFamily: 'Inter, sans-serif'}}>Level up your reporting today with an AI reporting engine built specifically for you</h2>
          </div>
          {showEmailInput ? (
          <div className='button-wrapper'>
          <input type="text" placeholder="Enter your name" className="start-button" value={name} onChange={(e) => setName(e.target.value)}  style={{border: '1px solid #000'}}/>
          <input type="text" placeholder="Enter your email" className="start-button" value={email} onChange={(e) => setEmail(e.target.value)}  style={{border: '1px solid #000'}}/>
          <button className="start-button" style={{border: '1px solid #000'}} onClick={handleKeyPress} >
            Submit
          </button>
          </div>
        ) : (
          <div clasName='dynamic-container'>
            {showNiceMessage && <p style={{color: '#469037', textAlign: 'center', fontWeight: 'bold'}}>Welcome. We'll reach out soon.</p>}
            <div className='button-container'>
            <div className="start-button" onClick={handleEarlyAccessClick} style={{border: '1px solid #000'}}>
            <h2  style={{fontFamily: 'IBM Plex Mono, sans-serif'}}>Request a demo<span style={{color: '#469037'}}>.</span></h2>
            </div>
          </div>
          </div>
        )}
        </div>
      </section>
      {/* <Footer /> */}

    </div>
  );
}

export default Home;