import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = Icon.extend({
  options: {
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  }
});

Icon.Default.mergeOptions({
  iconRetinaUrl: icon,
  iconUrl: icon,
  shadowUrl: iconShadow
});

function MapUpdater({ markers }) {
  const map = useMap();
  useEffect(() => {
    if (markers.length > 0) {
      const bounds = markers.map(marker => marker.position);
      map.fitBounds(bounds);
    }
  }, [markers, map]);
  return null;
}

const MapChart = ({ markers }) => {
  return (
    <MapContainer center={[0, 0]} zoom={2} style={{ width: "100%", height: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapUpdater markers={markers} />
      {markers.map((marker) => (
        <Marker key={marker.name} position={marker.position} icon={new DefaultIcon()}>
          <Popup>
            <div style={{maxWidth: '300px'}}>
              <h3>{marker.name}</h3>
              <p><strong>Description:</strong> {marker.description}</p>
              <p><strong>Research Paper:</strong> {marker.paper}</p>
              <p><strong>Key Stakeholder:</strong> {marker.stakeholder}</p>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapChart;