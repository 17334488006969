import React, { useState } from 'react';
import './Home.css'; 
import TopNav from './components/TopNav'; 
import { Link } from 'react-router-dom';
import HomeCard from './components/HomeCard';
import Footer from './components/Footer';






function Paladin() {
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [showNiceMessage, setShowNiceMessage] = useState(false);


  const handleEarlyAccessClick = () => {
    setShowEmailInput(true);
  };

  const handleKeyPress = () => {
      if (email == ''){
        setShowEmailInput(false);
        return;
      }
      fetch('https://formspree.io/f/xvoevekg', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, name: name })
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setShowEmailInput(false);
        setShowNiceMessage(true);
        setTimeout(() => {
          setShowNiceMessage(false);
        }, 3000);
      })
      .catch(error => console.log(error));
    
  };


  return (
    <div className="home-container">
      <TopNav />
      <section className="hero-section">
        <div className="content">
          <div className="titles">
          <h2 className="text-primary" style={{ fontFamily: 'Arial, sans-serif',}}>
          Shadow<span style={{color: '#469037'}}>.</span>paladin
          <p className="tagline">Custom newsletters, automatic reporting</p>
</h2>
          {/* <p className="tagline">10x your R&D process. Shadow is an AI-native research engine that orchestrates each stage of the research cycle, consolidates data sources, and automates core processes to accelerate discovery.</p> */}
          </div>
          <div style={{textAlign: 'left'}}>
        {showEmailInput ? (
          <div className='button-wrapper' >
          <input type="text" placeholder="Enter your name" className="start-button" value={name} onChange={(e) => setName(e.target.value)}  style={{border: '1px solid #000'}}/>
          <input type="text" placeholder="Enter your email" className="start-button" value={email} onChange={(e) => setEmail(e.target.value)}  style={{border: '1px solid #000'}}/>
          <button className="start-button" style={{border: '1px solid #000'}} onClick={handleKeyPress} >
            Submit
          </button>
          </div>
        ) : (
          <div clasName='dynamic-container'>
            {showNiceMessage && <p style={{color: '#469037', textAlign: 'center', fontWeight: 'bold'}}>Welcome. We'll reach out soon.</p>}
            <div className='button-container' >
            <div className="start-button" onClick={handleEarlyAccessClick} style={{border: '1px solid #000'}}>
              Get started
            </div>
          </div>
          </div>
        )}
      </div>
        </div>
      </section>
      {/* <p className="tagline" style={{textAlign: 'center', width: '100%', color: 'white', fontWeight: 'bold'}}>Powering research at:</p>
      <section className="company-logos-section" style={{paddingBottom: '20px', textAlign: 'center', borderTop: '0.5px solid #469037' }}>
        <div className="company-logos" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', overflowX: 'auto' }}>
          <img src="/stanford.png" alt="Company 1" className="company-logo" style={{ margin: '0 15px', height: '80px', borderRadius: '6px' }} />
          <img src="/oxford.png" alt="Company 2" className="company-logo" style={{ margin: '0 15px', height: '80px', borderRadius: '6px' }} />
          <img src="/mcgill.png" alt="Company 3" className="company-logo" style={{ margin: '0 15px', height: '80px', borderRadius: '6px' }} />
          <img src="/inworld.png" alt="Company 3" className="company-logo" style={{ margin: '0 15px', height: '80px', borderRadius: '6px' }} />
          <img src="/hampton.png" alt="Company 3" className="company-logo" style={{ margin: '0 15px', height: '80px', borderRadius: '6px' }} />
          <img src="/mila.png" alt="Company 3" className="company-logo" style={{ margin: '0 15px', height: '80px', borderRadius: '6px' }} />

        </div>
      </section> */}
      <section className="cards-section" style={{paddingBottom: '10vh'}}>
     <div className="feature-card">
          <p className="text-primary" style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif'}}>Custom Newsletters<span style={{color: '#469037'}}>.</span></p>
          <p className="primary-shadow" style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif'}}>stay informed, your way</p>
          <p>Inspired by the success of newsletters like AlphaSignal, Paladin empowers every individual and organization to create fully personalized newsletters on topics that matter most to them.</p>
          {/* <p>Shadow supports every stage of the research process on one platform. From literature sourcing to publication, Shadow makes it easy to manage your research projects and move faster.</p> */}
      </div>
      <div className="card-grid">
            {/* Insert Card components here */}
            <HomeCard 
              title="Easy Creation Process"
              description="Specify what you want the newsletter to cover and select which sources to track. Once set up, Paladin autonomously generates a daily newsletter for you, your team, or your entire organization. "
              link="/example"
              linkText=""
              className="square"
              examples={["Google Scholar", "OpenReview", "Git", "Google", "Arxiv", "Product Hunt", "Twitter", "Substack", "Semantic Scholar"]}

            />
            <HomeCard 
              title="Private Data Integration"
              description="Identify relevant papers, events, tools, or market shifts specific to your product, technology, or service by mounting private data for cross-analysis."
              link="/example"
              linkText=""
              className="square"
              examples={["Product Documentation", "Target Market", "Marketing Strategy", "Tech Stack", "R&D projects"]}

            />
            <HomeCard 
              title="Keep Alive"
              description="Continuously monitor the ecosystem for new content and automatically update your reports."
              link="/example"
              linkText=""
              className="square"
              examples={["Instant updates", "Notifications", "Direct to email", "Customizable frequency"]}

            />

            {/* Add more cards as needed */}
          </div>
      </section>
      <section className="cards-section" style={{paddingBottom: '10vh'}}>
        <div className="deploy-container">
          <div className="deploy-text">
            <p className="text-primary" style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif'}}>Automatic Reporting<span style={{color: '#469037'}}>.</span></p>
            <p className="primary-shadow" style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif'}}>for continuous insights</p>
            <p>We understand that reporting is a necessary but often tedious part of company operations. Everyone has to do it, but few enjoy the process. We recognize the crucial role reporting plays in driving informed decision-making and maintaining operational excellence.

<br/><br/>Our reporting solution, Paladin, transforms this essential task into an effortless and multi-purpose process:

<br/>
<ul>
<li>Generate standard reports for core company operations using private data</li>

<li>Design custom reports to keep your product team updated on market trends and relevant technologies
</li>
<li>Identify new product opportunities as they arise
</li>
</ul>

With Paladin, reporting becomes a powerful tool for driving your business forward, rather than a time-consuming obligation.</p>
          </div>
          <div className="card-grid">
            {/* Insert Card components here */}
            <HomeCard 
              title="Auto-reporting"
              description="Generate core and custom reports on any data"
              link="/example"
              linkText=""
              className="square"
            />
            <HomeCard 
              title="Continous Reporting"
              description="Stay up to date as your data evolves"
              link="/example"
              linkText=""
              className="square"
            />
            <HomeCard 
              title="Custom Newletters"
              description="Keep all your teams at the cutting-edge"
              link="/example"
              linkText=""
              className="square"
              examples={["ML", "Marketing", "Exec", "Sales"]}

            />

            {/* Add more cards as needed */}
          </div>
        </div>
      </section>
      <section className="hero-section" style={{borderBottom: 'none', paddingTop: '10vh', marginTop: '0', marginBottom: '0'}}>
        <div className="content">
          <div className="titles">
          <h2 className="text-primary" style={{fontFamily: 'Arial, sans-serif'}}>Get started, stay informed<span style={{color: '#469037'}}>.</span></h2>
          </div>
          <div style={{textAlign: 'left'}}>
        {showEmailInput ? (
          <div className='button-wrapper' >
          <input type="text" placeholder="Enter your name" className="start-button" value={name} onChange={(e) => setName(e.target.value)}  style={{border: '1px solid #000'}}/>
          <input type="text" placeholder="Enter your email" className="start-button" value={email} onChange={(e) => setEmail(e.target.value)}  style={{border: '1px solid #000'}}/>
          <button className="start-button" style={{border: '1px solid #000'}} onClick={handleKeyPress} >
            Submit
          </button>
          </div>
        ) : (
          <div clasName='dynamic-container'>
            {showNiceMessage && <p style={{color: '#469037', textAlign: 'center', fontWeight: 'bold'}}>Welcome. We'll reach out soon.</p>}
            <div className='button-container' >
            <div className="start-button" onClick={handleEarlyAccessClick} style={{border: '1px solid #000'}}>
              Get started
            </div>
          </div>
          </div>
        )}
      </div>
        </div>
      </section>
      <Footer />

    </div>
  );
}

export default Paladin;