import axios from 'axios';

//FOR LOCAL TESTING ALWAYS USE PORT 8080 UNLESS THE DOCKER CONTAINER IS DEPLOYED ON ANOTHER ENDPOINT


// to embed a new typed thought
// to retrieve existing thought
export const callAssistant = (query, brain) => {
    const data = {
        query: query,
        brain: brain
    }
    return axios({method: 'POST', url: `http://127.0.0.1:8080/assistant`, data: data});
};

//https://jessendgibbs--shadow-play-fastapi-app.modal.run/table-query
export const callDeploy = (prompt) => {
    return axios.post(`https://jessendgibbs--shadow-play-fastapi-app.modal.run/table-query`, { prompt });
};

//https://jessendgibbs--shadow-play-fastapi-app-dev.modal.run
export const getFiles = () => {
    return axios.get(`https://jessendgibbs--shadow-play-fastapi-app.modal.run/list-files`);
};

// to embed a new typed thought
export const sendToBackend = (input, title, type, brain) => {
    const data = {
        input: input,
        title: title,
        type: type,
        brain: brain
    }
  return axios({method: 'POST', url: `http://127.0.0.1:8080/echo`, data: data});
};

// get calendar content
export const getCalContent = () => {
    return axios.get(`http://127.0.0.1:8080/calendarContent`);
  };

// to delete chroma entry
export const sendToDelete = (id) => {
    const data = {
        id: id
    }
    console.log("\n\n\ndeleting:", data, "\n\n\n")
    return axios({method: 'POST', url: `http://127.0.0.1:8080/delete`, data: data});
  };

// to retrieve existing thought
export const callShowEndpoint = (input, searchMode, brain) => {
    const data = {
        searchMode: searchMode,
        input: input,
        brain: brain
    }
    return axios({method: 'POST', withCredentialsc: true, url: `http://127.0.0.1:8080/show`, data: data});
};

// to upload a pdf file and embed it
export const callUploadEndpoint = (file, title, brain) => {
const formData = new FormData();
formData.append('file', file);
formData.append('title', title);
formData.append('brain', brain);

//https://jessendgibbs--shadow-play-fastapi-app.modal.run/feed-brain
return axios.post(`http://127.0.0.1:8080/upload`, formData, {
    headers: {
    'Content-Type': 'multipart/form-data'
}});
};

// to upload a pdf file and save it
export const feedBrain = (file, brain) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('brain', brain);
    
    //https://jessendgibbs--shadow-play-fastapi-app.modal.run/feed-brain
    return axios.post(`https://jessendgibbs--shadow-play-fastapi-app.modal.run/feed-brain`, formData, {
        headers: {
        'Content-Type': 'multipart/form-data'
    }});
    };


// save paper from search results
export const savePaper = (paper) => {
    console.log("paper:", paper)

    const sanitizedTitle = paper[0].title.replace(/[^a-zA-Z0-9 ]/g, '');


    const saveData = new FormData();
    saveData.append('url', paper[0].openAccessPdf.url); 
    saveData.append('file_name', sanitizedTitle + ".pdf");  
 

    axios.post(`https://jessendgibbs--shadow-play-fastapi-app.modal.run/save`, saveData, {
        headers: {
        'Content-Type': 'multipart/form-data'
    }});

    const formData = new FormData();
    formData.append('papers', JSON.stringify(paper));  

    //https://jessendgibbs--shadow-play-fastapi-app.modal.run/feed-brain
    return axios.post(`https://jessendgibbs--shadow-play-fastapi-app.modal.run/add-sourced-papers`, formData, {
        headers: {
        'Content-Type': 'multipart/form-data'
    }});

    };

export const embedPaper = (paper, brain) => {
    const embedData = new FormData();

    const sanitizedTitle = paper[0].title.replace(/[^a-zA-Z0-9 ]/g, '');

    embedData.append('file', sanitizedTitle + ".pdf"); 
    embedData.append('brain', brain); 

    return axios.post(`http://127.0.0.1:8080/load-file`, embedData, {
        headers: {
        'Content-Type': 'multipart/form-data'
    }});
}

// search papers from Semantic Scholar
export const findPapers = (query) => {
    const formData = new FormData();
    formData.append('query', query);
 
    return axios.post(`https://jessendgibbs--shadow-play-fastapi-app.modal.run/find-papers`, formData, {
        headers: {
        'x-api-key': 'KssbErlQUT5o7IOXPMObW3cUbDf7P0mJ3IiHMmvB'
    }});
    };

export const callDeleteBrain = (brain) => {
    const formData = new FormData();
    formData.append('brain', brain);
    
    
    return axios.post(`http://127.0.0.1:8080/deletebrain`, formData);
    };

// invoke agent
// to convert the ave pdf to an image file
export const callAgent = (question, brain) => {
    return axios.post(`http://127.0.0.1:8080/agent`, { question, brain });
};

//get file from Docker
export const getFile = (filePath) => {
    console.log("getting file", filePath)
    return axios.get(`http://127.0.0.1:8080/getFile?file_path=${filePath}`);
};
// to convert the ave pdf to an image file
export const callConvertEndpoint = (filePath, title, brain) => {
    return axios.post(`http://127.0.0.1:8080/convert`, { filePath, title, brain });
};

// reset chroma stores
export const callReset = () => {
    return axios.post(`http://127.0.0.1:8080/reset`, {});
};

// get notion page
export const callNotion = (page) => {
    return axios.get(`http://127.0.0.1:8080/notion?page_id=${page}`);
};

// list dropbox files
export const callDropbox = (path, apiKey) => {
    console.log("path", path)
    console.log("api key", apiKey)
    return axios.get(`http://127.0.0.1:8080/list-files?folder_path=${path}&api_key=${apiKey}`);
};


