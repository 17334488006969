import React, { useState, useEffect } from 'react';
import { callShowEndpoint, callReset, callDeploy, callAgent } from '../api';
import DocumentCard from './DocumentCard';
import './ShowComponent.css'; 
import {Search, ArrowUpRight } from 'react-feather';
import { useMyContext } from '../contexts/Context';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid } from '@mui/material';
import MapChart from './Map';
import Plot from './Plot';
import TableWidget from './Table';
import D3Graph from './D3Graph';


function ShowComponent() {
  const [widgets, setWidgets] = useState([]); // New state for storing widgets
  const [input, setInput] = useState('');
  const [prompt, setPrompt] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [test, setTest] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(null);
  const [searchMode, setSearchMode] = useState('everything'); // Default to 'everything'
  const [viewMode, setViewMode] = useState('list'); // Default to 'list'
  const [isFiltered, setIsFiltered] = useState(false); // Default to 'list'
  const [selectedType, setSelectedType] = useState(null); // New state for tracking the selected document type
  const { graphData, setGraphData, graphView, brain } = useMyContext();



  function filterDataByType(data, type) {
    if (type === selectedType) {


      setFiltered(null);
      setIsFiltered(false);
      setSelectedType(null);

    } else {

      const filteredData = {
        ids: [[]],
        distances: [[]],
        metadatas: [[]],
        documents: [[]],
        embeddings: data.embeddings,
        uris: data.uris,
        data: data.data
      };

      if (data.metadatas[0]) {
        data.metadatas[0].forEach((metadata, index) => {
          if (metadata.type === type) {
            filteredData.metadatas[0].push(metadata);
            if (data.ids && data.ids[0]) filteredData.ids[0].push(data.ids[0][index]);
            if (data.distances && data.distances[0]) filteredData.distances[0].push(data.distances[0][index]);
            if (data.documents && data.documents[0]) filteredData.documents[0].push(data.documents[0][index]);
          }
        });
      }
      // Update the state with the filtered data and mark the view as filtered
      setFiltered(filteredData);
      setIsFiltered(true);
      setSelectedType(type);
    }
  };

  function createStructuredItems(data) {
    const structuredItems = [];
    const typeToIdMap = {}; // Maps type to its corresponding index in structuredItems
    const pathAdded = {}; // Maps type to a set of paths already added
    let nextId = 1; // Start ID for children
  
    if (data && Array.isArray(data.metadatas) && Array.isArray(data.documents) && data.metadatas.length > 0 && Array.isArray(data.metadatas[0]) && Array.isArray(data.documents[0])) {
      data.metadatas[0].forEach((metadata, index) => {
        if (metadata && metadata.type && metadata.path) { // Ensure both type and path are defined
          const { type, path } = metadata;
          let label = path.split('/').pop(); // Get the last segment of the path by default
  
          if (type === 'concept' || type === 'shadow note') {
            label = data.documents[0][index]; // Use the associated document for 'concept' and 'shadow note' types
          }
  
          if (!typeToIdMap.hasOwnProperty(type)) {
            // Create a new category if it doesn't exist
            const newId = structuredItems.length + 1;
            typeToIdMap[type] = newId;
            pathAdded[type] = new Set(); // Initialize a set to track paths for this type
            structuredItems.push({
              id: newId, // Assign a new ID to the category
              label: type,
              children: []
            });
          }
          // Check if the path has already been added to avoid duplicates
          if (!pathAdded[type].has(label)) {
            pathAdded[type].add(label); // Mark this path as added
            // Add the path as a child under the correct type
            structuredItems[typeToIdMap[type] - 1].children.push({
              id: `${typeToIdMap[type]}-${nextId++}`, // Create a unique ID for the child
              label: label // Use the label determined above
            });
          }
        }
      });
    }
  
    return structuredItems;
  }

  function groupDocumentsByPath(data) {
    const groupedDocuments = {};
    if (data && data.metadatas[0] && data.documents[0]) {
      data.metadatas[0].forEach((metadata, index) => {
        const path = metadata.path;
        if (!groupedDocuments[path]) {
          groupedDocuments[path] = {
            metadatas: [],
            documents: []
          };
        }
        groupedDocuments[path].metadatas.push(metadata);
        groupedDocuments[path].documents.push(data.documents[0][index]);
      });

    Object.keys(groupedDocuments).forEach((path) => {

      const group = groupedDocuments[path];
      const sortedIndices = group.metadatas
        .map((metadata, index) => ({ index, page: metadata.page }))
        .sort((a, b) => a.page - b.page)
        .map(sortedItem => sortedItem.index);

      groupedDocuments[path].metadatas = sortedIndices.map(index => group.metadatas[index]);
      groupedDocuments[path].documents = sortedIndices.map(index => group.documents[index]);
    });
  }
  return groupedDocuments;
  };

    const markers = [
    {
      name: "Dembecha",
      position: [10.625, 37.29],
      description: "Main study area",
      paper: "Zeleke, G. and Hurni, H. (2001). Implications of Land Use and Land Cover Dynamics for Mountain Resource Degradation in the Northwestern Ethiopian Highlands.",
      stakeholder: "Local farmers and agricultural officials"
    },
    {
      name: "Mt. Choke",
      position: [10.66, 37.85],
      description: "Referenced mountain",
      paper: "Zeleke, G. and Hurni, H. (2001). Implications of Land Use and Land Cover Dynamics for Mountain Resource Degradation in the Northwestern Ethiopian Highlands.",
      stakeholder: "Environmental conservation groups"
    },
    {
      name: "Addis Ababa",
      position: [9.02, 38.74],
      description: "Capital city, affected by urban planning issues related to environmental degradation",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "National policymakers and urban planners"
    },
    {
      name: "Blue Nile",
      position: [11.59, 35.09],
      description: "Major river affected by land use changes and soil erosion",
      paper: "El-Swaify, S.A. and Hurni, H. (1996). Transboundary effects of soil erosion and conservation in the Nile Basin.",
      stakeholder: "Water resource managers and downstream countries"
    },
    {
      name: "Gojam Region",
      position: [10.95, 37.5],
      description: "Broader region of study, severely affected by soil erosion and deforestation",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "Regional agricultural and environmental authorities"
    },
    {
      name: "Ethiopian Highlands",
      position: [9.5, 38.5],
      description: "Major focus of environmental degradation studies. Over 14 million hectares seriously eroded, 13 million hectares moderately eroded.",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "Environmental researchers, policymakers, and local communities"
    },
    {
      name: "Welo",
      position: [11.8, 39.6],
      description: "Region with highest rates of erosion in northern Ethiopia",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "Local farmers, environmental agencies"
    },
    {
      name: "Harerge",
      position: [9.3, 42.5],
      description: "Region in southeastern Ethiopia with lower erosion rates due to less remaining soil",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "Local communities, soil conservation experts"
    },
    {
      name: "Bahir Dar",
      position: [11.59, 37.39],
      description: "City near Lake Tana, affected by water management issues",
      paper: "Alemayehu, T. (2006). Water Quality Monitoring in Lake Tana Catchment.",
      stakeholder: "Local government and water resource managers"
  },
  {
      name: "Gondar",
      position: [12.6, 37.47],
      description: "Historical city with environmental conservation efforts",
      paper: "Eshetu, Z. (2002). Forest Conservation in the Gondar Region.",
      stakeholder: "Heritage conservationists and local communities"
  },
  {
      name: "Jimma",
      position: [7.67, 36.83],
      description: "City with agricultural research centers",
      paper: "Bekele, T. (2003). Agricultural Research and Development in Jimma.",
      stakeholder: "Agricultural researchers and local farmers"
  },
  {
      name: "Hawassa",
      position: [7.06, 38.48],
      description: "City by Lake Hawassa, facing urbanization challenges",
      paper: "Gebremariam, Z. (2009). Urbanization and Environmental Impact in Hawassa.",
      stakeholder: "Urban planners and environmental agencies"
  },
  {
      name: "Dire Dawa",
      position: [9.6, 41.87],
      description: "City with industrial pollution issues",
      paper: "Kebede, A. (2010). Industrial Pollution in Dire Dawa.",
      stakeholder: "Environmental protection agencies and local industries"
  },
  {
      name: "Mekelle",
      position: [13.49, 39.47],
      description: "City with soil erosion and conservation projects",
      paper: "Tesfaye, A. (2005). Soil Erosion and Conservation in Mekelle.",
      stakeholder: "Soil conservation experts and local farmers"
  },
  {
      name: "Arba Minch",
      position: [6.04, 37.55],
      description: "City near the Rift Valley lakes, facing water resource challenges",
      paper: "Wolde, M. (2008). Water Resource Management in Arba Minch.",
      stakeholder: "Water resource managers and local communities"
  },
  {
      name: "Harar",
      position: [9.31, 42.12],
      description: "Historical city with heritage conservation efforts",
      paper: "Abebe, T. (2011). Heritage Conservation in Harar.",
      stakeholder: "Heritage conservationists and local communities"
  },
  {
      name: "Adama",
      position: [8.54, 39.27],
      description: "City with renewable energy projects",
      paper: "Yohannes, D. (2012). Renewable Energy Development in Adama.",
      stakeholder: "Energy sector stakeholders and local government"
  },
  {
      name: "Gambela",
      position: [8.25, 34.59],
      description: "Region with biodiversity conservation projects",
      paper: "Mengistu, W. (2013). Biodiversity Conservation in Gambela.",
      stakeholder: "Environmental researchers and local communities"
  }
  ];

  const plotData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    values: [65, 59, 80, 81, 56, 55, 40],
  };

  const tableData = [
    {
      Institution: 'University of Bern',
      Country: 'Switzerland',
      Researchers: 'Zeleke, G.; Hurni, H.',
      Paper: 'Implications of Land Use and Land Cover Dynamics for Mountain Resource Degradation in the Northwestern Ethiopian Highlands',
      Year: '2001'
    },
    {
      Institution: 'Addis Ababa University',
      Country: 'Ethiopia',
      Researchers: 'Teketay, D.',
      Paper: 'Deforestation, Wood Famine, and Environmental Degradation in the Ethiopia Highland Ecosystems: Urgent Need for Action',
      Year: '2001'
    },
    {
      Institution: 'University of Hawaii',
      Country: 'USA',
      Researchers: 'El-Swaify, S.A.',
      Paper: 'Transboundary effects of soil erosion and conservation in the Nile Basin',
      Year: '1996'
    },
    {
      Institution: 'Ethiopian Agricultural Research Organization',
      Country: 'Ethiopia',
      Researchers: 'Gebremedhin, B.; Swinton, S.M.',
      Paper: 'Investment in soil conservation in northern Ethiopia: the role of land tenure security and public programs',
      Year: '2003'
    },
    {
      Institution: 'Wageningen University',
      Country: 'Netherlands',
      Researchers: 'Nyssen, J.; Poesen, J.; Moeyersons, J.',
      Paper: 'Land degradation and soil and water conservation in tropical highlands',
      Year: '2004'
    },
    {
      Institution: 'Mekelle University',
      Country: 'Ethiopia',
      Researchers: 'Gebremichael, D.; Nyssen, J.',
      Paper: 'Effectiveness of exclosures to restore degraded soils as a result of overgrazing in Tigray, Ethiopia',
      Year: '2005'
    },
    {
      Institution: 'Stockholm University',
      Country: 'Sweden',
      Researchers: 'Bewket, W.; Sterk, G.',
      Paper: 'Dynamics in land cover and its effect on stream flow in the Chemoga watershed, Blue Nile basin, Ethiopia',
      Year: '2005'
    },
    {
      Institution: 'Oregon State University',
      Country: 'USA',
      Researchers: 'Taye, G.; Poesen, J.; Van Wesemael, B.',
      Paper: 'Effects of land use, slope gradient, and soil and water conservation techniques on runoff and soil loss in a semi-arid Northern Ethiopia',
      Year: '2013'
    },
    {
      Institution: 'Ghent University',
      Country: 'Belgium',
      Researchers: 'Frankl, A.; Nyssen, J.; De Dapper, M.',
      Paper: 'Linking long-term gully and river channel dynamics to environmental change using repeat photography (Northern Ethiopia)',
      Year: '2011'
    },
    {
      Institution: 'Bahir Dar University',
      Country: 'Ethiopia',
      Researchers: 'Tilahun, S.A.; Guzman, C.D.; Zegeye, A.D.',
      Paper: 'An efficient semi-distributed hillslope erosion model for the subhumid Ethiopian Highlands',
      Year: '2013'
    }
  ];

  const mockGraphData = {
    "path": {
      "documents": [
        "Reforestation",
        "Reforestation",
        "Reforestation",
        "Reforestation",
        "Reforestation",
        "Reforestation",
        "Soil Conservation",
        "Crop Diversification",
        "Reforestation",
        "Crop Diversification",
        "Crop Diversification",
        "Irrigation Systems",
        "Soil Fertility",
        "Reforestation",
        "Reforestation",
        "Land Fragmentation",
        "Conservation Agriculture",
        "Erosion Control",
        "Food Security",
        "Crop Diversification",
        "Crop Diversification",
        "Crop Diversification",
        "Crop Diversification",
        "Crop Diversification",
        "Wetland Conservation",
        "Carbon Sequestration",
        "Crop Diversification",
        "Soil Salinity",
        "Agrobiodiversity",
        "Risk Assessment",
        "Forest Ecosystems",
        "Integrated Management",
        "Soil Compaction",
        "Soil Compaction",
        "Soil Compaction",
        "Soil Compaction",
        "Soil Compaction",
        "ASoil Compaction",
        "Soil Compaction",
        "Soil Compaction",
        "Ethiopian Highlands",
        "Pest Management",
        "Smallholder Farming",
        "GIS Modeling",
        "GIS Modeling",
        "Rainfed Agriculture",
        "Rainfed Agriculture",
        "Ethiopian Highlands",
        "Rainfed Agriculture",
        "Rainfed Agriculture",
        "Agroecosystems",
        "Ethiopian Highlands",
        "Drought-Prone Areas",
        "Irrigated Agriculture",
        "GIS Modeling",
        "Tropical Soils",
        "Tropical Soils",
        "Tillage Impact",
        "Organic Farming",
        "Ethiopian Highlands",
        "Rift Valley",
        "Tropical Soils",
        "Oromia Region",
        "GIS Modeling",
        "Ethiopian Highlands",
        "Rainfed Agriculture"
      ],
      "metadatas": [
        {
          "note": "Soil Erosion in Ethiopian Highlands",
          "date": "2019",
          "path": "/research/soil-erosion",
          "title": "Hurni, H.; Abate, S. (2019) Land Degradation & Development"
        },
        {
          "note": "Water Resource Management in Blue Nile Basin",
          "date": "2019",
          "path": "/research/water-management",
          "title": "Gebremicael, T.G.; Mohamed, Y.A.; Van der Zaag, P. (2019) Water Resources Management"
        },
        {
          "note": "Reforestation Strategies for Land Rehabilitation",
          "date": "2017",
          "path": "/research/reforestation",
          "title": "Birhane, E.; Mengistu, T.; Seyoum, Y. (2017) Forest Ecology and Management"
        },
        {
          "note": "Sustainable Agriculture Practices in Ethiopia",
          "date": "2017",
          "path": "/research/sustainable-agriculture",
          "title": "Agegnehu, G.; Amede, T. (2017) Environment, Development and Sustainability"
        },
        {
          "note": "Climate Change Impacts on Ethiopian Agriculture",
          "date": "2015",
          "path": "/research/climate-change",
          "title": "Kassie, B.T.; Asseng, S.; Porter, C.H. (2015) Agricultural Systems"
        },
        {
          "note": "Land Use Changes in Northwestern Ethiopia",
          "date": "2001",
          "path": "/research/land-use",
          "title": "Zeleke, G.; Hurni, H. (2001) Mountain Research and Development"
        },
        {
          "note": "Soil Conservation Techniques for Smallholder Farmers",
          "date": "2014",
          "path": "/research/soil-conservation",
          "title": "Adimassu, Z.; Mekonnen, K.; Yirga, C. (2014) Natural Resources"
        },
        {
          "note": "Watershed Management in Tigray Region",
          "date": "2009",
          "path": "/research/watershed-management",
          "title": "Nyssen, J.; Clymans, W.; Descheemaeker, K. (2009) Journal of Hydrology"
        },
        {
          "note": "Agroforestry Systems in Southern Ethiopia",
          "date": "2015",
          "path": "/research/agroforestry",
          "title": "Negash, M.; Starr, M. (2015) Agroforestry Systems"
        },
        {
          "note": "Drought Resilience in Pastoral Communities",
          "date": "2013",
          "path": "/research/drought-resilience",
          "title": "Catley, A.; Lind, J.; Scoones, I. (2013) Development and Change"
        },
        {
          "note": "Crop Diversification for Food Security",
          "date": "2013",
          "path": "/research/crop-diversification",
          "title": "Teklewold, H.; Kassie, M.; Shiferaw, B. (2013) Food Security"
        },
        {
          "note": "Irrigation Systems in Arid Regions of Ethiopia",
          "date": "2005",
          "path": "/research/irrigation-systems",
          "title": "Awulachew, S.B.; Merrey, D.J.; Kamara, A.B. (2005) Irrigation and Drainage Systems"
        },
        {
          "note": "Soil Fertility Management in Oromia",
          "date": "2011",
          "path": "/research/soil-fertility",
          "title": "Abera, Y.; Belachew, T. (2011) Journal of Agriculture and Rural Development in the Tropics and Subtropics"
        },
        {
          "note": "Land Degradation in the Rift Valley",
          "date": "2012",
          "path": "/research/land-degradation",
          "title": "Meshesha, D.T.; Tsunekawa, A.; Tsubo, M. (2012) Land Degradation & Development"
        },
        {
          "note": "Participatory Forest Management in Ethiopia",
          "date": "2014",
          "path": "/research/participatory-forest-management",
          "title": "Ameha, A.; Larsen, H.O.; Lemenih, M. (2014) Forests, Trees and Livelihoods"
        },
        {
          "note": "Impact of Land Fragmentation on Productivity",
          "date": "2020",
          "path": "/research/land-fragmentation",
          "title": "Knippenberg, E.; Jolliffe, D.; Hoddinott, J. (2020) Land Economics"
        },
        {
          "note": "Conservation Agriculture in Ethiopia",
          "date": "2012",
          "path": "/research/conservation-agriculture",
          "title": "Araya, T.; Cornelis, W.M.; Nyssen, J. (2012) Agronomy for Sustainable Development"
        },
        {
          "note": "Erosion Control in Highland Areas",
          "date": "2013",
          "path": "/research/erosion-control",
          "title": "Taye, G.; Poesen, J.; Van Wesemael, B. (2013) Earth Surface Processes and Landforms"
        },
        {
          "note": "Food Security Challenges in Ethiopia",
          "date": "2014",
          "path": "/research/food-security",
          "title": "Dorosh, P.; Rashid, S. (2014) Food and Agriculture in Ethiopia: Progress and Policy Challenges"
        },
        {
          "note": "Climate-Smart Agriculture Adoption in Ethiopia",
          "date": "2013",
          "path": "/research/climate-smart-agriculture",
          "title": "Tessema, Y.A.; Aweke, C.S.; Endris, G.S. (2013) Climate and Development"
        },
        {
          "note": "Soil Microbial Diversity in Agricultural Lands",
          "date": "2018",
          "path": "/research/soil-microbial-diversity",
          "title": "Abera, G.; Wolde-Meskel, E.; Bakken, L.R. (2018) Applied Soil Ecology"
        },
        {
          "note": "Impact of Eucalyptus on Soil Properties",
          "date": "2016",
          "path": "/research/eucalyptus-impact",
          "title": "Jagger, P.; Pender, J. (2016) Forest Policy and Economics"
        },
        {
          "note": "Terracing Techniques for Erosion Control",
          "date": "2015",
          "path": "/research/terracing-techniques",
          "title": "Nyssen, J.; Poesen, J.; Gebremichael, D. (2015) Earth Surface Processes and Landforms"
        },
        {
          "note": "Climate Variability and Crop Yield",
          "date": "2017",
          "path": "/research/climate-variability",
          "title": "Bewket, W. (2017) Agriculture, Ecosystems & Environment"
        },
        {
          "note": "Wetland Conservation in Ethiopia",
          "date": "2019",
          "path": "/research/wetland-conservation",
          "title": "Gebresllassie, H.; Gashaw, T.; Mehari, A. (2019) Journal of Environmental Protection"
        },
        {
          "note": "Soil Carbon Sequestration in Agroforestry Systems",
          "date": "2018",
          "path": "/research/carbon-sequestration",
          "title": "Rimhanen, K.; Ketoja, E.; Yli-Halla, M. (2018) Agriculture, Ecosystems & Environment"
        },
        {
          "note": "Impact of Land Use on Water Quality",
          "date": "2016",
          "path": "/research/land-use-water-quality",
          "title": "Taddese, G. (2016) Environmental Management"
        },
        {
          "note": "Soil Salinity Management in Irrigated Areas",
          "date": "2020",
          "path": "/research/soil-salinity",
          "title": "Qureshi, A.S.; Ertebo, T.; Mehansiwala, M. (2020) Irrigation and Drainage"
        },
        {
          "note": "Agrobiodiversity Conservation",
          "date": "2017",
          "path": "/research/agrobiodiversity",
          "title": "Mulumba, L.N.; Lal, R. (2017) Environment, Development and Sustainability"
        },
        {
          "note": "Soil Erosion Risk Assessment",
          "date": "2019",
          "path": "/research/erosion-risk-assessment",
          "title": "Haregeweyn, N.; Tsunekawa, A.; Poesen, J. (2019) Land Degradation & Development"
        },
        {
          "note": "Climate Change and Forest Ecosystems",
          "date": "2018",
          "path": "/research/climate-forest-ecosystems",
          "title": "Mokria, M.; Gebrekirstos, A.; Abiyu, A. (2018) Forest Ecology and Management"
        },
        {
          "note": "Integrated Watershed Management",
          "date": "2016",
          "path": "/research/integrated-watershed",
          "title": "Desta, L.; Carucci, V.; Wendem-Agenehu, A. (2016) Mountain Research and Development"
        },
        {
          "note": "Soil Compaction in Mechanized Agriculture",
          "date": "2017",
          "path": "/research/soil-compaction",
          "title": "Hamza, M.A.; Anderson, W.K. (2017) Soil and Tillage Research"
        },
        {
          "note": "Urban Agriculture and Soil Contamination",
          "date": "2019",
          "path": "/research/urban-agriculture",
          "title": "Woldetsadik, M.; Drechsel, P.; Keraita, B. (2019) Environment, Development and Sustainability"
        },
        {
          "note": "Biochar Application for Soil Improvement",
          "date": "2018",
          "path": "/research/biochar-application",
          "title": "Abewa, A.; Yitaferu, B.; Selassie, Y.G. (2018) Journal of Soil Science and Environmental Management"
        },
        {
          "note": "Rangeland Management in Pastoral Areas",
          "date": "2016",
          "path": "/research/rangeland-management",
          "title": "Angassa, A.; Oba, G. (2016) Human Ecology"
        },
        {
          "note": "Soil Fauna Diversity in Different Land Use Systems",
          "date": "2017",
          "path": "/research/soil-fauna-diversity",
          "title": "Ayuke, F.O.; Brussaard, L.; Vanlauwe, B. (2017) Applied Soil Ecology"
        },
        {
          "note": "Impact of Agricultural Intensification on Soil Quality",
          "date": "2018",
          "path": "/research/agricultural-intensification",
          "title": "Lemenih, M.; Karltun, E.; Olsson, M. (2018) Agriculture, Ecosystems & Environment"
        },
        {
          "note": "Soil Erosion and Nutrient Loss in Coffee Plantations",
          "date": "2019",
          "path": "/research/coffee-plantations",
          "title": "Liang, J.; Crowther, T.W.; Picard, N. (2019) Science"
        },
        {
          "note": "Traditional Ecological Knowledge in Land Management",
          "date": "2017",
          "path": "/research/traditional-knowledge",
          "title": "Berkes, F.; Colding, J.; Folke, C. (2017) Ecological Applications"
        },
        {
          "note": "Soil Organic Matter Dynamics in Tropical Soils",
          "date": "2018",
          "path": "/research/soil-organic-matter",
          "title": "Zech, W.; Senesi, N.; Guggenberger, G. (2018) Geoderma"
        },
        {
          "note": "Impact of Climate Change on Crop Diseases",
          "date": "2019",
          "path": "/research/climate-crop-diseases",
          "title": "Elad, Y.; Pertot, I. (2019) Journal of Crop Protection"
        },
        {
          "note": "Sustainable Intensification of Smallholder Farming",
          "date": "2020",
          "path": "/research/sustainable-intensification",
          "title": "Pretty, J.; Bharucha, Z.P. (2020) Annals of Botany"
        },
        {
          "note": "Soil Erosion Modeling Using GIS",
          "date": "2018",
          "path": "/research/erosion-modeling",
          "title": "Ganasri, B.P.; Ramesh, H. (2018) Geoscience Frontiers"
        },
        {
          "note": "Impact of Land Use Change on Biodiversity",
          "date": "2017",
          "path": "/research/land-use-biodiversity",
          "title": "Newbold, T.; Hudson, L.N.; Hill, S.L. (2017) Nature"
        },
        {
          "note": "Soil Water Conservation Techniques",
          "date": "2019",
          "path": "/research/water-conservation",
          "title": "Vohland, K.; Barry, B. (2019) Journal of Soil and Water Conservation"
        },
        {
          "note": "Agroecological Approaches to Pest Management",
          "date": "2018",
          "path": "/research/agroecological-pest-management",
          "title": "Altieri, M.A.; Nicholls, C.I. (2018) Agroecology and Sustainable Food Systems"
        },
        {
          "note": "Soil Nutrient Cycling in Agroforestry Systems",
          "date": "2020",
          "path": "/research/nutrient-cycling",
          "title": "Nair, P.K.R.; Buresh, R.J.; Mugendi, D.N. (2020) Agroforestry Systems"
        },
        {
          "note": "Impact of Tillage on Soil Physical Properties",
          "date": "2017",
          "path": "/research/tillage-impact",
          "title": "Busari, M.A.; Kukal, S.S.; Kaur, A. (2017) Advances in Agronomy"
        },
        {
          "note": "Soil Biodiversity and Ecosystem Functioning",
          "date": "2019",
          "path": "/research/soil-biodiversity",
          "title": "Bardgett, R.D.; van der Putten, W.H. (2019) Nature"
        },
        {
          "note": "Climate Change Adaptation in Smallholder Agriculture",
          "date": "2018",
          "path": "/research/smallholder-adaptation",
          "title": "Harvey, C.A.; Rakotobe, Z.L.; Rao, N.S. (2018) Agricultural Systems"
        },
        {
          "note": "Soil Erosion Impact on Crop Productivity",
          "date": "2017",
          "path": "/research/erosion-productivity",
          "title": "Panagos, P.; Standardi, G.; Borrelli, P. (2017) Land Degradation & Development"
        },
        {
          "note": "Agroforestry for Soil Fertility Improvement",
          "date": "2019",
          "path": "/research/agroforestry-soil-fertility",
          "title": "Mbow, C.; Smith, P.; Skole, D. (2019) Current Opinion in Environmental Sustainability"
        },
        {
          "note": "Soil Carbon Dynamics in Agricultural Systems",
          "date": "2018",
          "path": "/research/soil-carbon-dynamics",
          "title": "Lal, R. (2018) Global Change Biology"
        },
        {
          "note": "Water Use Efficiency in Irrigated Agriculture",
          "date": "2020",
          "path": "/research/water-use-efficiency",
          "title": "Jägermeyr, J.; Gerten, D.; Schaphoff, S. (2020) Environmental Research Letters"
        },
        {
          "note": "Soil Erosion and Food Security",
          "date": "2017",
          "path": "/research/erosion-food-security",
          "title": "Amundson, R.; Berhe, A.A.; Hopmans, J.W. (2017) Advances in Agronomy"
        },
        {
          "note": "Impact of Climate Change on Soil Fertility",
          "date": "2019",
          "path": "/research/climate-soil-fertility",
          "title": "Karmakar, R.; Das, I.; Dutta, D. (2019) Journal of Soil Science and Plant Nutrition"
        },
        {
          "note": "Sustainable Land Management in Drylands",
          "date": "2018",
          "path": "/research/dryland-management",
          "title": "Reynolds, J.F.; Smith, D.M.S. (2018) Global Environmental Change"
        },
        {
          "note": "Soil Microbial Communities in Agroecosystems",
          "date": "2020",
          "path": "/research/soil-microbial-communities",
          "title": "Fierer, N. (2020) Nature Reviews Microbiology"
        },
        {
          "note": "Impact of Deforestation on Soil Properties",
          "date": "2017",
          "path": "/research/deforestation-impact",
          "title": "Lal, R. (2017) Land Degradation & Development"
        },
        {
          "note": "Soil Organic Carbon and Climate Change",
          "date": "2019",
          "path": "/research/soil-carbon-climate",
          "title": "Smith, P. (2019) Global Change Biology"
        },
        {
          "note": "Sustainable Intensification of Agriculture",
          "date": "2018",
          "path": "/research/sustainable-intensification",
          "title": "Rockström, J.; Williams, J.; Daily, G. (2018) Nature Sustainability"
        },
        {
          "note": "Soil Health in Organic Farming Systems",
          "date": "2020",
          "path": "/research/organic-soil-health",
          "title": "Lori, M.; Symnaczik, S.; Mäder, P. (2020) Sustainability"
        },
        {
          "note": "Impact of Erosion on Soil Organic Carbon Stocks",
          "date": "2017",
          "path": "/research/erosion-carbon-stocks",
          "title": "Wang, Z.; Hoffmann, T.; Six, J. (2017) Nature Geoscience"
        },
        {
          "note": "Soil Biodiversity and Ecosystem Services",
          "date": "2019",
          "path": "/research/soil-biodiversity-services",
          "title": "Orgiazzi, A.; Bardgett, R.D.; Barrios, E. (2019) Global Soil Biodiversity Atlas"
        },
        {
          "note": "Climate-Smart Soil Management Practices",
          "date": "2018",
          "path": "/research/climate-smart-soil",
          "title": "Paustian, K.; Lehmann, J.; Ogle, S. (2018) Nature"
        },
        {
          "note": "Soil Erosion Mitigation Strategies in Highland Agriculture",
          "date": "2021",
          "path": "/research/erosion-mitigation-highlands",
          "title": "Alemayehu, T.; Fisseha, G.; Assefa, D. (2021) Catena"
        },
        {
          "note": "Water Resource Management and Climate Change Adaptation",
          "date": "2020",
          "path": "/research/water-climate-adaptation",
          "title": "Gebre, S.L.; Ludwig, F. (2020) Water Resources Management"
        },
        {
          "note": "Agroforestry for Soil Conservation and Crop Productivity",
          "date": "2019",
          "path": "/research/agroforestry-soil-productivity",
          "title": "Kuyah, S.; Whitney, C.W.; Jonsson, M. (2019) Ecological Applications"
        },
        {
          "note": "Sustainable Intensification of Smallholder Farming Systems",
          "date": "2021",
          "path": "/research/sustainable-intensification-smallholders",
          "title": "Vanlauwe, B.; Barrios, E.; Robinson, T. (2021) Agricultural Systems"
        },
        {
          "note": "Climate-Smart Agriculture and Food Security",
          "date": "2020",
          "path": "/research/climate-smart-food-security",
          "title": "Lipper, L.; McCarthy, N.; Zilberman, D. (2020) Food Security"
        },
        {
          "note": "Land Use Change and Biodiversity Conservation",
          "date": "2019",
          "path": "/research/land-use-biodiversity-conservation",
          "title": "Eshetu, A.A.; Gebeyehu, G.M. (2019) Environmental Systems Research"
        },
        {
          "note": "Soil and Water Conservation in Drought-Prone Areas",
          "date": "2021",
          "path": "/research/conservation-drought-areas",
          "title": "Mekuria, W.; Gebregziabher, G.; Lefore, N. (2021) Land"
        },
        {
          "note": "Integrated Watershed Management for Ecosystem Services",
          "date": "2020",
          "path": "/research/watershed-ecosystem-services",
          "title": "Guzha, A.C.; Rufino, M.C.; Okoth, S. (2020) Ecosystem Services"
        },
        {
          "note": "Agroecological Practices for Sustainable Agriculture",
          "date": "2019",
          "path": "/research/agroecology-sustainable-agriculture",
          "title": "Tittonell, P.; Giller, K.E. (2019) Agronomy for Sustainable Development"
        },
        {
          "note": "Drought Resilience and Water Resource Management",
          "date": "2021",
          "path": "/research/drought-water-management",
          "title": "Haile, G.G.; Tang, Q.; Hosseini-Moghari, S.M. (2021) Science of The Total Environment"
        },
        {
          "note": "Crop Diversification and Climate Change Adaptation",
          "date": "2020",
          "path": "/research/crop-diversification-climate",
          "title": "Waha, K.; van Wijk, M.T.; Arsenault, K. (2020) Nature Climate Change"
        },
        {
          "note": "Irrigation Efficiency and Water Conservation",
          "date": "2019",
          "path": "/research/irrigation-efficiency-conservation",
          "title": "Kifle, M.; Gebremicael, T.G.; Girmay, A. (2019) Agricultural Water Management"
        },
        {
          "note": "Soil Fertility Management and Crop Yield Stability",
          "date": "2021",
          "path": "/research/fertility-yield-stability",
          "title": "Abera, T.; Feyisa, D.; Friesen, D.K. (2021) Agronomy Journal"
        },
        {
          "note": "Land Degradation Neutrality in Semi-Arid Ecosystems",
          "date": "2020",
          "path": "/research/degradation-neutrality-semiarid",
          "title": "Tadesse, L.; Suryabhagavan, K.V.; Sridhar, G. (2020) Land Degradation & Development"
        },
        {
          "note": "Participatory Forest Management and Livelihoods",
          "date": "2019",
          "path": "/research/participatory-forest-livelihoods",
          "title": "Ameha, A.; Nielsen, O.J.; Larsen, H.O. (2019) Forests"
        },
        {
          "note": "Land Fragmentation and Agricultural Productivity",
          "date": "2021",
          "path": "/research/fragmentation-productivity",
          "title": "Cholo, T.C.; Fleskens, L.; Sietz, D. (2021) Land Use Policy"
        },
        {
          "note": "Conservation Agriculture and Soil Health",
          "date": "2020",
          "path": "/research/conservation-agriculture-soil-health",
          "title": "Araya, T.; Nyssen, J.; Govaerts, B. (2020) Soil and Tillage Research"
        },
        {
          "note": "Erosion Control and Ecosystem Services in Highlands",
          "date": "2019",
          "path": "/research/erosion-ecosystem-services",
          "title": "Adimassu, Z.; Langan, S.; Barron, J. (2019) Ecological Processes"
        },
        {
          "note": "Food Security and Sustainable Agriculture Practices",
          "date": "2021",
          "path": "/research/food-security-sustainable-practices",
          "title": "Gebrehiwot, K.A.; Gebrewahid, T.T. (2021) Sustainability"
        },
        {
          "note": "Climate-Smart Agriculture and Smallholder Adoption",
          "date": "2020",
          "path": "/research/climate-smart-smallholder-adoption",
          "title": "Teklewold, H.; Gebrehiwot, T.; Bezabih, M. (2020) Technological Forecasting and Social Change"
        },
        {
          "note": "Soil Microbial Diversity and Ecosystem Functioning",
          "date": "2019",
          "path": "/research/microbial-diversity-ecosystem",
          "title": "Gebreselassie, Y.; Moges, F.; Estrella, M. (2019) Applied Soil Ecology"
        },
        {
          "note": "Impact of Eucalyptus on Water Resources and Soil Properties",
          "date": "2021",
          "path": "/research/eucalyptus-water-soil",
          "title": "Dessie, A.B.; Bredemeier, M. (2021) Forests"
        },
        {
          "note": "Terracing for Soil and Water Conservation",
          "date": "2020",
          "path": "/research/terracing-soil-water",
          "title": "Kagabo, D.M.; Stroosnijder, L.; Visser, S.M. (2020) Soil and Tillage Research"
        },
        {
          "note": "Climate Variability and Adaptation in Rain-fed Agriculture",
          "date": "2019",
          "path": "/research/climate-variability-rainfed",
          "title": "Asfaw, S.; Pallante, G.; Palma, A. (2019) World Development"
        },
        {
          "note": "Wetland Conservation and Ecosystem Services",
          "date": "2021",
          "path": "/research/wetland-ecosystem-services",
          "title": "Wondie, A.; Mengistou, S. (2021) Wetlands Ecology and Management"
        },
        {
          "note": "Soil Carbon Sequestration in Different Land Use Systems",
          "date": "2020",
          "path": "/research/carbon-sequestration-land-use",
          "title": "Solomon, D.; Lehmann, J.; Fraser, J.A. (2020) Agriculture, Ecosystems & Environment"
        },
        {
          "note": "Land Use Change and Water Quality in Agricultural Watersheds",
          "date": "2019",
          "path": "/research/land-use-water-quality-watersheds",
          "title": "Gessesse, B.; Bewket, W.; Bräuning, A. (2019) Environmental Monitoring and Assessment"
        },
        {
          "note": "Soil Salinity Management in Irrigated Agriculture",
          "date": "2021",
          "path": "/research/salinity-irrigated-agriculture",
          "title": "Gebremeskel, G.; Gebremicael, T.G.; Kifle, M. (2021) Agricultural Water Management"
        },
        {
          "note": "Agrobiodiversity Conservation and Food Security",
          "date": "2020",
          "path": "/research/agrobiodiversity-food-security",
          "title": "Bellon, M.R.; Kotu, B.H.; Azzarri, C. (2020) Global Food Security"
        },
        {
          "note": "Soil Erosion Risk Assessment and Management",
          "date": "2019",
          "path": "/research/erosion-risk-management",
          "title": "Haregeweyn, N.; Tsunekawa, A.; Poesen, J. (2019) Science of The Total Environment"
        },
        {
          "note": "Climate Change Impacts on Forest Ecosystems",
          "date": "2021",
          "path": "/research/climate-forest-impacts",
          "title": "Mokria, M.; Gebrekirstos, A.; Aynekulu, E. (2021) Forest Ecology and Management"
        },
        {
          "note": "Integrated Watershed Management and Livelihoods",
          "date": "2020",
          "path": "/research/watershed-management-livelihoods",
          "title": "Nigussie, Z.; Tsunekawa, A.; Haregeweyn, N. (2020) Land Use Policy"
        },
        {
          "note": "Soil Compaction and Conservation Tillage",
          "date": "2019",
          "path": "/research/soil-compaction-conservation-tillage",
          "title": "Temesgen, M.; Hoogmoed, W.B.; Rockström, J. (2019) Soil and Tillage Research"
        },
        {
          "note": "Urban Agriculture and Food Security in Developing Countries",
          "date": "2021",
          "path": "/research/urban-agriculture-food-security",
          "title": "Orsini, F.; Pennisi, G.; Zulfiqar, F. (2021) Sustainability"
        },
        {
          "note": "Biochar Application for Soil Fertility and Crop Productivity",
          "date": "2020",
          "path": "/research/biochar-fertility-productivity",
          "title": "Agegnehu, G.; Srivastava, A.K.; Bird, M.I. (2020) Geoderma"
        },
        {
          "note": "Rangeland Management and Pastoralist Livelihoods",
          "date": "2019",
          "path": "/research/rangeland-pastoralist-livelihoods",
          "title": "Fenta, M.M.; Jordaan, A.; Melka, Y. (2019) Journal of Arid Environments"
        },
        {
          "note": "Soil Fauna Diversity and Ecosystem Services",
          "date": "2021",
          "path": "/research/soil-fauna-ecosystem-services",
          "title": "Geissen, V.; Peña-Peña, K.; Huerta, E. (2021) Frontiers in Environmental Science"
        },
        {
          "note": "Agricultural Intensification and Soil Quality Management",
          "date": "2020",
          "path": "/research/intensification-soil-quality",
          "title": "Taddese, G.; Sonder, K.; Peden, D. (2020) Land Degradation & Development"
        },
        {
          "note": "Soil Erosion and Nutrient Management in Coffee Agroforestry",
          "date": "2019",
          "path": "/research/erosion-nutrients-coffee-agroforestry",
          "title": "Gessesse, B.; Bewket, W.; Bräuning, A. (2019) Agriculture, Ecosystems & Environment"
        },
        {
          "note": "Traditional Ecological Knowledge in Sustainable Land Management",
          "date": "2021",
          "path": "/research/traditional-knowledge-land-management",
          "title": "Assefa, E.; Hans-Rudolf, B. (2021) Land Use Policy"
        },
        {
          "note": "Soil Organic Matter Dynamics in Tropical Agroecosystems",
          "date": "2020",
          "path": "/research/organic-matter-tropical-agroecosystems",
          "title": "Abera, G.; Wolde-Meskel, E.; Bakken, L.R. (2020) Soil Biology and Biochemistry"
        },
        {
          "note": "Climate Change Impacts on Crop Diseases and Pest Management",
          "date": "2019",
          "path": "/research/climate-crop-diseases-pests",
          "title": "Yesuf, M.; Bluffstone, R. (2019) Food Security"
        },
        {
          "note": "Sustainable Intensification of Smallholder Farming Systems",
          "date": "2021",
          "path": "/research/sustainable-intensification-smallholders",
          "title": "Tittonell, P.; Gérard, B.; Erenstein, O. (2021) Agricultural Systems"
        },
        {
          "note": "GIS-based Soil Erosion Modeling and Risk Assessment",
          "date": "2020",
          "path": "/research/gis-erosion-modeling",
          "title": "Gashaw, T.; Tulu, T.; Argaw, M. (2020) Modeling Earth Systems and Environment"
        },
        {
          "note": "Land Use Change and Biodiversity Conservation Strategies",
          "date": "2019",
          "path": "/research/land-use-biodiversity-strategies",
          "title": "Tadesse, G.; Zavaleta, E.; Shennan, C. (2019) Biological Conservation"
        },
        {
          "note": "Water Conservation Techniques in Rain-fed Agriculture",
          "date": "2021",
          "path": "/research/water-conservation-rainfed",
          "title": "Biazin, B.; Sterk, G.; Temesgen, M. (2021) Agricultural Water Management"
        },
        {
          "note": "Agroecological Pest Management in Smallholder Farming",
          "date": "2020",
          "path": "/research/agroecological-pest-management",
          "title": "Wyckhuys, K.A.G.; Wongtiem, P.; Wu, K. (2020) Current Opinion in Environmental Sustainability"
        },
        {
          "note": "Agroecological Pest Management in Smallholder Farming",
          "date": "2020",
          "path": "/research/agroecological-pest-management",
          "title": "Wyckhuys, K.A.G.; Wongtiem, P.; Wu, K. (2020) Current Opinion in Environmental Sustainability"
        }
      ]
    }
  };


  const handleClick = async (e, searchMode, researchType) => {
    const plot = input.includes("[plot]")
    e.preventDefault()
    setTest("")
    setLoading(true)

        // Special handling for Map and Plot
        if (searchMode === 'concepts') {
          setTimeout(() => {
            const newWidget = {
              id: Date.now(),
              type: 'Regions of study',
              component: <MapChart markers={markers}/>
            };
            setWidgets(prevWidgets => [...prevWidgets, newWidget]);
            setLoading(false);
            return;
          }, 5000);
        }
    
        else if (searchMode === 'shadow-notes') {
          setTimeout(() => {
            const newWidget = {
              id: Date.now(),
              type: 'Prevention efforts by region',
              component: <Plot />
            };
            setWidgets(prevWidgets => [...prevWidgets, newWidget]);
            setLoading(false);
            return;
          }, 5000);
          
        }
        else if (searchMode === 'institutions') {
          setTimeout(() => {
            const newWidget = {
              id: Date.now(),
              type: 'Overview of institutions',
              component: <TableWidget data={tableData} />
            };
            setWidgets(prevWidgets => [...prevWidgets, newWidget]);
            setLoading(false);
            return;
          }, 5000);
          
        }

        else if (searchMode === 'lit-review') {
          setTimeout(() => {
            const newWidget = {
              id: Date.now(),
              type: 'Topical Relationships',
              component: <D3Graph data={mockGraphData} />
            };
            setWidgets(prevWidgets => [...prevWidgets, newWidget]);
            setLoading(false);
            return;
          }, 5000);
          
        }
        else {
          let inputValue = '';
    switch (searchMode) {
        case 'advanced':
            inputValue = input
            break;
        case 'table':
            inputValue = 'create a nicely formatted markdown document that provides all the details for each paper in a nicely organized way';
            break;
        case 'authors':
            inputValue = 'show me the authors associated with each paper in a table';
            break;
        case 'methodologies':
            inputValue = 'show me the complete methodology used for each paper in nicely formatted Markdown document';
            break;
        case 'abstracts':
            inputValue = 'show me the complete abstract for each paper in nicely formatted Markdown document';
            break;
        case 'introduction':
            inputValue = 'show me the complete introduction for each paper in nicely formatted Markdown document';
            break;
        case 'lit-review':
            inputValue = 'Do a comprehenive literature review over all paper and preent it in nicely formatted Markdown document';
            break;
        case 'institutions':
            inputValue = 'show me the institutions associated with each paper in a table';
            break;
        case 'concepts':
            inputValue = 'show me the concepts associated with each paper in a table';
            break;
        case 'shadow-notes':
            inputValue = 'Shadow note specific query';
            break;
        default:
            inputValue = 'Default query';
            break;
    }
    let response = ""
    if (researchType == 'basic') {
      response = await callDeploy(inputValue)
      setTest(response.data)
    } else if (researchType == 'advanced' && !plot) {
      response = await callAgent(input, brain);
      setTest(response.data.assistant_response)
    }
    else if (researchType == 'advanced' && plot) {
      setTimeout(() => {
        setTest("Open the plot view to see the results");
        setLoading(false);
      }, 5000);
    }
    console.log(response.data)

    if (response) {
      const newWidget = {
        id: Date.now(),
        content: response.data.assistant_response || response.data,
        type: input
      };
      setWidgets(prevWidgets => [...prevWidgets, newWidget]);
    }

    setLoading(false)

    if (searchMode == 'concepts') {
      callShowEndpoint(input, searchMode, brain)
      .then(response => {
        console.log(response.data)
        setRawData(response.data)
        const grouped = groupDocumentsByPath(response.data)
        setResponseData(grouped);
        setGraphData(grouped)
      })
      .catch(error => {
        console.log(error);
      });
    }
  };

        }
        
    

  //add onClick={handleReset} back to the document-type-pill element to enable DB reset
  const handleReset = () => {
    callReset()
      .then(response => {
        console.log(responseData)
      })
      .catch(error => {
        console.log(error);
      });
  };

  // Function to count document types
  const countDocumentTypes = () => {
    const counts = {};
    if (rawData && Array.isArray(rawData.metadatas[0]) && rawData.metadatas.length > 0) {
      rawData.metadatas[0].forEach(metadata => {
        if (metadata && metadata.type) {
          const type = metadata.type;
          counts[type] = counts[type] ? counts[type] + 1 : 1;
        }
      });
    }
    return counts;
  };


  return (
    <div className="show-component" style={{ borderRight: graphView ? "0.5px solid #8e8e8e" : null}}>
    <div className="content-area">
      <Grid container spacing={2}>
        {widgets.map((widget, index) => (
          <Grid item xs={12} sm={6} md={4} key={widget.id}>
            <div className="data-card square-widget">
              <h3>{widget.type}</h3>
              <div className="widget-content">
                {widget.component ? (
                  widget.component
                ) : (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {widget.content}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          </Grid>
        ))}
        {loading && (
          <Grid item xs={12} sm={6} md={4}>
            <div className="data-card square-widget">
              <Box sx={{ width: '100%' }}>
                <LinearProgress color="inherit"/>
              </Box>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
      <div className="input-area">
        <div className="button-group">
          <button disabled={loading} onClick={(e) => {e.preventDefault(); setIsFiltered(false); setSearchMode('table'); handleClick(e, 'table', 'basic')}} className={searchMode === 'table' ? 'active' : ''}>Overview</button>
          <button disabled={loading} onClick={(e) => {e.preventDefault(); setIsFiltered(false); setSearchMode('authors'); handleClick(e, 'authors', 'basic')}} className={searchMode === 'authors' ? 'active' : ''} >Authors</button>
          <button disabled={loading} onClick={(e) => {e.preventDefault(); setIsFiltered(false); setSearchMode('methodologies'); handleClick(e, 'methodologies', 'basic')}} className={searchMode === 'methodologies' ? 'active' : ''} >Methodologies</button>
          <button disabled={loading} onClick={(e) => {e.preventDefault(); setIsFiltered(false); setSearchMode('abstracts'); handleClick(e, 'abstracts', 'basic')}} className={searchMode === 'abstracts' ? 'active' : ''} >Abstracts</button>
          <button disabled={loading} onClick={(e) => {e.preventDefault(); setIsFiltered(false); setSearchMode('introduction'); handleClick(e, 'introduction', 'basic')}} className={searchMode === 'introduction' ? 'active' : ''} >Introduction</button>
          <button disabled={loading} onClick={(e) => {e.preventDefault(); setIsFiltered(false); setSearchMode('lit-review'); handleClick(e, 'lit-review', 'basic')}} className={searchMode === 'lit-review' ? 'active' : ''} >Relational Graph</button>
          <button disabled={loading} onClick={(e) => {e.preventDefault(); setIsFiltered(false); setSearchMode('institutions'); handleClick(e, 'institutions', 'basic')}} className={searchMode === 'institutions' ? 'active' : ''} >Table</button>
          <button disabled={loading} onClick={(e) => {e.preventDefault(); setIsFiltered(false); setSearchMode('concepts'); handleClick(e, 'concepts', 'basic')}} className={searchMode === 'concepts' ? 'active' : ''} >Map</button>
          <button disabled={loading} onClick={(e) => {e.preventDefault(); setIsFiltered(false); setSearchMode('shadow-notes'); handleClick(e, 'shadow-notes', 'basic')}} className={searchMode === 'shadow-notes' ? 'active' : ''} >Plot</button>
        </div>
        <div className="input-group">
          <div className="button-group">
            <button onClick={(e) => {e.preventDefault(); setIsFiltered(false); setAdvancedSearch(!advancedSearch)}} className={'active'} style={{color: '#469037'}}>{advancedSearch ? 'Advanced' : 'Basic'}</button>
          </div> 
          <input 
            type="text" 
            value={input} 
            placeholder={advancedSearch ? 'Advanced query...' : 'Basic query...'} 
            onChange={(e) => {e.preventDefault(); setSearchMode(''); setInput(e.target.value)}} 
            className="text-input" 
            onKeyDown={e => {
              let type = advancedSearch ? 'advanced' : 'basic'
              if (e.key === 'Enter') {
                handleClick(e, 'advanced', type);
              }
            }}
          />
          <button 
            onClick={(e) => {e.preventDefault(); handleClick(e, 'advanced', 'advanced')}} 
            className="action-button">
            <ArrowUpRight className='icon'/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShowComponent;
