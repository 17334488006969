import React from 'react';
import Home from './Home'; 
import About from './About'; 
import Papers from './Product';
import Paladin from './Paladin';
import AppPage from './AppPage'; 
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import SignUp from './components/auth/SignUp';
import SignIn from './components/auth/SignIn';
import PrivateRoute from './components/auth/PrivateRoute';
import SessionValidator from './components/auth/SessionValidator';
import PaperDetail from './PaperDetail';
import WizardScout from './WizardScout';



function App() {
  return (
    <AuthProvider>
      <Router>
        <SessionValidator />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/papers" element={<Papers />} />
          <Route path="/paladin" element={<Paladin />} />
          <Route path="/paper/:id" element={<PaperDetail />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route
            path="/app"
            element={
              <PrivateRoute>
                <AppPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/research"
            element={
              <PrivateRoute>
                <WizardScout />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;