import React, { useState, useEffect } from 'react';
import ShowComponent from './components/ShowComponent';
import UploadButton from './components/UploadButton';
import { pdfjs } from 'react-pdf';
import './App.css';
import ConnectSources from './components/ConnectSources';
import Profile from './components/Profile';
import { useMyContext } from './contexts/Context';
import CreateNoteButton from './components/CreateNoteButton';
import { ArrowLeft, Check, Map as MapIcon, Settings, HelpCircle, Crosshair, Search, HardDrive, Globe } from 'react-feather';
import BurgerMenu from './components/BurgerMenu'; 
import { useMediaQuery } from 'react-responsive';
import D3Graph from './components/D3Graph';
import Brains from './components/Brains';
import GetStarted from './components/GetStarted';
import FileStore from './components/FileStore';
import SaveThought from './components/SaveThought';
import { supabase } from './services/supabaseClient';
import { getBrains, updateBrainOutlineState } from './services/brainService';
import { Table } from 'react-feather';
import TempComponent from './components/TempComponent';
import MapChart from './components/Map';
import Plot from './components/Plot';
import EcosystemDiversityDashboard from './partner_modules/EcosystemDiversityDashboard';
import ResearchQuery from './components/ResearchQuery';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


function AppPage() {
  const { fileUploaded, graphView, toggleInternetView, setGraphData, graphData, setGraphView, brain } = useMyContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [showBrains, setShowBrains] = useState(true); 
  const [brainState, setBrainState] = useState('');
  const [brainId, setBrainId] = useState(null);
  const [brainName, setBrainName] = useState(null);


  const handleBackClick = () => {
    setShowBrains(true);
    setGraphView(false);
    setGraphData(null);
    setBrainId(null);
    setBrainState('');
  };

  const handleCardClick = () => {
    setShowBrains(false);
  };

  const handleUpdate = (id, state) => {
    updateBrainOutlineState(id, state);
  };

  const getAllBrains = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session){
      const userId = session.user.id;
      const brains = await getBrains(userId);
      return brains
    }
  }

  const handleSetBrainState = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const userId = session.user.id;
        const brains = await getBrains(userId);
        for (let i = 0; i < brains.length; i++) {
          let user_id = brains[i].user_id;
          let id = brains[i].id;
          const concat = id + '-' + user_id;
          if (concat === brain) {
            setBrainState(brains[i].outline_state);
            setBrainId(brains[i].id);
          }
        }
      }

  };

  const navContent = (
    <>
      <CreateNoteButton/>
      <UploadButton/>
      <ConnectSources sources={[
        { name: 'Notion', id: 'notion' },
        { name: 'Zotero', id: 'google-docs' },
        { name: 'Bear notes', id: 'bear-notes' },
        { name: 'reMarkable', id: 'remarkable' },
        { name: 'Mendeley', id: 'slack' },
        { name: 'Obsidian', id: 'gmail' },
        { name: 'Evernote', id: 'evernote' },
        { name: 'Google drive', id: 'googledrive' },
        { name: 'Dropbox', id: 'dropbox' },
      ]} buttonText="Connect Sources" actionText={"Connect"} title={"Connect sources"} subtitle={"Select where you want to sync your thinking from"}/>
    </>
  );

  const sideNavContent = (
    <>
    <img src="/logo.png" alt="Logo" style={{ width: "30px", marginTop: '20px' }} onClick={() => window.location.href = "https://www.shadowresearch.co/"} />

      <Search size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>
      <Crosshair size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>
      <HardDrive size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>
      <Globe size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>
      <Profile/>
      <Settings size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>

    </>
  );

  const markers = [
    {
      name: "Dembecha",
      position: [10.625, 37.29],
      description: "Main study area",
      paper: "Zeleke, G. and Hurni, H. (2001). Implications of Land Use and Land Cover Dynamics for Mountain Resource Degradation in the Northwestern Ethiopian Highlands.",
      stakeholder: "Local farmers and agricultural officials"
    },
    {
      name: "Mt. Choke",
      position: [10.66, 37.85],
      description: "Referenced mountain",
      paper: "Zeleke, G. and Hurni, H. (2001). Implications of Land Use and Land Cover Dynamics for Mountain Resource Degradation in the Northwestern Ethiopian Highlands.",
      stakeholder: "Environmental conservation groups"
    },
    {
      name: "Addis Ababa",
      position: [9.02, 38.74],
      description: "Capital city, affected by urban planning issues related to environmental degradation",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "National policymakers and urban planners"
    },
    {
      name: "Blue Nile",
      position: [11.59, 35.09],
      description: "Major river affected by land use changes and soil erosion",
      paper: "El-Swaify, S.A. and Hurni, H. (1996). Transboundary effects of soil erosion and conservation in the Nile Basin.",
      stakeholder: "Water resource managers and downstream countries"
    },
    {
      name: "Gojam Region",
      position: [10.95, 37.5],
      description: "Broader region of study, severely affected by soil erosion and deforestation",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "Regional agricultural and environmental authorities"
    },
    {
      name: "Ethiopian Highlands",
      position: [9.5, 38.5],
      description: "Major focus of environmental degradation studies. Over 14 million hectares seriously eroded, 13 million hectares moderately eroded.",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "Environmental researchers, policymakers, and local communities"
    },
    {
      name: "Welo",
      position: [11.8, 39.6],
      description: "Region with highest rates of erosion in northern Ethiopia",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "Local farmers, environmental agencies"
    },
    {
      name: "Harerge",
      position: [9.3, 42.5],
      description: "Region in southeastern Ethiopia with lower erosion rates due to less remaining soil",
      paper: "Teketay, D. (2001). Deforestation, Wood Famine, and Environmental Degradation in Ethiopia's Highland Ecosystems: Urgent Need for Action.",
      stakeholder: "Local communities, soil conservation experts"
    },
    {
      name: "Bahir Dar",
      position: [11.59, 37.39],
      description: "City near Lake Tana, affected by water management issues",
      paper: "Alemayehu, T. (2006). Water Quality Monitoring in Lake Tana Catchment.",
      stakeholder: "Local government and water resource managers"
  },
  {
      name: "Gondar",
      position: [12.6, 37.47],
      description: "Historical city with environmental conservation efforts",
      paper: "Eshetu, Z. (2002). Forest Conservation in the Gondar Region.",
      stakeholder: "Heritage conservationists and local communities"
  },
  {
      name: "Jimma",
      position: [7.67, 36.83],
      description: "City with agricultural research centers",
      paper: "Bekele, T. (2003). Agricultural Research and Development in Jimma.",
      stakeholder: "Agricultural researchers and local farmers"
  },
  {
      name: "Hawassa",
      position: [7.06, 38.48],
      description: "City by Lake Hawassa, facing urbanization challenges",
      paper: "Gebremariam, Z. (2009). Urbanization and Environmental Impact in Hawassa.",
      stakeholder: "Urban planners and environmental agencies"
  },
  {
      name: "Dire Dawa",
      position: [9.6, 41.87],
      description: "City with industrial pollution issues",
      paper: "Kebede, A. (2010). Industrial Pollution in Dire Dawa.",
      stakeholder: "Environmental protection agencies and local industries"
  },
  {
      name: "Mekelle",
      position: [13.49, 39.47],
      description: "City with soil erosion and conservation projects",
      paper: "Tesfaye, A. (2005). Soil Erosion and Conservation in Mekelle.",
      stakeholder: "Soil conservation experts and local farmers"
  },
  {
      name: "Arba Minch",
      position: [6.04, 37.55],
      description: "City near the Rift Valley lakes, facing water resource challenges",
      paper: "Wolde, M. (2008). Water Resource Management in Arba Minch.",
      stakeholder: "Water resource managers and local communities"
  },
  {
      name: "Harar",
      position: [9.31, 42.12],
      description: "Historical city with heritage conservation efforts",
      paper: "Abebe, T. (2011). Heritage Conservation in Harar.",
      stakeholder: "Heritage conservationists and local communities"
  },
  {
      name: "Adama",
      position: [8.54, 39.27],
      description: "City with renewable energy projects",
      paper: "Yohannes, D. (2012). Renewable Energy Development in Adama.",
      stakeholder: "Energy sector stakeholders and local government"
  },
  {
      name: "Gambela",
      position: [8.25, 34.59],
      description: "Region with biodiversity conservation projects",
      paper: "Mengistu, W. (2013). Biodiversity Conservation in Gambela.",
      stakeholder: "Environmental researchers and local communities"
  }
  ];

  const plotData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    values: [65, 59, 80, 81, 56, 55, 40],
  };


  return (
      <>
          {fileUploaded && <div className="notification-popup"><Check size={"16px"}/>your files are uploaded and searchable</div>}
        <div className="flex-row-container">

          <div className="app-container">
          <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #404040', height: '100vh', alignItems: 'center', gap: '20px', width: '54px'}}>
                    {!showBrains && ( <>
                        <button className="back-button" onClick={handleBackClick} style={{padding: '10px 15px' }}><ArrowLeft className='icon' size={24}/></button>
                        {navContent}
                        <button className="graph-toggle" onClick={() => {handleSetBrainState(); toggleInternetView();}}><MapIcon className='icon' size={20}/></button>

                      </>
                      )}
                    {isMobile ? <BurgerMenu>{sideNavContent}</BurgerMenu> : sideNavContent}
                  </div>
            <div className="column" style={{justifyContent: graphView ? 'space-between' : 'center'}}> 
                {showBrains ? (
                  <>
                  <Brains onCardClick={handleCardClick} passBrainName={setBrainName}/>
                  <FileStore onCardClick={handleCardClick} getAllBrains={getAllBrains}/>

                  {/* <GetStarted onCardClick={handleCardClick} passBrainName={setBrainName}/> */}
                  </>

                ) : (
                  <>
                    <ShowComponent/>
                    {graphView && graphData ? <MapChart markers={markers}/> : null}
                    {/* {graphView && graphData ? <MapChart markers={markers}/> : null} */}
                    {/* {graphView && !graphData ? <TempComponent/> : null} */}
                    {/* {graphView && !graphData ? <SaveThought state ={brainState} id={brainId} update={handleUpdate}/> : null} */}
                    {graphView && !graphData ? <Plot /> : null}

                  </>
                )}
            </div>
          </div>
        </div>
      </>
  );
}

export default AppPage;