import { supabase } from './supabaseClient';

// Function to insert a new brain
export const insertBrain = async (brain) => {
  const { error } = await supabase
    .from('brains')
    .insert([brain]);

  if (error) {
    console.error('Error inserting brain:', error);
    return false;
  }
  return true;
};

// Function to retrieve brains for a specific user
export const getBrains = async (userId) => {
  const { data, error } = await supabase
    .from('brains')
    .select('*')
    .eq('user_id', userId);

  if (error) {
    console.error('Error retrieving brains:', error);
    return [];
  }
  return data;
};


// Function to delete a brain by its ID
export const deleteBrain = async (brainId) => {
  const { error } = await supabase
    .from('brains')
    .delete()
    .eq('id', brainId);

  if (error) {
    console.error('Error deleting brain:', error);
    return false;
  }
  return true;
};

// Function to retrieve a single brain by its ID
export const getBrainById = async (brainId) => {
  const { data, error } = await supabase
    .from('brains')
    .select('*')
    .eq('id', brainId)
    .single();

  if (error) {
    console.error('Error retrieving brain:', error);
    return null;
  }
  return data;
};

// Function to update the outline_state of a brain by its ID
export const updateBrainOutlineState = async (brainId, outlineState) => {
  const { error } = await supabase
    .from('brains')
    .update({ outline_state: outlineState })
    .eq('id', brainId);

  if (error) {
    console.error('Error updating brain outline_state:', error);
    return false;
  }
  return true;
};