import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import TopNav from './components/TopNav';
import { fetchPaperData } from './services/paperService';
import './PaperDetail.css';

function PaperDetail() {
  const { id } = useParams();
  const [paper, setPaper] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPaper = async () => {
      setLoading(true);
      try {
        const paperData = await fetchPaperData(id);
        setPaper(paperData);
        setError(null);
      } catch (err) {
        setError(err.message);
        setPaper(null);
      } finally {
        setLoading(false);
      }
    };

    loadPaper();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !paper) {
    return <div>Error: {error || 'Paper not found'}</div>;
  }

  const parseAbstract = (text) => {
    return text.split('\n').map((paragraph, index) => (
      <p key={index} dangerouslySetInnerHTML={{
        __html: paragraph.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      }} />
    ));
  };

  const renderSection = (section) => {
    switch (section.type) {
      case 'text':
        return <p>{section.content}</p>;
      case 'list':
        return (
          <ol>
            {section.items.map((item, index) => (
              <li key={index}>
                {typeof item === 'string' ? item : renderSection(item)}
              </li>
            ))}
          </ol>
        );
      case 'graph':
        return (
          <div className="graph-container">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={section.data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={section.xDataKey} />
                <YAxis />
                <Tooltip />
                <Legend />
                {section.bars.map((bar, index) => (
                  <Bar key={index} dataKey={bar.dataKey} fill={bar.color} name={bar.name} />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="paper-detail-container">
      <TopNav />
      <div className="paper-content">
      <div className="paper-pdf">
          <iframe
            src={`${process.env.PUBLIC_URL}/reports/${paper.pdfFileName}#toolbar=0`}
            title="Paper PDF"
            width="100%"
            height="100%"
          />
        </div>
        <div className="paper-info">
          <h1>{paper.title}</h1>
          <div className="authors">{paper.authors.join(', ')}</div>
          {/* <section className="abstract">
            <h2>Abstract</h2>
            {parseAbstract(paper.abstract)}
          </section> */}
          {paper.sections.map((section, index) => (
            <section key={index} className={section.className} style={{ marginTop: '5vh', backgroundColor: 'rgba(0, 128, 0, 0.081)', border: '0.5px solid rgba(0, 128, 0, 0.3)', borderRadius: '10px', padding: '20px' }}>
              <h2>{section.title}</h2>
              {section.description && <p>{section.description}</p>}
              {renderSection(section)}
              {section.footer && <p className="section-footer">{section.footer}</p>}
            </section>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PaperDetail;