import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ShowComponent from './components/ShowComponent';
import UploadButton from './components/UploadButton';
import { pdfjs } from 'react-pdf';
import './App.css';
import ConnectSources from './components/ConnectSources';
import Profile from './components/Profile';
import { useMyContext } from './contexts/Context';
import CreateNoteButton from './components/CreateNoteButton';
import { ArrowLeft, Check, Map as MapIcon, Settings, HelpCircle, Crosshair, Search, HardDrive, Globe } from 'react-feather';
import BurgerMenu from './components/BurgerMenu'; 
import { useMediaQuery } from 'react-responsive';
import D3Graph from './components/D3Graph';
import Brains from './components/Brains';
import GetStarted from './components/GetStarted';
import FileStore from './components/FileStore';
import SaveThought from './components/SaveThought';
import { supabase } from './services/supabaseClient';
import { getBrains, updateBrainOutlineState } from './services/brainService';
import { Table } from 'react-feather';
import TempComponent from './components/TempComponent';
import MapChart from './components/Map';
import Plot from './components/Plot';
import EcosystemDiversityDashboard from './partner_modules/EcosystemDiversityDashboard';
import ResearchQuery from './components/ResearchQuery';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


function WizardScout() {
  const { fileUploaded, graphView, toggleInternetView, setGraphData, graphData, setGraphView, brain } = useMyContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [showBrains, setShowBrains] = useState(true); 
  const [brainState, setBrainState] = useState('');
  const [brainId, setBrainId] = useState(null);
  const [brainName, setBrainName] = useState(null);
  const navigate = useNavigate();



  const handleBackClick = () => {
    setShowBrains(true);
    setGraphView(false);
    setGraphData(null);
    setBrainId(null);
    setBrainState('');
  };

  const handleCardClick = () => {
    setShowBrains(false);
  };

  const handleUpdate = (id, state) => {
    updateBrainOutlineState(id, state);
  };

  const getAllBrains = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session){
      const userId = session.user.id;
      const brains = await getBrains(userId);
      return brains
    }
  }

  const handleSetBrainState = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const userId = session.user.id;
        const brains = await getBrains(userId);
        for (let i = 0; i < brains.length; i++) {
          let user_id = brains[i].user_id;
          let id = brains[i].id;
          const concat = id + '-' + user_id;
          if (concat === brain) {
            setBrainState(brains[i].outline_state);
            setBrainId(brains[i].id);
          }
        }
      }

  };

  const navContent = (
    <>
      <CreateNoteButton/>
      <UploadButton/>
      <ConnectSources sources={[
        { name: 'Notion', id: 'notion' },
        { name: 'Zotero', id: 'google-docs' },
        { name: 'Bear notes', id: 'bear-notes' },
        { name: 'reMarkable', id: 'remarkable' },
        { name: 'Mendeley', id: 'slack' },
        { name: 'Obsidian', id: 'gmail' },
        { name: 'Evernote', id: 'evernote' },
        { name: 'Google drive', id: 'googledrive' },
        { name: 'Dropbox', id: 'dropbox' },
      ]} buttonText="Connect Sources" actionText={"Connect"} title={"Connect sources"} subtitle={"Select where you want to sync your thinking from"}/>
    </>
  );

  const sideNavContent = (
    <>
    <img src="/logo.png" alt="Logo" style={{ width: "30px", marginTop: '20px' }} onClick={() => window.location.href = "https://www.shadowresearch.co/"} />
        <button className="back-button" onClick={() => navigate('/app')} style={{padding: '10px 15px' }}><ArrowLeft className='icon' size={24}/></button>
      <Search size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>
      <Crosshair size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>
      <HardDrive size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>
      <Globe size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>
      <Profile/>
      <Settings size={20} style={{color: 'white', marginTop: '20px', padding: '15px'}}/>

    </>
  );


  return (
      <>
          {fileUploaded && <div className="notification-popup"><Check size={"16px"}/>your files are uploaded and searchable</div>}
        <div className="flex-row-container">

          <div className="app-container">
          <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #404040', height: '100vh', alignItems: 'center', gap: '20px', width: '54px'}}>
                    {!showBrains && ( <>
                        <button className="back-button" onClick={handleBackClick} style={{padding: '10px 15px' }}><ArrowLeft className='icon' size={24}/></button>
                        {navContent}
                        <button className="graph-toggle" onClick={() => {handleSetBrainState(); toggleInternetView();}}><MapIcon className='icon' size={20}/></button>

                      </>
                      )}
                    {isMobile ? <BurgerMenu>{sideNavContent}</BurgerMenu> : sideNavContent}
                  </div>
            <div className="column" style={{justifyContent: graphView ? 'space-between' : 'center'}}> 
                  <ResearchQuery/>
            </div>
          </div>
        </div>
      </>
  );
}

export default WizardScout;