import React, { useState, useRef } from 'react';
import './Paladin.css';

const reportData = {
  1: {
    title: "Project Impact Report: Forest Restoration and Community Resilience",
    content: [
      {
        type: "section",
        title: "1. Stakeholder Engagement and Research Impact",
        content: [
          { type: "paragraph", text: "Annual monitoring records show significant stakeholder engagement across various levels:" },
          {
            type: "table",
            headers: ["Stakeholder Type", "Number Engaged", "Percentage"],
            rows: [
              ["Community members", "1,250", "45%"],
              ["District/Provincial officials", "75", "15%"],
              ["National agencies", "30", "25%"],
              ["International organizations", "15", "15%"]
            ]
          },
          { type: "paragraph", text: "Engagement with national, regional, and international agencies included collaborations with UNFAO and UNEP, contributing to a broader impact on sustainable biodiversity use." },
          { type: "paragraph", text: "Qualitative surveys indicate that 80% of engaged stakeholders have taken concrete actions towards more sustainable use of biodiversity, exceeding our performance criteria." }
        ]
      },
      {
        type: "section",
        title: "2. Forest Restoration and Agroforestry Impact",
        content: [
          { type: "paragraph", text: "Long-term forest restoration trials have shown promising results:" },
          {
            type: "list",
            items: [
              "Natural regeneration areas: 500 hectares",
              "Active restoration areas: 750 hectares",
              "Success rate of framework species method: 85%"
            ]
          },
          { type: "paragraph", text: "Agroforestry practices have been significantly strengthened:" },
          {
            type: "list",
            items: [
              "Established livestock forage grass areas: 300 hectares",
              "Ensete ventricosum plantations: 150 hectares",
              "Other multipurpose trees planted: 50,000"
            ]
          }
        ]
      },
      {
        type: "section",
        title: "3. Community Resilience Enhancement",
        content: [
          { type: "paragraph", text: "Annual surveys show increased community engagement in forest restoration and agroforestry:" },
          {
            type: "table",
            headers: ["Age Group", "Gender", "Number Engaged", "Activity Type"],
            rows: [
              ["18-30", "Male", "450", "Direct forest restoration"],
              ["18-30", "Female", "400", "Direct forest restoration"],
              ["31-50", "Male", "350", "Agroforestry practices"],
              ["31-50", "Female", "300", "Agroforestry practices"],
              ["50+", "All", "200", "Indirect support and knowledge sharing"]
            ]
          }
        ]
      },
      {
        type: "section",
        title: "4. Scientific Output and Knowledge Dissemination",
        content: [
          { type: "paragraph", text: "The project has significantly contributed to scientific knowledge:" },
          {
            type: "list",
            items: [
              "Journal articles published: 15",
              "Scientific datasets made publicly available: 8",
              "Policy and information products distributed: 25"
            ]
          },
          { type: "paragraph", text: "These outputs have been accessed over 10,000 times through various repositories and websites, indicating strong interest and potential for impact in the scientific community." }
        ]
      },
      {
        type: "section",
        title: "5. Ecosystem Services Valuation and Community Involvement",
        content: [
          { type: "paragraph", text: "Valuation of ecosystem services generated by forest and agroforestry actions shows a significant increase in value:" },
          {
            type: "list",
            items: [
              "Total estimated value: $5.2 million annually",
              "Increase from baseline: 35%"
            ]
          },
          { type: "paragraph", text: "Community involvement in nature-based solutions (NBS) has been substantial:" },
          {
            type: "list",
            items: [
              "Community members involved in NBS planning: 750",
              "Resources applied to nursery infrastructure: $250,000",
              "Community-led NBS initiatives: 12"
            ]
          }
        ]
      },
      {
        type: "section",
        title: "6. Capacity Building and Stakeholder Engagement",
        content: [
          { type: "paragraph", text: "Feedback from stakeholder workshops and training sessions has been overwhelmingly positive:" },
          {
            type: "list",
            items: [
              "Total participants: 500",
              "Average satisfaction score: 4.5/5",
              "Reported knowledge increase: 85%"
            ]
          },
          { type: "paragraph", text: "Academic involvement:" },
          {
            type: "list",
            items: [
              "BSc students involved: 30",
              "MSc students involved: 15",
              "PhD students involved: 5"
            ]
          }
        ]
      },
      {
        type: "section",
        title: "7. Policy Impact and Institutional Involvement",
        content: [
          { type: "paragraph", text: "The project has successfully engaged various institutions:" },
          {
            type: "table",
            headers: ["Institution Type", "Number Involved", "Level of Involvement"],
            rows: [
              ["Local government", "15", "High"],
              ["National agencies", "5", "Medium"],
              ["NGOs", "10", "High"],
              ["Academic institutions", "3", "High"],
              ["International organizations", "2", "Medium"]
            ]
          },
          { type: "paragraph", text: "Number of people participating and leading at all levels: 250, indicating strong engagement and potential for long-term policy impact." }
        ]
      }
    ]
  },
  2: {
    title: "Newsletter: Climate Research in Ethiopia",
    content: [
      {
        type: "section",
        title: "Recent Events",
        content: [
          { type: "paragraph", text: "The Ethiopian Climate Research Conference was held last month, bringing together researchers from across the country to discuss the latest findings in climate science." },
          { type: "paragraph", text: "A workshop on 'Climate-Smart Agriculture' was conducted in Addis Ababa, attended by over 100 farmers and agricultural experts." },
          {
            type: "table",
            headers: ["Event", "Date", "Participants", "Key Outcomes"],
            rows: [
              ["Ethiopian Climate Research Conference", "May 15-17, 2023", "250", "5 new research collaborations initiated"],
              ["Climate-Smart Agriculture Workshop", "June 5, 2023", "100", "20 farmers adopted new techniques"],
              ["Drought Resilience Symposium", "June 20, 2023", "150", "New policy recommendations drafted"]
            ]
          }
        ]
      },
      {
        type: "section",
        title: "New Tools and Technologies",
        content: [
          { type: "paragraph", text: "The Ethiopian Meteorological Institute has launched a new mobile app for real-time weather forecasting and climate data access." },
          { type: "paragraph", text: "A consortium of universities has developed a high-resolution climate model specifically calibrated for the Horn of Africa region." },
          {
            type: "list",
            items: [
              "EthioClimate App: Available on iOS and Android, 10,000+ downloads in the first month",
              "Horn of Africa Climate Model (HACM): 50% improvement in prediction accuracy for regional rainfall patterns",
              "Drought Early Warning System: Implemented in 5 regions, covering 60% of agricultural land"
            ]
          }
        ]
      },
      {
        type: "section",
        title: "Latest Datasets",
        content: [
          { type: "paragraph", text: "A comprehensive dataset of Ethiopian forest cover changes over the past 30 years has been made publicly available." },
          { type: "paragraph", text: "The National Agricultural Research Institute has released a new database of drought-resistant crop varieties suitable for different regions of Ethiopia." },
          {
            type: "table",
            headers: ["Dataset", "Time Span", "Resolution", "Key Findings"],
            rows: [
              ["Ethiopian Forest Cover", "1990-2020", "30m", "15% decrease in forest cover"],
              ["Drought-Resistant Crops", "2010-2023", "N/A", "50 new varieties identified"],
              ["Climate Change Impacts", "1950-2022", "Daily", "1.5°C average temperature increase"]
            ]
          }
        ]
      },
      {
        type: "section",
        title: "Ongoing Projects",
        content: [
          { type: "paragraph", text: "The 'Green Corridor Initiative' aims to create a network of protected areas to facilitate species migration in response to climate change." },
          { type: "paragraph", text: "A joint project between Ethiopian and international researchers is studying the impact of climate change on coffee production in the country." },
          {
            type: "table",
            headers: ["Project", "Duration", "Funding", "Expected Outcomes"],
            rows: [
              ["Green Corridor Initiative", "2023-2028", "$10 million", "1000 km of connected habitats"],
              ["Climate-Resilient Coffee", "2022-2025", "$5 million", "New cultivation methods for 50,000 farmers"],
              ["Urban Heat Island Study", "2023-2024", "$1 million", "Mitigation strategies for 3 major cities"]
            ]
          }
        ]
      },
      {
        type: "section",
        title: "Capacity Building",
        content: [
          { type: "paragraph", text: "Several initiatives are underway to build capacity in climate research and adaptation:" },
          {
            type: "list",
            items: [
              "Climate Science Fellowship Program: 20 PhD students funded for advanced studies",
              "Regional Climate Change Workshops: Conducted in 11 regions, training over 500 local officials",
              "School Climate Ambassador Program: Launched in 100 schools, reaching 10,000 students"
            ]
          },
          {
            type: "table",
            headers: ["Program", "Participants", "Duration", "Impact"],
            rows: [
              ["Climate Science Fellowship", "20", "4 years", "15 new research projects initiated"],
              ["Regional Workshops", "500", "1 year", "30 local adaptation plans developed"],
              ["School Ambassador Program", "10,000", "Ongoing", "25% increase in climate awareness among youth"]
            ]
          }
        ]
      }
    ]
  }
};

function Paladin() {
  const [template, setTemplate] = useState(null);
  const [schedule, setSchedule] = useState('weekly');
  const [emails, setEmails] = useState('');
  const [title, setTitle] = useState('');
  const [requireApproval, setRequireApproval] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [contentSources, setContentSources] = useState({
    GoogleScholar: false,
    SemanticScholar: false,
    Google: false,
    Git: false,
    ProductHunt: false,
    News: false,
    Twitter: false
    });
  const [existingReports] = useState([
    { id: 1, title: 'Final Project Report', date: '2023-04-015', recipients: ['jessen@shadowresearch.com', 'nico.bent@shadowresearch.com'], status: 'complete' },
    { id: 2, title: 'Newsletter: Climate Research in Ethiopia', date: '2023-04-015', recipients: ['jessen@shadowresearch.com', 'nico.bent@shadowresearch.com'], status: 'complete' },
    { id: 3, title: 'Insights on Stakeholder data', date: '2023-04-015', recipients: ['jessen@shadowresearch.com', 'nico.bent@shadowresearch.com'], status: 'upcoming' },
    { id: 4, title: 'Top-level on stakeholder data', date: '2023-04-015', recipients: ['jessen@shadowresearch.com', 'nico.bent@shadowresearch.com'], status: 'upcoming' },
    { id: 5, title: 'Meta-analysis on state of the field', date: '2023-05-15', recipients: ['jessen@shadowresearch.com', 'nico.bent@shadowresearch.com'], status: 'upcoming' },
    // Add more sample reports as needed
  ]);

  const handleContentSourceChange = (event) => {
    setContentSources({
      ...contentSources,
      [event.target.name]: event.target.checked
    });
  };

  const handleReportClick = (reportId) => {
    const report = reportData[reportId];
    if (report) {
      setCurrentReport(report);
      setShowModal(true);
    }
  };

  const renderReportContent = (content) => {
    return content.map((item, index) => {
      switch (item.type) {
        case 'section':
          return (
            <div key={index} className="section">
              <h2>{item.title}</h2>
              {renderReportContent(item.content)}
            </div>
          );
        case 'paragraph':
          return <p key={index}>{item.text}</p>;
        case 'table':
          return (
            <table key={index}>
              <thead>
                <tr>
                  {item.headers.map((header, i) => <th key={i}>{header}</th>)}
                </tr>
              </thead>
              <tbody>
                {item.rows.map((row, i) => (
                  <tr key={i}>
                    {row.map((cell, j) => <td key={j}>{cell}</td>)}
                  </tr>
                ))}
              </tbody>
            </table>
          );
        default:
          return null;
      }
    });
  };


  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0]);
    }
  };

  const handleFiles = (file) => {
    setTemplate(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send this data to your backend
    console.log({ template, schedule, emails, requireApproval });
  };

  return (
    <div className="paladin-container">
      <div className="paladin-columns">
      <div className="paladin-column left-column">
        <h3>Reports</h3>
        <div className="report-list">
          {existingReports.map(report => (
            <div key={report.id} className="report-card" onClick={() => handleReportClick(report.id)}>
              <div className="report-header">
                <h4>{report.title}</h4>
                <span className={`status-pill ${report.status}`}>{report.status}</span>
              </div>
              <p>Date: {report.date}</p>
              <p>Recipients: {report.recipients.join(', ')}</p>
            </div>
          ))}
        </div>
      </div>
        <div className="paladin-column right-column">
          <h3>Report Configuration</h3>
          <form onSubmit={handleSubmit} className="paladin-form">
          <div className="form-group">
          <label htmlFor="title">Report Title:</label>
          <textarea
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="What would you like to call this report?"
          />
        </div>
        <div className="form-group">
              <label>Content Sources to Track:</label>
              <div className="checkbox-group">
                {Object.keys(contentSources).map((source) => (
                  <div key={source}>
                    <input
                      type="checkbox"
                      id={source}
                      name={source}
                      checked={contentSources[source]}
                      onChange={handleContentSourceChange}
                    />
                    <label htmlFor={source}>{source}</label>
                  </div>
                ))}
              </div>
            </div>
          <div className="form-group">
          <label htmlFor="template">Upload Report Template:</label>
          <div 
            className={`drag-drop-area ${dragActive ? "drag-active" : ""}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <input
              type="file"
              id="template"
              ref={fileInputRef}
              onChange={handleChange}
              accept=".docx,.pdf,.txt"
              style={{display: 'none'}}
            />
            <p>Drag and drop template file here or</p>
            <button type="button" onClick={() => fileInputRef.current.click()}>
              Select File
            </button>
          </div>
          {template && <div className="uploaded-file">Uploaded: {template.name}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="schedule">Reporting Schedule:</label>
          <select
            id="schedule"
            value={schedule}
            onChange={(e) => setSchedule(e.target.value)}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="manual">Manual</option>

          </select>
        </div>

        <div className="form-group">
          <label htmlFor="emails">Recipient Emails:</label>
          <textarea
            id="emails"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            placeholder="Enter email addresses, separated by commas"
          />
        </div>

        <div className="form-group checkbox-group">
          <input
            type="checkbox"
            id="requireApproval"
            checked={requireApproval}
            onChange={(e) => setRequireApproval(e.target.checked)}
          />
          <label htmlFor="requireApproval">Require approval before sending</label>
        </div>

        <button type="submit" className="submit-button">Save Configuration</button>
          </form>
        </div>
      </div>
      {showModal && currentReport && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <h1>{currentReport.title}</h1>
            {renderReportContent(currentReport.content)}
          </div>
        </div>
      )}
    </div>
  );
}

export default Paladin;