import React from 'react';
import { Link } from 'react-router-dom';
import { Crosshair } from 'react-feather';


function Card({ title, description, link, linkText, className, icon: Icon, imageUrl, examples = [] }) {
  return (
    <div className={`homecard ${className}`}>
      {imageUrl && <img src={imageUrl} alt={title} className="homecard-image" />}
      <div className="homecard-content">
        <h2 style={{fontWeight: 'bold', fontFamily: 'Arial, sans-serif', display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "5px"}}>
        {Icon && <Icon style={{height: "24px", width: "24px",color: '#469037' }}/>}
          {title}
          </h2>
        <p>{description}</p>
        {examples.length > 0 && (
          <div className="homecard-examples">
            {examples.map((example, index) => (
              <span key={index} className="homecard-example-pill">{example}</span>
            ))}
          </div>
        )}
      </div>
      <Link to={link} className="homecard-link">{linkText}</Link>

    </div>
  );
}

export default Card;