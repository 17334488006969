import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  const session = sessionStorage.getItem('supabaseSession');
  return session ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;