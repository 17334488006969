import React, { useState } from 'react';
import './Home.css'; 
import TopNav from './components/TopNav'; 


function About() {
  return (
    <div className="about-container">
      <TopNav />
      <section className="cards-section-about" style={{maxWidth: '800px'}}>
      <div className="feature-card">
          <p className="text-primary" style={{fontWeight: 'bold', fontFamily: 'Bodoni'}}>Discover the unknown<span style={{color: '#a55fa5'}}>.</span></p>
          <p>At Shadow, we're on a mission to change how research is produced and consumed. We want to make it easier, faster, and more open for everyone.
We believe that researchers should spend less time on boring stuff and more time on big ideas. That's why we're building tools that use AI to help with the heavy lifting.
<br/><br/>

<b style={{fontFamily: 'Bodoni', fontSize: 24}}>Supporting Researchers</b>
<br/>
We're here for the scientists, the thinkers, and the curious minds. Our platform is designed to make your life easier, so you can focus on what you do best - coming up with groundbreaking ideas.
<br/><br/>
<b style={{fontFamily: 'Bodoni', fontSize: 24}}>Accelerating Discovery</b>
<br/>
We want to speed things up. By using AI to handle time-consuming tasks, we're helping researchers get from idea to publication faster than ever before. This means more discoveries, more often.
<br/><br/>
<b style={{fontFamily: 'Bodoni', fontSize: 24}}>Open-Access</b><br/>
Knowledge shouldn't be locked behind paywalls. We're committed to making research freely available to everyone. It's about sharing ideas and building on each other's work.

<br/><br/>
<b style={{fontFamily: 'Bodoni', fontSize: 24}}>Responsible AI</b>
<br/>
We're not replacing researchers with robots. Instead, we're using AI as a tool to enhance human capabilities. We're careful about how we use AI, always putting ethics and integrity first.
<br/><br/>
<b style={{fontFamily: 'Bodoni', fontSize: 24}}>Democratizing Knowledge</b>
<br/>
Complex ideas shouldn't be limited to experts. We're turning research into interactive "brains" that anyone can explore and understand. It's about making science accessible to everyone.
<br/><br/>
At Shadow, we're not just dreaming about the future of research - we're building it. We see a world where discoveries happen faster, where knowledge is shared freely, and where anyone with curiosity can dive into the latest research.
<br/><br/>
We're excited about what this could mean for science, for innovation, and for solving big problems. And we want you to be part of it. Whether you're a seasoned researcher or just someone who loves to learn, there's a place for you in the future we're creating.
Join us as we push the boundaries of what's possible in research. Together, we can unlock new ideas, drive progress, and make the world a little bit smarter, one discovery at a time.</p>
          <p>Expedite scientific discovery and innovation by transforming static publications, and the knowledge collected to produce them, into dynamic, interactive agents that can be easily shared and deployed in various research tasks.</p>
          <p>We're building a future where every academic paper is published with an associated Shadow brain and state of the art knowledge is accessible to everyone.</p>
          <br/>
          <br/>
          <p className="text-primary" style={{fontSize: 24}}>Contact us</p>
          <p style={{fontSize: 16}}>Jessen Gibbs (CEO): jessen[@]shadowresearch.co<br/>Nicolas Bent (CTO): nico.bent[@]shadowresearch.co</p>
      </div>
      </section>

    </div>
    );
}


export default About;