import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useMyContext } from '../contexts/Context';
import Legend from './Legend'; 
import "./D3Graph.css"


const D3Graph = ({ data }) => {
  const d3Container = useRef(null);
  const { graphData, graphFilter, graphSourceFilter, brain } = useMyContext();
  const [filterText, setFilterText] = useState(graphFilter); // Add this line
  const [singletonNotes, setUseSingletonNotes] = useState(true); // Initialize based on your default preference

  window.onunhandledrejection = function(event) {
    console.log('Unhandled rejection:', event.reason);
    event.preventDefault(); // Prevents the default browser handling
};
 
  const legendItems = [
    { color: "#9a7bc9", label: "Paper" },
    { color: "#ce772f", label: "Topic" },
    { color: "#44c14e", label: "Focused" }
  ];

  const toggleUseSingletonNotes = () => {
    setUseSingletonNotes(prevState => !prevState);
  };

  function createGraphFromData(graphData, filterText = null, singletonNotes, filterSource = null) {
    console.log("graphdata:", graphData)
    const nodes = [];
    const links = [];

    if (!graphData || !graphData[Object.keys(graphData)[0]] || !graphData[Object.keys(graphData)[0]].documents) {
      return { nodes, links }; // Return empty nodes and links as the null-state
    }
  
    const conceptIndexMap = new Map();
    const noteToConceptsMap = new Map();
    const noteIndexMap = new Map(); // To keep track of note nodes by their IDs
    const noteContentMap = new Map(); // Add this line to keep track of note nodes by their content, only used if singletonNotes is true
  

    const path = Object.keys(graphData)[0];
  
    // First, create all concept nodes and map notes to their concepts
    graphData[path].documents.forEach((doc, index) => {
      if (filterSource === null || graphData[path].metadatas[index].title === filterSource) {
      if (filterText === null || doc === filterText) {
        if (!conceptIndexMap.has(doc)) {
          conceptIndexMap.set(doc, nodes.length); // Map concept to its node index
          nodes.push({ id: `Concept ${nodes.length + 1}`, type: "concept", content: doc });
        }
  
        const metadata = graphData[path].metadatas[index];
        const noteContent = metadata.note;
        let noteId;
  
        if (singletonNotes) {
          // Check if the note with the same content already exists
          if (noteContentMap.has(noteContent)) {
            noteId = noteContentMap.get(noteContent);
          } else {
            noteId = `Note ${nodes.length + 1}`;
            noteContentMap.set(noteContent, noteId); // Map note content to its node ID
            noteIndexMap.set(noteId, nodes.length); // Map note ID to its node index
            nodes.push({ id: noteId, type: "note", content: noteContent, date: metadata.date, path: metadata.path, title: metadata.title });
          }
        } else {
          noteId = `Note ${index + 1}`;
          if (!noteIndexMap.has(noteId)) {
            noteIndexMap.set(noteId, nodes.length); // Map note to its node index
            nodes.push({ id: noteId, type: "note", content: metadata.note, date: metadata.date, path: metadata.path, title: metadata.title });
          }
        }
  
        if (!noteToConceptsMap.has(noteId)) {
          noteToConceptsMap.set(noteId, []);
        }
        noteToConceptsMap.get(noteId).push(doc);
      }
    }
    });
  
    // Then, create links for notes to their associated concepts
    noteToConceptsMap.forEach((concepts, noteId) => {
      concepts.forEach(conceptDoc => {
        if (conceptIndexMap.has(conceptDoc)) {
          const conceptNodeId = nodes[conceptIndexMap.get(conceptDoc)].id;
          // Use noteIndexMap to find the index of the note node
          const noteNodeId = nodes[noteIndexMap.get(noteId)].id;
          links.push({ source: noteNodeId, target: conceptNodeId, value: 1 });
        }
      });
    });
  
    return { nodes, links };
  }



  useEffect(() => {
    const { nodes, links } = createGraphFromData(data, graphFilter, singletonNotes, graphSourceFilter);
  
    if (nodes.length && links.length && d3Container.current) {
      const svg = d3.select(d3Container.current);
      
      svg.selectAll("*").remove(); // Clear svg content before adding new elements
  
      const width = +svg.attr("width");
      const height = +svg.attr("height");
  
      // Define zoom behavior
      const zoom = d3.zoom()
        .scaleExtent([1 / 8, 5])
        .on("zoom", (event) => {
          g.attr("transform", event.transform);
        });
  
      svg.call(zoom);
  
      const g = svg.append("g");
  
      svg.call(zoom)
        .call(zoom.transform, d3.zoomIdentity.scale(0.8).translate(100, 100));
  
      const simulation = d3.forceSimulation(nodes)
        .force("link", d3.forceLink(links).id(d => d.id).distance(50)) // Increase distance between nodes
        .force("charge", d3.forceManyBody().strength(-200)) // Adjust the strength to repel nodes from each other more strongly
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force("collision", d3.forceCollide().radius(15)); // Add collision force with a specified radius
  
      // Append graph elements to the container group `g` instead of directly to `svg`
      const link = g.append("g")
          .attr("stroke", "#999")
          .attr("stroke-opacity", 0.6)
          .selectAll("line")
          .data(links)
          .join("line")
          .attr("stroke-width", d => Math.sqrt(d.value));
  
      const node = g.append("g")
          .attr("stroke", "#9a7bc9")
          .attr("stroke-width", 1.5)
          .selectAll("circle")
          .data(nodes)
          .join("circle")
          .attr("r", 5)
          .attr("fill", color)
          .call(drag(simulation));
  
      node.append("title")
          .text(d => (d.type === "concept" ? d.content : d.content + "\n\nSource: " + d.title));

      node.style("fill", d => d.type === "concept" ? "#ce772f" : "#9a7bc9");
      // Highlight connected nodes
      node.on("mouseover", (event, clickedNode) => {
        const isConnected = (node) => links.some(link => (link.source.id === clickedNode.id && link.target.id === node.id) || (link.target.id === clickedNode.id && link.source.id === node.id));
          
        // Reset all nodes to default color first, if needed
        node.style("fill", color);

        node.style("fill", d => isConnected(d) ? d.type === "concept" ? "#d99965" : "#c3a0da" : color());

        d3.select(event.currentTarget).style("fill", "#44c14e");

        link.style("stroke", d => d.source.id === clickedNode.id || d.target.id === clickedNode.id ? "red" : "#999");
    
    });
  


    node.on("mouseleave", (event, clickedNode) => {
        
      node.style("fill", d => d.type === "concept" ? "#ce772f" : "#9a7bc9");
        // Reset all nodes to default color first, if needed
        link.style("stroke", "#999");
  });

      simulation.on("tick", () => {
        link
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);

        node
            .attr("cx", d => d.x)
            .attr("cy", d => d.y);
      });

      function color() {
        return "#666";
      }

      function drag(simulation) {
        function dragstarted(event) {
          if (!event.active) simulation.alphaTarget(0.3).restart();
          event.subject.fx = event.subject.x;
          event.subject.fy = event.subject.y;
        }

        function dragged(event) {
          event.subject.fx = event.x;
          event.subject.fy = event.y;
        }

        function dragended(event) {
          if (!event.active) simulation.alphaTarget(0);
          event.subject.fx = null;
          event.subject.fy = null;
        }

        return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
      }
    }
  }, [graphData, graphFilter, graphSourceFilter, singletonNotes, brain]); // Redraw graph when nodes or links change

  return (
    <div className="graph-container">
      <Legend items={legendItems} onToggle={toggleUseSingletonNotes} view = {singletonNotes}/>
    <svg
      key={filterText}
      className="d3-component"
      width={1010}
      height={900}
      ref={d3Container}
    />
    </div>
  );
};

export default D3Graph;