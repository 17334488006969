import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const TableWidget = ({ data }) => {
    if (!data || data.length === 0) {
        return <div>No data available</div>;
      }
    
      const headers = Object.keys(data[0]);
    
      return (
        <TableContainer component={Paper} className="table-widget-container">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header} className="table-header-cell">{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {headers.map((header) => (
                    <TableCell key={`${index}-${header}`} className="table-body-cell">{row[header]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

export default TableWidget;