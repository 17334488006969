import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './ResearchQuery.css';
import ShowComponent from './ShowComponent';
import Paladin from './Paladin';

const mockResults = {
  google_scholar: [
    {
      query: "soil degradation Ethiopia causes",
      results: [
        {
          title: "Drivers of Soil Degradation in Ethiopia: A Comprehensive Review",
          abstract: "This paper presents a comprehensive review of the primary drivers of soil degradation in Ethiopia. We analyze decades of research to identify key factors contributing to soil erosion, nutrient depletion, and loss of soil organic matter. Our findings highlight the complex interplay between natural factors such as topography and climate, and anthropogenic factors including deforestation, overgrazing, and unsustainable agricultural practices. We also examine the socio-economic and policy contexts that exacerbate these issues. The review concludes with recommendations for integrated soil management strategies and policy interventions to combat soil degradation in Ethiopia.",
          authors: ["Bekele, Abebe", "Johnson, Sarah", "Mekonnen, Kidist"],
          publishing_date: "2022-09-15",
          citation_count: 37,
          url: "https://example.com/soil_degradation_ethiopia_drivers"
        },
        {
          title: "Climate Change and Its Impact on Soil Degradation in the Ethiopian Highlands",
          abstract: "This study investigates the relationship between climate change and soil degradation in the Ethiopian Highlands. Using long-term climate data and soil quality assessments from multiple sites, we demonstrate significant correlations between changing precipitation patterns, increasing temperatures, and accelerated soil erosion rates. Our findings suggest that climate change is exacerbating existing soil degradation processes, particularly in areas with steep slopes and limited vegetation cover. We also explore the cascading effects on agricultural productivity and rural livelihoods. The paper concludes with a discussion on the need for climate-smart soil conservation strategies in Ethiopia's highland regions.",
          authors: ["Tadesse, Girmay", "Williams, Emily", "Ayana, Melaku"],
          publishing_date: "2023-02-20",
          citation_count: 22,
          url: "https://example.com/climate_change_soil_degradation_ethiopia"
        },
        {
          title: "The Role of Land Tenure Systems in Soil Conservation Practices in Ethiopia",
          abstract: "This research examines the influence of land tenure systems on soil conservation practices in Ethiopia. Through a mixed-methods approach combining household surveys and case studies from diverse regions, we analyze how different land ownership and use rights affect farmers' incentives and capacities to implement soil conservation measures. Our results indicate that secure land tenure is positively associated with long-term investments in soil conservation. However, we also find that customary land rights systems can sometimes promote sustainable practices through community-based natural resource management. The study provides insights for policymakers on how land tenure reforms could support efforts to combat soil degradation in Ethiopia.",
          authors: ["Gebre, Hanna", "Smith, Robert", "Osman, Fatima"],
          publishing_date: "2022-11-05",
          citation_count: 18,
          url: "https://example.com/land_tenure_soil_conservation_ethiopia"
        },
        {
          title: "Assessing the Economic Costs of Soil Degradation in the Ethiopian Agricultural Sector",
          abstract: "This paper presents a comprehensive assessment of the economic costs associated with soil degradation in Ethiopia's agricultural sector. Using a combination of remote sensing data, agricultural productivity statistics, and economic modeling, we estimate the direct and indirect costs of soil erosion, nutrient depletion, and soil organic matter loss. Our findings indicate that soil degradation costs the Ethiopian economy approximately 2-3% of its GDP annually, with significant variations across regions and farming systems. We also analyze the potential economic benefits of various soil conservation interventions. The study concludes with policy recommendations for integrating soil health considerations into national agricultural and economic development strategies.",
          authors: ["Alemu, Dawit", "Peterson, Lisa", "Tessema, Yohannes"],
          publishing_date: "2023-04-10",
          citation_count: 11,
          url: "https://example.com/economic_costs_soil_degradation_ethiopia"
        },
        {
          title: "Indigenous Knowledge and Soil Conservation Practices in Ethiopia: A Case Study of the Konso People",
          abstract: "This research explores the role of indigenous knowledge in soil conservation practices among the Konso people of southern Ethiopia. Through ethnographic fieldwork and participatory research methods, we document traditional soil and water conservation techniques that have been developed and refined over generations. Our findings highlight the effectiveness of indigenous terracing systems, intercropping practices, and local land use regulations in maintaining soil fertility and preventing erosion in a challenging environment. We also examine how these traditional practices are adapting to changing climatic and socio-economic conditions. The paper argues for greater integration of indigenous knowledge into modern soil conservation efforts in Ethiopia.",
          authors: ["Kassa, Hailu", "Brown, Jennifer", "Wolde, Tigist"],
          publishing_date: "2022-08-30",
          citation_count: 25,
          url: "https://example.com/indigenous_knowledge_soil_conservation_ethiopia"
        },
        {
          title: "The Impact of Population Pressure on Soil Degradation in the Ethiopian Highlands",
          abstract: "This study investigates the relationship between population pressure and soil degradation in the Ethiopian Highlands. Using a combination of demographic data, land use change analysis, and soil quality assessments, we examine how increasing population density affects land use intensification and soil degradation processes. Our results show a strong correlation between population growth and increased soil erosion rates, particularly in areas with limited access to alternative livelihoods. We also analyze the effectiveness of various interventions aimed at mitigating the impact of population pressure on soil resources. The paper concludes with recommendations for integrated population and natural resource management policies in Ethiopia's highland regions.",
          authors: ["Mengistu, Abeba", "Garcia, Carlos", "Desta, Lemma"],
          publishing_date: "2023-01-18",
          citation_count: 19,
          url: "https://example.com/population_pressure_soil_degradation_ethiopia"
        }
      ]
    },
    {
      query: "soil conservation techniques Ethiopia",
      results: [
        {
          title: "Effectiveness of Soil and Water Conservation Structures in the Ethiopian Highlands: A Meta-Analysis",
          abstract: "This meta-analysis evaluates the effectiveness of various soil and water conservation structures implemented in the Ethiopian Highlands. We synthesize data from 45 studies conducted over the past two decades, focusing on the impact of terraces, bunds, and check dams on soil erosion rates, water retention, and crop yields. Our results indicate that these structures can reduce soil loss by 60-80% on average, with stone bunds showing the highest effectiveness. We also find significant improvements in soil moisture content and crop productivity, particularly in semi-arid areas. The study discusses factors influencing the success of these interventions and provides recommendations for optimizing their design and implementation in different agro-ecological zones of Ethiopia.",
          authors: ["Debela, Seifu", "Anderson, Karen", "Gebremariam, Eyasu"],
          publishing_date: "2022-10-12",
          citation_count: 33,
          url: "https://example.com/soil_water_conservation_structures_ethiopia"
        },
        {
          title: "Adoption of Conservation Agriculture in Ethiopia: Barriers and Opportunities",
          abstract: "This paper examines the adoption of conservation agriculture (CA) practices in Ethiopia, focusing on minimum tillage, crop rotation, and permanent soil cover. Through a mixed-methods approach combining household surveys, focus group discussions, and key informant interviews across four regions, we identify key barriers and opportunities for CA adoption. Our findings reveal that while farmers recognize the benefits of CA for soil conservation, adoption rates remain low due to factors such as lack of appropriate machinery, competing uses for crop residues, and short-term yield concerns. We also highlight successful cases of CA adoption and analyze the role of agricultural extension services in promoting these practices. The study concludes with policy recommendations for scaling up CA in Ethiopia.",
          authors: ["Worku, Alemayehu", "Mitchell, Jane", "Abate, Tsedeke"],
          publishing_date: "2023-03-05",
          citation_count: 15,
          url: "https://example.com/conservation_agriculture_adoption_ethiopia"
        },
        {
          title: "Integrating Agroforestry Systems for Soil Conservation in Ethiopia: A Review",
          abstract: "This review paper explores the potential of agroforestry systems for soil conservation in Ethiopia. We analyze evidence from various agroforestry projects implemented across different agro-ecological zones of the country. Our findings demonstrate that well-designed agroforestry interventions can significantly reduce soil erosion, improve soil fertility, and enhance overall land productivity. We examine the effectiveness of different agroforestry practices, including alley cropping, parkland systems, and home gardens, in addressing soil degradation. The paper also discusses the additional benefits of agroforestry, such as increased biodiversity and climate change mitigation. We conclude with recommendations for scaling up agroforestry adoption as part of a comprehensive soil conservation strategy in Ethiopia.",
          authors: ["Assefa, Freweni", "Thompson, James", "Belay, Kebede"],
          publishing_date: "2022-12-20",
          citation_count: 27,
          url: "https://example.com/agroforestry_soil_conservation_ethiopia"
        },
        {
          title: "The Role of Crop Diversification in Soil Conservation: Evidence from Smallholder Farms in Ethiopia",
          abstract: "This study investigates the impact of crop diversification on soil conservation outcomes among smallholder farmers in Ethiopia. Using panel data from 1,200 households across three major agricultural regions, we analyze the relationship between crop diversity indices and indicators of soil quality and erosion. Our results show that farms with higher crop diversity experience lower rates of soil erosion and maintain higher levels of soil organic matter. We also find positive correlations between crop diversification and the adoption of other soil conservation practices. The paper discusses the mechanisms through which crop diversification contributes to soil health, including improved soil structure and reduced pest pressure. We conclude with implications for agricultural policies aimed at promoting sustainable intensification in Ethiopia.",
          authors: ["Tadesse, Getachew", "Wilson, Sarah", "Mulugeta, Eleni"],
          publishing_date: "2023-05-02",
          citation_count: 8,
          url: "https://example.com/crop_diversification_soil_conservation_ethiopia"
        },
        {
          title: "Community-Based Approaches to Soil Conservation in Ethiopia: Lessons from the MERET Project",
          abstract: "This paper presents a comprehensive analysis of community-based approaches to soil conservation in Ethiopia, focusing on lessons learned from the Managing Environmental Resources to Enable Transitions (MERET) project. Through a combination of long-term impact assessments, stakeholder interviews, and case studies, we evaluate the effectiveness of participatory watershed management approaches in addressing soil degradation. Our findings highlight the importance of community ownership, integration of livelihood improvements with conservation efforts, and strong local institutions in ensuring the success and sustainability of soil conservation interventions. We also examine the challenges faced in scaling up these approaches and their potential for replication in other parts of Ethiopia and beyond.",
          authors: ["Kebede, Yohannes", "Martinez, Elena", "Amare, Haimanot"],
          publishing_date: "2022-11-15",
          citation_count: 22,
          url: "https://example.com/community_based_soil_conservation_ethiopia"
        },
        {
          title: "The Potential of Conservation Tillage for Soil Erosion Control in the Ethiopian Highlands",
          abstract: "This research evaluates the potential of conservation tillage practices for controlling soil erosion in the Ethiopian Highlands. Through field experiments conducted at three sites over a four-year period, we compare the effects of no-tillage, reduced tillage, and conventional tillage on soil loss, runoff, and crop yields. Our results demonstrate that conservation tillage practices can reduce soil erosion by up to 70% compared to conventional tillage, while maintaining or even improving crop yields after an initial transition period. We also analyze the economic viability of these practices and farmers' perceptions of their benefits and challenges. The paper concludes with recommendations for promoting conservation tillage as part of a comprehensive soil conservation strategy in Ethiopia's highland areas.",
          authors: ["Gebreselassie, Yared", "Peterson, Anna", "Demeke, Mulat"],
          publishing_date: "2023-01-30",
          citation_count: 18,
          url: "https://example.com/conservation_tillage_ethiopia"
        }
      ]
    }
  ],
  semantic_scholar: [
    {
      query: "soil erosion assessment Ethiopia",
      results: [
        {
          title: "Remote Sensing and GIS-Based Soil Erosion Assessment in the Ethiopian Highlands",
          abstract: "This study presents a comprehensive soil erosion assessment in the Ethiopian Highlands using remote sensing and GIS techniques. We integrate high-resolution satellite imagery, digital elevation models, and land use/land cover data to model soil erosion rates across a large area of the highlands. The Revised Universal Soil Loss Equation (RUSLE) is adapted to local conditions and used to estimate annual soil loss. Our results reveal severe erosion hotspots and quantify the contribution of different factors to soil loss. We also validate the model using field measurements from selected sites. The paper discusses the implications of our findings for targeted soil conservation interventions and demonstrates the potential of geospatial technologies for large-scale soil erosion monitoring in Ethiopia.",
          authors: ["Alemayehu, Tewodros", "Singh, Amrita", "Zeleke, Gete"],
          publishing_date: "2022-09-25",
          citation_count: 31,
          url: "https://example.com/remote_sensing_soil_erosion_ethiopia"
        },
        {
          title: "Modeling the Impact of Climate Change on Soil Erosion in Ethiopia: A Scenario Analysis",
          abstract: "This research models the potential impact of climate change on soil erosion rates in Ethiopia under different future scenarios. We use an ensemble of regional climate models to project changes in rainfall patterns and intensity for the mid and late 21st century. These projections are then integrated into a spatially explicit soil erosion model to estimate future erosion rates across different agro-ecological zones of Ethiopia. Our results indicate that climate change could significantly exacerbate soil erosion in many parts of the country, with some areas potentially experiencing a doubling of current erosion rates by 2070. We also identify regions where erosion risk may decrease due to projected reductions in rainfall. The paper concludes with a discussion on the implications of these findings for long-term soil conservation planning and climate change adaptation strategies in Ethiopia.",
          authors: ["Mengistu, Dereje", "Williams, Jessica", "Abebe, Yonas"],
          publishing_date: "2023-03-18",
          citation_count: 14,
          url: "https://example.com/climate_change_soil_erosion_ethiopia"
        },
        {
          title: "Participatory Soil Erosion Mapping: Integrating Local Knowledge with Scientific Assessment in Ethiopia",
          abstract: "This study explores the integration of local knowledge with scientific methods for soil erosion assessment in Ethiopia. We develop and test a participatory mapping approach that combines farmers' perceptions of erosion severity with quantitative erosion modeling. The research is conducted in three watersheds representing different agro-ecological zones. Our findings show strong correlations between local assessments and model-based erosion estimates, highlighting the value of indigenous knowledge in understanding erosion processes. We also identify instances where local knowledge provides insights not captured by conventional models, particularly regarding the impacts of land management practices. The paper discusses the potential of this integrated approach for enhancing the accuracy and local relevance of soil erosion assessments in Ethiopia.",
          authors: ["Desta, Lemma", "O'Brien, Kelly", "Woldemariam, Teklu"],
          publishing_date: "2022-11-30",
          citation_count: 23,
          url: "https://example.com/participatory_soil_erosion_mapping_ethiopia"
        },
        {
          title: "Sediment Yield Modeling in Ethiopian River Basins: Implications for Soil Loss Estimation",
          abstract: "This research focuses on modeling sediment yield in major river basins of Ethiopia to improve soil loss estimations at a large scale. We apply the Soil and Water Assessment Tool (SWAT) to three river basins, calibrating and validating the model using long-term hydrological and sediment data. Our results provide new insights into the spatial and temporal patterns of sediment transport in Ethiopian rivers and their relationship to soil erosion processes in the watersheds. We find that current soil loss estimates may be underestimating the actual rates of erosion in certain regions. The study also examines the impact of land use changes and soil conservation interventions on sediment yields. We conclude with recommendations for improving watershed management strategies and soil conservation planning based on our sediment yield models.",
          authors: ["Gebremariam, Birhane", "Anderson, Paul", "Taddese, Girma"],
          publishing_date: "2023-01-22",
          citation_count: 19,
          url: "https://example.com/sediment_yield_modeling_ethiopia"
        },
        {
          title: "Soil Erosion Risk Assessment Using the Revised Universal Soil Loss Equation and GIS in Northern Ethiopia",
          abstract: "This study applies the Revised Universal Soil Loss Equation (RUSLE) in combination with GIS to assess soil erosion risk in a watershed of Northern Ethiopia. We use high-resolution spatial data to derive RUSLE factors and produce a detailed soil erosion risk map for the study area. Our results identify critical erosion hotspots and quantify the contribution of different factors to soil loss rates. We also validate the model predictions using field observations and sediment yield data from reservoir surveys. The paper discusses the strengths and limitations of the RUSLE-GIS approach in the Ethiopian context and suggests modifications to improve its accuracy. We conclude with recommendations for targeting soil conservation interventions based on our erosion risk assessment.",
          authors: ["Tesfaye, Abebe", "Kumar, Sandeep", "Mekonen, Kinfe"],
          publishing_date: "2022-10-05",
          citation_count: 27,
          url: "https://example.com/rusle_gis_soil_erosion_ethiopia"
        },
        {
          title: "Long-term Monitoring of Soil Erosion Rates in the Ethiopian Highlands: A Case Study of the Debre Mewi Watershed",
          abstract: "This paper presents results from a long-term monitoring study of soil erosion rates in the Debre Mewi watershed, located in the northwestern highlands of Ethiopia. We use a combination of erosion plots, sediment traps, and repeated topographic surveys to quantify soil loss rates over a 15-year period. Our findings reveal high spatial and temporal variability in erosion rates, with annual soil losses ranging from 5 to 120 t/ha/year depending on land use, slope, and rainfall patterns. We also document the effectiveness of various soil conservation measures implemented in the watershed over the study period. The research provides valuable insights into the long-term dynamics of soil erosion processes in the Ethiopian highlands and the factors influencing the success of conservation interventions.",
          authors: ["Belay, Kifle", "Thompson, Sarah", "Ayana, Mekuria"],
          publishing_date: "2023-04-12",
          citation_count: 9,
          url: "https://example.com/longterm_soil_erosion_monitoring_ethiopia"
        }
      ]
    },
    {
      query: "soil fertility management Ethiopia",
      results: [
        {
          title: "Integrated Soil Fertility Management Practices in Smallholder Farming Systems of Ethiopia",
          abstract: "This study examines the adoption and impact of integrated soil fertility management (ISFM) practices among smallholder farmers in Ethiopia. Through a combination of household surveys, field experiments, and participatory research in four regions, we assess the effectiveness of various ISFM practices in improving soil fertility and crop yields. Our findings show that farmers implementing a combination of organic and inorganic fertilizers, crop rotation, and improved crop varieties achieve significantly higher yields and better soil health indicators compared to those using conventional practices. We also analyze the socio-economic factors influencing ISFM adoption and the challenges faced by farmers in implementing these practices. The paper concludes with recommendations for scaling up ISFM in Ethiopia's diverse agro-ecological zones.",
          authors: ["Gebrehiwot, Kindu", "Martinez, Anna", "Desta, Lulseged"],
          publishing_date: "2022-11-20",
          citation_count: 35,
          url: "https://example.com/isfm_smallholder_farming_ethiopia"
        },
        {
          title: "The Role of Nitrogen Fixing Trees in Soil Fertility Improvement: Evidence from Ethiopian Agroforestry Systems",
          abstract: "This research investigates the contribution of nitrogen-fixing trees to soil fertility improvement in agroforestry systems across Ethiopia. We conduct a comparative study of farms with and without nitrogen-fixing tree species, analyzing soil chemical properties, nitrogen fixation rates, and crop yield data. Our results demonstrate significant improvements in soil organic matter content, nitrogen availability, and crop productivity in agroforestry systems incorporating species such as Faidherbia albida and Acacia senegal. We also examine the economic benefits of these systems and farmers' perceptions of their impact on soil fertility. The paper discusses the potential of scaling up the use of nitrogen-fixing trees as a sustainable approach to soil fertility management in Ethiopia.",
          authors: ["Assefa, Freweni", "Brown, David", "Tadesse, Getachew"],
          publishing_date: "2023-02-08",
          citation_count: 22,
          url: "https://example.com/nitrogen_fixing_trees_soil_fertility_ethiopia"
        },
        {
          title: "Biochar Application for Soil Fertility Enhancement in Ethiopia: Opportunities and Challenges",
          abstract: "This study explores the potential of biochar application for enhancing soil fertility in Ethiopian agricultural systems. We conduct field trials in three agro-ecological zones to assess the impact of biochar derived from different feedstocks on soil physical and chemical properties, nutrient retention, and crop yields. Our results show significant improvements in soil water holding capacity, cation exchange capacity, and nutrient availability in biochar-amended soils. We also observe yield increases of 15-30% in major crops across the study sites. The paper discusses the economic feasibility of biochar production and application in the Ethiopian context, as well as the challenges related to feedstock availability and farmer adoption. We conclude with recommendations for integrating biochar into existing soil fertility management strategies in Ethiopia.",
          authors: ["Mulugeta, Dawit", "Singh, Brajesh", "Alemu, Haimanot"],
          publishing_date: "2022-09-30",
          citation_count: 18,
          url: "https://example.com/biochar_soil_fertility_ethiopia"
        },
        {
          title: "Microbial Inoculants for Sustainable Soil Fertility Management in Ethiopian Agriculture",
          abstract: "This research investigates the use of microbial inoculants as a sustainable approach to soil fertility management in Ethiopian agriculture. We evaluate the effectiveness of various rhizobial and mycorrhizal inoculants on legume and cereal crops across different soil types and agro-ecological zones. Our findings demonstrate significant improvements in biological nitrogen fixation, phosphorus uptake, and overall plant growth in inoculated crops. We also assess the persistence of introduced microorganisms in the soil and their long-term effects on soil health. The paper discusses the potential of microbial inoculants to reduce dependence on chemical fertilizers and enhance soil biological activity. We conclude with recommendations for developing a national strategy for the production and distribution of quality microbial inoculants in Ethiopia.",
          authors: ["Woldemariam, Tsige", "Patel, Anita", "Bekele, Endalkachew"],
          publishing_date: "2023-03-25",
          citation_count: 11,
          url: "https://example.com/microbial_inoculants_soil_fertility_ethiopia"
        },
        {
          title: "Traditional Soil Fertility Management Practices in Ethiopian Indigenous Communities: Lessons for Sustainable Agriculture",
          abstract: "This study documents and analyzes traditional soil fertility management practices among indigenous communities in different regions of Ethiopia. Through ethnographic research and participatory field assessments, we examine the effectiveness of various local practices such as traditional composting methods, crop rotation systems, and the use of indigenous plant species for soil improvement. Our findings reveal a rich body of indigenous knowledge that has evolved to address soil fertility challenges in diverse agro-ecological contexts. We also analyze how these traditional practices are adapting to changing environmental and socio-economic conditions. The paper argues for greater recognition and integration of indigenous soil fertility management practices into modern agricultural extension programs in Ethiopia.",
          authors: ["Gebre, Solomon", "Martinez, Lucia", "Abebe, Yenenesh"],
          publishing_date: "2022-12-10",
          citation_count: 29,
          url: "https://example.com/traditional_soil_fertility_practices_ethiopia"
        },
        {
          title: "Optimizing Fertilizer Use Efficiency in Ethiopian Cereal Production Systems",
          abstract: "This research focuses on optimizing fertilizer use efficiency in major cereal production systems of Ethiopia. We conduct multi-location field trials to assess the response of teff, wheat, and maize to different rates and combinations of macro and micronutrients across various soil types. Our results show significant variations in fertilizer response across agro-ecological zones, highlighting the need for site-specific fertilizer recommendations. We also evaluate the effectiveness of slow-release fertilizers and nutrient use efficiency enhancers in improving nutrient uptake and reducing losses. The paper discusses strategies for improving fertilizer supply chains and promoting balanced fertilizer use among smallholder farmers. We conclude with policy recommendations for enhancing fertilizer use efficiency as a key component of sustainable soil fertility management in Ethiopia.",
          authors: ["Tekle, Yohannes", "Wilson, Jennifer", "Mengistu, Dereje"],
          publishing_date: "2023-01-15",
          citation_count: 24,
          url: "https://example.com/fertilizer_use_efficiency_ethiopia"
        }
      ]
    }
  ],
  openread: [
    {
      query: "soil conservation policies Ethiopia",
      results: [
        {
          title: "Evolution of Soil and Water Conservation Policies in Ethiopia: A Historical Analysis",
          abstract: "This paper traces the evolution of soil and water conservation policies in Ethiopia from the imperial era to the present day. Through extensive archival research and policy document analysis, we examine how national approaches to soil conservation have changed over time in response to environmental challenges, political shifts, and international influences. Our findings reveal a gradual transition from top-down, technical interventions to more participatory and integrated approaches. We also analyze the implementation challenges and outcomes of major soil conservation initiatives across different regimes. The study provides insights into the political economy of natural resource management in Ethiopia and offers lessons for future policy development in the face of ongoing soil degradation and climate change challenges.",
          authors: ["Dessalegn, Beyene", "Anderson, Karen", "Gebremariam, Eyasu"],
          publishing_date: "2022-10-05",
          citation_count: 38,
          url: "https://example.com/soil_conservation_policy_evolution_ethiopia"
        },
        {
          title: "Institutional Framework for Soil Conservation in Ethiopia: Challenges and Opportunities",
          abstract: "This study examines the institutional framework governing soil conservation efforts in Ethiopia. Through a comprehensive review of legal documents, institutional structures, and stakeholder interviews, we analyze the roles and responsibilities of various government agencies, NGOs, and community organizations involved in soil conservation. Our findings highlight issues of fragmentation, overlapping mandates, and limited coordination among institutions. We also identify successful cases of institutional collaboration and assess the impact of recent decentralization efforts on soil conservation governance. The paper concludes with recommendations for strengthening institutional capacities and improving cross-sectoral coordination to enhance the effectiveness of soil conservation policies in Ethiopia.",
          authors: ["Tadesse, Meron", "Johnson, Robert", "Alemu, Dawit"],
          publishing_date: "2023-02-18",
          citation_count: 21,
          url: "https://example.com/institutional_framework_soil_conservation_ethiopia"
        },
        {
          title: "The Impact of Land Certification on Soil Conservation Investments in Ethiopia",
          abstract: "This research investigates the relationship between land certification programs and farmers' investments in soil conservation in Ethiopia. Using panel data from 2,000 households across four regions, we employ econometric analyses to assess the impact of land certificates on the adoption of various soil conservation measures. Our results show that farmers with land certificates are significantly more likely to invest in long-term soil conservation structures such as terraces and bunds. We also find that the effect is stronger for female-headed households and in areas with higher land pressure. The paper discusses the mechanisms through which land tenure security influences soil conservation decisions and provides policy recommendations for leveraging land certification to promote sustainable land management in Ethiopia.",
          authors: ["Gebre, Hanna", "Williams, Michael", "Bekele, Abebe"],
          publishing_date: "2022-11-30",
          citation_count: 29,
          url: "https://example.com/land_certification_soil_conservation_ethiopia"
        },
        {
          title: "Evaluating the Implementation of Ethiopia's Climate-Resilient Green Economy Strategy: Implications for Soil Conservation",
          abstract: "This study evaluates the implementation of Ethiopia's Climate-Resilient Green Economy (CRGE) strategy, focusing on its implications for soil conservation. Through a mixed-methods approach combining policy analysis, stakeholder interviews, and case studies from selected woredas, we assess the progress made in integrating soil conservation into climate resilience and green growth initiatives. Our findings reveal both achievements and challenges in translating the CRGE strategy into effective soil conservation actions on the ground. We identify key barriers including limited local capacity, inadequate financing mechanisms, and insufficient coordination between climate and agricultural sectors. The paper concludes with recommendations for enhancing the soil conservation components of the CRGE strategy and improving its implementation at different administrative levels.",
          authors: ["Mulugeta, Gezahegn", "Peterson, Lisa", "Worku, Alemayehu"],
          publishing_date: "2023-04-10",
          citation_count: 13,
          url: "https://example.com/crge_soil_conservation_ethiopia"
        },
        {
          title: "The Role of Agricultural Extension Services in Promoting Soil Conservation Policies in Ethiopia",
          abstract: "This research examines the role of agricultural extension services in promoting and implementing soil conservation policies in Ethiopia. Through surveys of extension agents and farmers across three regions, we analyze the effectiveness of various extension approaches in disseminating information about soil conservation policies and encouraging the adoption of recommended practices. Our findings highlight the importance of participatory extension methods and demonstration plots in increasing farmers' awareness and uptake of soil conservation measures. We also identify challenges faced by extension agents in promoting soil conservation, including limited resources and the need for more specialized training. The paper concludes with recommendations for strengthening the capacity of agricultural extension services to support soil conservation policy implementation in Ethiopia.",
          authors: ["Assefa, Freweni", "O'Brien, Patrick", "Demeke, Mulat"],
          publishing_date: "2022-09-22",
          citation_count: 25,
          url: "https://example.com/extension_services_soil_conservation_ethiopia"
        },
        {
          title: "Integrating Indigenous Knowledge into Soil Conservation Policies: Lessons from Ethiopia's Productive Safety Net Program",
          abstract: "This study investigates the integration of indigenous knowledge into soil conservation policies in Ethiopia, focusing on the experience of the Productive Safety Net Program (PSNP). Through case studies in three PSNP watersheds, we examine how local knowledge and practices are incorporated into soil and water conservation activities. Our findings reveal both successes and challenges in bridging scientific and indigenous approaches to soil conservation. We analyze the factors that facilitate or hinder the integration of local knowledge and assess the impacts on the effectiveness and sustainability of conservation efforts. The paper argues for a more systematic approach to incorporating indigenous knowledge into national soil conservation policies and provides recommendations for enhancing local participation in policy formulation and implementation.",
          authors: ["Kebede, Yohannes", "Thompson, Sarah", "Ayana, Melaku"],
          publishing_date: "2023-01-05",
          citation_count: 19,
          url: "https://example.com/indigenous_knowledge_soil_conservation_policies_ethiopia"
        }
      ]
    },
    {
      query: "soil restoration techniques Ethiopia",
      results: [
        {
          title: "Bioengineering Approaches for Gully Rehabilitation in the Ethiopian Highlands",
          abstract: "This research evaluates the effectiveness of bioengineering techniques for gully rehabilitation in the Ethiopian Highlands. We conduct field experiments in three severely degraded watersheds, testing various combinations of physical structures and vegetative measures. Our results demonstrate that integrated approaches combining check dams, fascines, and native grass species are most effective in stabilizing gully systems and promoting soil accumulation. We also assess the cost-effectiveness of these techniques and their potential for scaling up. The paper discusses the ecological and socio-economic benefits of gully rehabilitation and provides guidelines for selecting appropriate bioengineering interventions based on local conditions.",
          authors: ["Desta, Lemma", "Garcia, Carlos", "Wolde, Tigist"],
          publishing_date: "2022-11-15",
          citation_count: 32,
          url: "https://example.com/bioengineering_gully_rehabilitation_ethiopia"
        },
        {
          title: "Restoring Soil Organic Matter in Degraded Agricultural Lands of Ethiopia: A Comparative Study of Organic Amendments",
          abstract: "This study compares the effectiveness of different organic amendments in restoring soil organic matter in degraded agricultural lands of Ethiopia. We conduct a three-year field experiment in two agro-ecological zones, testing the impacts of compost, biochar, and green manure on soil organic carbon content, nutrient availability, and crop yields. Our results show that while all treatments improve soil quality, biochar application leads to the most significant and long-lasting increases in soil organic matter. We also analyze the economic feasibility of these restoration techniques and their adoption potential among smallholder farmers. The paper concludes with recommendations for integrating organic soil amendments into national soil restoration strategies in Ethiopia.",
          authors: ["Alemu, Haile", "Patel, Anita", "Mengistu, Dereje"],
          publishing_date: "2023-03-20",
          citation_count: 18,
          url: "https://example.com/organic_amendments_soil_restoration_ethiopia"
        },
        {
          title: "Microbial Inoculation for Restoring Soil Functionality in Degraded Ethiopian Drylands",
          abstract: "This research investigates the potential of microbial inoculation techniques for restoring soil functionality in degraded dryland ecosystems of Ethiopia. We conduct field trials in three sites, testing the effects of various bacterial and fungal inoculants on soil biological activity, nutrient cycling, and vegetation establishment. Our findings show that tailored microbial consortia can significantly enhance soil organic matter decomposition, nitrogen fixation, and plant growth in these harsh environments. We also examine the persistence of introduced microorganisms and their interactions with native soil biota. The paper discusses the challenges and opportunities of scaling up microbial restoration techniques in Ethiopia's drylands and their potential contribution to combating desertification.",
          authors: ["Gebremariam, Eyasu", "Singh, Brajesh", "Tadesse, Girmay"],
          publishing_date: "2022-10-08",
          citation_count: 27,
          url: "https://example.com/microbial_inoculation_dryland_restoration_ethiopia"
        },
        {
          title: "Integrating Physical and Biological Soil Conservation Measures for Landscape Restoration in Ethiopia",
          abstract: "This study examines the effectiveness of integrated physical and biological soil conservation measures for landscape restoration in Ethiopia. We implement a watershed-scale experiment combining terracing, check dams, and vegetative barriers with the reintroduction of native plant species. Over a five-year period, we monitor changes in soil erosion rates, vegetation cover, and biodiversity. Our results demonstrate that this integrated approach leads to significant improvements in soil stability, water retention, and ecosystem recovery. We also assess the socio-economic impacts on local communities and analyze the factors influencing the long-term sustainability of these interventions. The paper concludes with recommendations for scaling up integrated landscape restoration approaches in Ethiopia's diverse agro-ecological zones.",
          authors: ["Bekele, Abebe", "Martinez, Elena", "Wolde, Solomon"],
          publishing_date: "2023-02-12",
          citation_count: 23,
          url: "https://example.com/integrated_landscape_restoration_ethiopia"
        },
        {
          title: "Phytoremediation Techniques for Heavy Metal Contaminated Soils in Ethiopian Urban and Peri-urban Areas",
          abstract: "This research explores the potential of phytoremediation techniques for restoring heavy metal contaminated soils in urban and peri-urban areas of Ethiopia. We conduct greenhouse and field experiments to evaluate the metal accumulation capacity of various native and introduced plant species. Our findings identify several hyperaccumulator plants that effectively remove lead, cadmium, and zinc from contaminated soils. We also assess the feasibility of using these plants for economic purposes, such as bioenergy production or phytomining. The paper discusses the challenges of implementing phytoremediation at scale in the Ethiopian context and provides guidelines for selecting appropriate plant species based on site-specific contamination profiles.",
          authors: ["Tessema, Yohannes", "Kumar, Rajesh", "Ayana, Melaku"],
          publishing_date: "2022-12-05",
          citation_count: 15,
          url: "https://example.com/phytoremediation_urban_soils_ethiopia"
        },
        {
          title: "Restoring Soil Fertility in Smallholder Farming Systems of Ethiopia: A Participatory Technology Development Approach",
          abstract: "This study employs a participatory technology development (PTD) approach to identify and evaluate soil fertility restoration techniques suitable for smallholder farming systems in Ethiopia. Working with farmer groups in four regions, we co-design and test a range of low-cost, locally adapted interventions including improved composting methods, green manuring, and micro-dosing of inorganic fertilizers. Our results show that farmer-led experimentation leads to higher adoption rates and more sustained use of soil restoration practices. We also analyze the social and institutional factors that influence the success of PTD processes in the Ethiopian context. The paper concludes with recommendations for integrating participatory approaches into national agricultural extension and soil management programs.",
          authors: ["Gebrehiwot, Kinfe", "Anderson, Sarah", "Demeke, Mulat"],
          publishing_date: "2023-04-30",
          citation_count: 9,
          url: "https://example.com/participatory_soil_restoration_ethiopia"
        }
      ]
    }
  ],
  core: [
    {
      query: "soil erosion modeling Ethiopia",
      results: [
        {
          title: "Comparative Analysis of Soil Erosion Models for Ethiopian Highlands: RUSLE vs. PESERA",
          abstract: "This study compares the performance of two widely used soil erosion models, the Revised Universal Soil Loss Equation (RUSLE) and the Pan-European Soil Erosion Risk Assessment (PESERA), in predicting soil loss rates in the Ethiopian Highlands. We apply both models to three watersheds representing different agro-ecological zones, using high-resolution spatial data and field measurements for parameterization. Our results show that while both models capture the general spatial patterns of erosion, PESERA tends to provide more realistic estimates in areas with complex topography and land use patterns. We discuss the strengths and limitations of each model in the Ethiopian context and provide recommendations for their application in soil conservation planning and policy development.",
          authors: ["Alemayehu, Tewodros", "Williams, Jessica", "Zeleke, Gete"],
          publishing_date: "2022-09-10",
          citation_count: 36,
          url: "https://example.com/rusle_pesera_comparison_ethiopia"
        },
        {
          title: "Integration of Remote Sensing and Machine Learning for High-Resolution Soil Erosion Modeling in Ethiopia",
          abstract: "This research presents a novel approach to high-resolution soil erosion modeling in Ethiopia by integrating remote sensing data with machine learning techniques. We develop a random forest model that combines multispectral satellite imagery, digital elevation models, and climate data to predict soil loss rates at a 10m resolution. The model is trained and validated using field measurements from erosion plots across different agro-ecological zones. Our results demonstrate significant improvements in prediction accuracy compared to traditional empirical models, particularly in capturing the spatial variability of erosion processes. We also explore the potential of this approach for near-real-time erosion monitoring and early warning systems. The paper discusses the implications of high-resolution erosion modeling for targeted soil conservation interventions and precision agriculture in Ethiopia.",
          authors: ["Mengistu, Dereje", "Patel, Anita", "Abebe, Yonas"],
          publishing_date: "2023-03-25",
          citation_count: 19,
          url: "https://example.com/machine_learning_erosion_modeling_ethiopia"
        },
        {
          title: "Modeling the Impact of Climate Change on Soil Erosion in the Ethiopian Highlands: A Scenario-Based Approach",
          abstract: "This study employs a scenario-based modeling approach to assess the potential impacts of climate change on soil erosion in the Ethiopian Highlands. We use an ensemble of regional climate models to generate future climate scenarios for the mid and late 21st century. These projections are then integrated into a spatially distributed soil erosion model to estimate changes in erosion rates under different climate change pathways. Our results indicate significant spatial and temporal variations in future erosion risk, with some areas experiencing up to a 50% increase in soil loss rates by 2070. We also analyze the relative contributions of changes in rainfall patterns and land use to future erosion risk. The paper concludes with a discussion on the implications of these findings for long-term soil conservation planning and climate change adaptation strategies in Ethiopia.",
          authors: ["Gebre, Solomon", "Thompson, James", "Desta, Lemma"],
          publishing_date: "2022-11-30",
          citation_count: 28,
          url: "https://example.com/climate_change_erosion_modeling_ethiopia"
        },
        {
          title: "Sediment Yield Modeling in Ethiopian River Basins: Implications for Reservoir Management and Soil Loss Estimation",
          abstract: "This research focuses on modeling sediment yield in major river basins of Ethiopia to improve understanding of large-scale soil erosion processes and inform reservoir management strategies. We apply the Soil and Water Assessment Tool (SWAT) to three river basins, calibrating and validating the model using long-term hydrological and sediment data. Our results provide new insights into the spatial and temporal patterns of sediment transport in Ethiopian rivers and their relationship to watershed characteristics and land use changes. We also assess the impact of existing and planned soil conservation interventions on basin-scale sediment yields. The paper discusses the implications of our findings for reservoir sedimentation management and the design of future hydropower projects in Ethiopia.",
          authors: ["Tadesse, Getachew", "Martinez, Anna", "Alemu, Haile"],
          publishing_date: "2023-01-15",
          citation_count: 24,
          url: "https://example.com/sediment_yield_modeling_ethiopia"
        },
        {
          title: "Coupling Hydrological and Soil Erosion Models for Integrated Watershed Management in Ethiopia",
          abstract: "This study presents a coupled modeling approach that integrates hydrological and soil erosion processes for improved watershed management in Ethiopia. We combine a distributed hydrological model with a process-based soil erosion model to simulate water and sediment fluxes at the watershed scale. The coupled model is applied to a case study watershed in the Blue Nile basin, using a combination of field data and remote sensing inputs. Our results demonstrate the importance of considering the interactions between hydrological and erosion processes in accurately predicting sediment yields and identifying critical source areas. We also evaluate the effectiveness of various soil and water conservation scenarios using the coupled model. The paper concludes with recommendations for using integrated modeling approaches to support evidence-based watershed management decisions in Ethiopia.",
          authors: ["Bekele, Abebe", "Singh, Vijay", "Wolde, Tigist"],
          publishing_date: "2022-10-20",
          citation_count: 31,
          url: "https://example.com/coupled_hydrology_erosion_modeling_ethiopia"
        },
        {
          title: "Participatory Erosion Mapping and Modeling: Integrating Local Knowledge with Scientific Approaches in Ethiopia",
          abstract: "This research explores the integration of local knowledge with scientific modeling approaches for soil erosion assessment in Ethiopia. We develop a participatory framework that combines farmers' perceptions of erosion severity with GIS-based erosion modeling. The study is conducted in three watersheds representing different agro-ecological zones. Our findings reveal strong correlations between local assessments and model-based erosion estimates, highlighting the value of indigenous knowledge in understanding erosion processes. We also identify instances where local knowledge provides insights not captured by conventional models, particularly regarding the impacts of land management practices and short-term erosion events. The paper discusses the potential of this integrated approach for enhancing the accuracy and local relevance of erosion models and improving the design of soil conservation interventions in Ethiopia.",
          authors: ["Mulugeta, Dawit", "O'Brien, Kelly", "Gebremariam, Eyasu"],
          publishing_date: "2023-05-05",
          citation_count: 11,
          url: "https://example.com/participatory_erosion_modeling_ethiopia"
        }
      ]
    },
    {
      query: "soil carbon sequestration Ethiopia",
      results: [
        {
          title: "Potential of Conservation Agriculture for Soil Carbon Sequestration in Ethiopian Smallholder Farming Systems",
          abstract: "This study assesses the potential of conservation agriculture (CA) practices for enhancing soil carbon sequestration in smallholder farming systems of Ethiopia. We conduct a meta-analysis of long-term field experiments across different agro-ecological zones, comparing soil organic carbon (SOC) stocks under CA and conventional practices. Our results show that CA practices, particularly minimum tillage combined with residue retention, can increase SOC stocks by 20-35% over a 10-year period. We also analyze the factors influencing the variability in carbon sequestration rates, including soil type, climate, and management intensity. The paper discusses the implications of widespread CA adoption for climate change mitigation and soil health improvement in Ethiopia, as well as the barriers to scaling up these practices among smallholder farmers.",
          authors: ["Tadesse, Meron", "Johnson, Robert", "Bekele, Abebe"],
          publishing_date: "2022-11-10",
          citation_count: 39,
          url: "https://example.com/conservation_agriculture_carbon_sequestration_ethiopia"
        },
        {
          title: "Biochar Application for Soil Carbon Sequestration in Ethiopian Agricultural Systems: A Life Cycle Assessment",
          abstract: "This research evaluates the potential of biochar application for soil carbon sequestration in Ethiopian agricultural systems using a life cycle assessment (LCA) approach. We analyze the carbon balance and greenhouse gas emissions associated with biochar production from different feedstocks and its application to soils across three agro-ecological zones. Our results indicate that biochar can lead to net carbon sequestration of 0.5-2.5 tons CO2-eq per hectare per year, depending on feedstock and application rate. We also assess the co-benefits of biochar application on soil fertility and crop yields. The paper discusses the economic feasibility and environmental sustainability of scaling up biochar use in Ethiopia, considering factors such as feedstock availability, production technology, and policy incentives.",
          authors: ["Alemu, Dawit", "Patel, Anita", "Gebre, Hanna"],
          publishing_date: "2023-02-28",
          citation_count: 22,
          url: "https://example.com/biochar_carbon_sequestration_lca_ethiopia"
        },
        {
          title: "Modeling Soil Organic Carbon Dynamics under Climate Change Scenarios in Ethiopian Agroecosystems",
          abstract: "This study models soil organic carbon (SOC) dynamics in Ethiopian agroecosystems under different climate change scenarios. We use the RothC model, parameterized with local data, to simulate SOC changes in major agricultural regions of Ethiopia over the next 50 years. Our projections incorporate climate data from an ensemble of regional climate models and consider various land management scenarios. Results indicate that climate change could lead to significant SOC losses in some regions, particularly in the highlands, while other areas may see increased carbon sequestration potential due to changes in productivity and decomposition rates. We also evaluate the effectiveness of different management strategies in mitigating SOC losses under climate change. The paper concludes with recommendations for climate-smart soil management practices to enhance carbon sequestration in Ethiopian agriculture.",
          authors: ["Mengistu, Dereje", "Williams, Sarah", "Zeleke, Gete"],
          publishing_date: "2022-09-20",
          citation_count: 31,
          url: "https://example.com/soil_carbon_dynamics_climate_change_ethiopia"
        },
        {
          title: "Agroforestry Systems for Soil Carbon Sequestration in the Ethiopian Highlands: A Comparative Analysis",
          abstract: "This research compares the soil carbon sequestration potential of different agroforestry systems in the Ethiopian Highlands. We conduct a chronosequence study of five agroforestry practices, including alley cropping, parkland systems, and home gardens, measuring SOC stocks and other soil properties across a 20-year age gradient. Our findings show that all agroforestry systems increase SOC stocks compared to conventional croplands, with parkland systems demonstrating the highest sequestration rates of up to 2.5 Mg C ha⁻¹ yr⁻¹. We also analyze the factors influencing carbon sequestration efficiency, such as tree species composition, management intensity, and initial soil conditions. The paper discusses the implications of these results for designing optimal agroforestry interventions to maximize carbon sequestration and other ecosystem services in the Ethiopian Highlands.",
          authors: ["Gebrehiwot, Kindu", "Kumar, Sandeep", "Ayana, Melaku"],
          publishing_date: "2023-04-05",
          citation_count: 15,
          url: "https://example.com/agroforestry_carbon_sequestration_ethiopia"
        },
        {
          title: "Soil Carbon Sequestration Potential of Exclosures in Degraded Lands of Ethiopia",
          abstract: "This study assesses the soil carbon sequestration potential of exclosures established on degraded lands in Ethiopia. We compare SOC stocks and sequestration rates between exclosures of different ages (5-30 years) and adjacent open grazing lands across three ecological zones. Our results demonstrate that exclosures can significantly increase SOC stocks, with average sequestration rates of 0.8-1.2 Mg C ha⁻¹ yr⁻¹. We also analyze the vegetation dynamics and soil property changes associated with carbon accumulation in exclosures. The paper discusses the implications of these findings for large-scale land restoration programs in Ethiopia and their potential contribution to national climate change mitigation goals. We conclude with recommendations for optimizing the design and management of exclosures to maximize their carbon sequestration potential while providing other ecological and socio-economic benefits.",
          authors: ["Desta, Lemma", "O'Brien, Patrick", "Wolde, Solomon"],
          publishing_date: "2022-12-15",
          citation_count: 27,
          url: "https://example.com/exclosures_carbon_sequestration_ethiopia"
        },
        {
          title: "Integrating Soil Carbon Sequestration into Ethiopia's Climate-Resilient Green Economy Strategy: Opportunities and Challenges",
          abstract: "This research examines the potential for integrating soil carbon sequestration initiatives into Ethiopia's Climate-Resilient Green Economy (CRGE) strategy. We conduct a policy analysis and stakeholder consultation to identify opportunities and challenges for scaling up soil carbon sequestration projects within the CRGE framework. Our findings highlight the significant mitigation potential of soil carbon sequestration in Ethiopia's agricultural and forestry sectors, but also reveal barriers related to measurement, reporting, and verification (MRV) systems, as well as institutional capacities. We propose a roadmap for developing a national soil carbon sequestration program that aligns with the CRGE strategy and international climate finance mechanisms. The paper concludes with recommendations for policy reforms and capacity building to enable Ethiopia to fully leverage soil carbon sequestration as a nature-based solution for climate change mitigation and adaptation.",
          authors: ["Gebremariam, Eyasu", "Martinez, Elena", "Tessema, Yohannes"],
          publishing_date: "2023-03-10",
          citation_count: 19,
          url: "https://example.com/soil_carbon_crge_strategy_ethiopia"
        }
      ]
    }
  ]
};

function ResearchQuery() {
  const location = useLocation();

  const getBrainFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('brain') || 'New Project';
  };
  const [formData, setFormData] = useState({
    projectDefinition: '',
    goals: '',
    methodology: '',
    topics: ''
  });
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('project');
  const [activeSource, setActiveSource] = useState('news');
  const [projectName, setProjectName] = useState('New Project');
  const [isEditingName, setIsEditingName] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [privateDataSource, setPrivateDataSource] = useState('');
  const [mountedSources, setMountedSources] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileInputRef = useRef(null);




  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  const handleFiles = (files) => {
    const newFiles = Array.from(files).map(file => ({
      name: file.name,
      size: file.size,
      type: file.type
    }));
    setUploadedFiles(prevFiles => [...prevFiles, ...newFiles]);
    // Here you would typically also send these files to your backend to actually mount them
  };

  const removeFile = (index) => {
    setUploadedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    // Here you would typically also send this to your backend to unmount the file
  };

  const handlePrivateDataSourceChange = (e) => {
    setPrivateDataSource(e.target.value);
  };

  const handleMountDataSource = () => {
    if (privateDataSource.trim()) {
      setMountedSources([...mountedSources, privateDataSource.trim()]);
      setPrivateDataSource('');
      // Here you would typically also send this to your backend to actually mount the data source
    }
  };

  const handleRemoveDataSource = (index) => {
    const newMountedSources = mountedSources.filter((_, i) => i !== index);
    setMountedSources(newMountedSources);
    // Here you would typically also send this to your backend to unmount the data source
  };


  useEffect(() => {
    const savedName = localStorage.getItem('projectName');
    if (savedName) {
      setProjectName(savedName);
    }
  }, []);

  useEffect(() => {
    // Update projectName when URL changes
    setProjectName(getBrainFromUrl());
  }, [location]);

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleProjectNameBlur = () => {
    setIsEditingName(false);
    localStorage.setItem('projectName', projectName);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const projectString = `
      Project Definition: ${formData.projectDefinition}
      Goals: ${formData.goals}
      Methodology: ${formData.methodology}
      Topics and Fields: ${formData.topics}
    `;
  
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('project', projectString);
      setActiveTab('content');
      const response = await axios.post('https://shadowresearch--api-app-fastapi-app-dev.modal.run/exa', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setResults(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      }
    } finally {
      setLoading(false);
    }
  };

  const renderResults = () => {
    if (!results && !mockResults) {
      return <p>No results available.</p>;
    }
  
    const resultTypes = ['companies', 'git', 'tweet', 'papers', 'news', 'pdf', 'Google Scholar', 'Semantic Scholar'];
  
    return (
      <div>
        <div className="source-tabs">
          {resultTypes.map(type => (
            <button
              key={type}
              className={`source-tab ${activeSource === type ? 'active' : ''}`}
              onClick={() => setActiveSource(type)}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </button>
          ))}
        </div>
        <div className="result-type-section">
          {(activeSource === 'Google Scholar' || activeSource === 'Semantic Scholar') ? (
            <ul>
              {mockResults[activeSource.toLowerCase().replace(' ', '_')][0].results.map((result, idx) => (
                <li key={idx} className="result-card">
                  <h4>{result.title}</h4>
                  <p>Authors: {result.authors.join(', ')}</p>
                  <p>{result.abstract}</p>
                  <p>Published: {result.publishing_date}</p>
                  <p>Citations: {result.citation_count}</p>
                  <a href={result.url} target="_blank" rel="noopener noreferrer">Read more</a>
                  <div className="action-buttons">
                    <button className="approve-button">Approve</button>
                    <button className="decline-button">Decline</button>
                  </div>
                </li>
              ))}
            </ul>
          ) : results && results[activeSource] && results[activeSource].length > 0 ? (
            <ul>
              {results[activeSource].map((result, idx) => (
                <li key={idx} className="result-card">
                  <h4>{result.title || ''}</h4>
                  {(activeSource === 'tweet' || activeSource === 'papers' || activeSource === 'news' || activeSource === 'pdf') && (
                    <p>Author: {result.author || 'Unknown'}</p>
                  )}
                  <p>{result.text ? result.text.substring(0, 600) + '...' : 'No text available'}</p>
                  {result.url && (
                    <a href={result.url} target="_blank" rel="noopener noreferrer">
                      {activeSource === 'companies' && 'Visit website'}
                      {activeSource === 'git' && 'View repository'}
                      {activeSource === 'tweet' && 'View tweet'}
                      {(activeSource === 'papers' || activeSource === 'news' || activeSource === 'pdf') && 'Read more'}
                    </a>
                  )}
                  <p>Score: {result.score ? result.score.toFixed(2) : 'N/A'}</p>
                  <div className="action-buttons">
                    <button className="approve-button">Approve</button>
                    <button className="decline-button">Decline</button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No results for {activeSource}.</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="research-query-container">
      <div className="research-query-header">
      {isEditingName ? (
          <input
            type="text"
            value={projectName}
            onChange={handleProjectNameChange}
            onBlur={handleProjectNameBlur}
            autoFocus
            className="project-name-input"
          />
        ) : (
          <h2 onClick={() => setIsEditingName(true)}>{projectName}</h2>
        )}
      </div>
      <div className="tab-container">
        <button 
          className={`tab ${activeTab === 'project' ? 'active' : ''}`}
          onClick={() => setActiveTab('project')}
        >
          Wizard
        </button>
        <button 
          className={`tab ${activeTab === 'content' ? 'active' : ''}`}
          onClick={() => setActiveTab('content')}
        >
          Scout
        </button>
        <button 
          className={`tab ${activeTab === 'alchemist' ? 'active' : ''}`}
          onClick={() => setActiveTab('alchemist')}
        >
          Alchemist
        </button>
        <button 
          className={`tab ${activeTab === 'paladin' ? 'active' : ''}`}
          onClick={() => setActiveTab('paladin')}
        >
          Paladin
        </button>
      </div>
      {activeTab === 'project' && (
        <form onSubmit={handleSubmit} className="research-query-form">
          <div className="input-group-wizard">
            <label htmlFor="projectDefinition">Project Definition:</label>
            <textarea
              id="projectDefinition"
              name="projectDefinition"
              value={formData.projectDefinition}
              onChange={handleInputChange}
              placeholder="Describe your project"
              className="research-query-input"
            />
          </div>
          <div className="input-group-wizard">
            <label htmlFor="goals">Goals:</label>
            <textarea
              id="goals"
              name="goals"
              value={formData.goals}
              onChange={handleInputChange}
              placeholder="List your specific goals"
              className="research-query-input"
            />
          </div>
          <div className="input-group-wizard">
            <label htmlFor="methodology">Methodology:</label>
            <textarea
              id="methodology"
              name="methodology"
              value={formData.methodology}
              onChange={handleInputChange}
              placeholder="How do you want to conduct the research?"
              className="research-query-input"
            />
          </div>
          <div className="input-group-wizard">
            <label htmlFor="topics">Topics and Fields:</label>
            <textarea
              id="topics"
              name="topics"
              value={formData.topics}
              onChange={handleInputChange}
              placeholder="Enter relevant topics and fields"
              className="research-query-input"
            />
          </div>
          <div className="advanced-section" style={{border: showAdvanced ? '0.5px solid #469037' : 'none'}}>
            <button 
              type="button" 
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="advanced-toggle"
            >
              {showAdvanced ? 'Done Mounting' : 'Mount Private Data'}
            </button>
            
            {showAdvanced && (
              <div className="advanced-content">
                <h3>Mount Private Cloud Data</h3>
                <div className="input-group-mount">
                  <input
                    type="text"
                    value={privateDataSource}
                    onChange={handlePrivateDataSourceChange}
                    placeholder="Enter private data source URL"
                    className="mount-input"
                  />
                  <button 
                    type="button" 
                    onClick={handleMountDataSource}
                    className="mount-button"
                  >
                    Mount
                  </button>
                </div>
                {mountedSources.length > 0 && (
                  <div className="mounted-sources">
                    <h4>Mounted Sources:</h4>
                    <ul>
                      {mountedSources.map((source, index) => (
                        <li key={index}>
                          {source}
                          <button 
                            type="button" 
                            onClick={() => handleRemoveDataSource(index)}
                            className="remove-button"
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <h3>Mount Local Files</h3>
                <div 
                  className={`drag-drop-area ${dragActive ? "drag-active" : ""}`}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleChange}
                    multiple
                    style={{display: 'none'}}
                  />
                  <p>Drag and drop files here or</p>
                  <button type="button" onClick={() => fileInputRef.current.click()}>
                    Select Files
                  </button>
                </div>
                
                {uploadedFiles.length > 0 && (
                  <div className="uploaded-files">
                    <h4>Uploaded Files:</h4>
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={index}>
                          {file.name} ({(file.size / 1024).toFixed(2)} KB)
                          <button 
                            type="button" 
                            onClick={() => removeFile(index)}
                            className="remove-button"
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
          <button type="submit" className="research-query-button">Create</button>ß
        </form>
      )}
      {activeTab === 'content' && (
        <div className="content-container">
          {loading && <div className="research-query-loader">Scout is retrieving the best content for your project...</div>}
          {results && (
            <div className="research-query-results">
              {renderResults()}
            </div>
          )}
        </div>
      )}
      {activeTab === 'alchemist' && (
        <ShowComponent/>
      )}
      {activeTab === 'paladin' && (
        <Paladin/>
      )}
    </div>
  );
}

export default ResearchQuery;