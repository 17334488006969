import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2023 Shadow Research Inc. All rights reserved.</p>
        <p>
            <Link to="/about">About</Link>
            <Link to="https://www.linkedin.com/company/shadow-research" target="_blank" rel="noopener noreferrer">LinkedIn</Link>
        </p>
      </div>
    </footer>
  );
}

export default Footer;