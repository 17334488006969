import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';

const TempComponent = () => {
  const treeStyles = {
    lineColor: 'white',
    lineHeight: '30px',
    lineWidth: '1px',
    nodePadding: '10px',
    nodeTextColor: '#B19CD9', // Soft purple
    nodeBorder: '1px solid #B19CD9',
    nodeBackgroundColor: 'transparent',
  };

  return (
    <div style={{ backgroundColor: '#000000', padding: '20px', color: "purple" }}>
      <Tree lineWidth={'2px'} lineColor={'white'} lineBorderRadius={'10px'}>
        <TreeNode label="Main Results" {...treeStyles}>
          <TreeNode label="Heavy Metals in Fish" {...treeStyles}>
            <TreeNode label="Site A: Cu>Pb>As>Co>Ni>Zn>Cr>Cd" {...treeStyles} />
            <TreeNode label="Site B: Zn>Cu>As>Ni>Cr>Co>Cd>Pb" {...treeStyles} />
            <TreeNode label="Above WHO permissible limits" {...treeStyles} />
          </TreeNode>
          <TreeNode label="BTEX in Fish" {...treeStyles}>
            <TreeNode label="Benzene present in Site A" {...treeStyles} />
            <TreeNode label="O-Xylene present in Site B" {...treeStyles} />
            <TreeNode label="M-Xylene and P-Xylene in Site A" {...treeStyles} />
          </TreeNode>
          <TreeNode label="PAHs in Fish" {...treeStyles}>
            <TreeNode label="Total PAH: Site A (27.42), Site B (12.28)" {...treeStyles} />
            <TreeNode label="Carcinogenic PAHs present" {...treeStyles} />
            <TreeNode label="Above WHO permissible limits" {...treeStyles} />
          </TreeNode>
          <TreeNode label="Total Hydrocarbon Content" {...treeStyles}>
            <TreeNode label="Site A: 91.19 mg/l" {...treeStyles} />
            <TreeNode label="Site B: 17.54 mg/l" {...treeStyles} />
          </TreeNode>
        </TreeNode>
      </Tree>
    </div>
  );
};

export default TempComponent;

