import React from 'react';
import './PopupMenu.css';

const PopupMenu = ({ user, onSignOut }) => {
  return (
    <div className="popup-menu">
      {user?.user_metadata.display_name ? <p>{user?.user_metadata.display_name}</p> : null}
      <p style={{ color: 'gray', marginTop: user?.user_metadata.display_name ? "0px" : "10px" }}>{user?.email}</p>
      <button className="profile-popup-menu-button" onClick={onSignOut}>Sign Out</button>
    </div>
  );
};

export default PopupMenu;