import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../services/supabaseClient';
import PopupMenu from './PopupMenu';
import "./Profile.css";
import { User } from 'react-feather';


const Profile = () => {
  const [user, setUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error getting session:', error);
      } else if (session) {
        setUser(session.user);
      }
    };

    getSession();
  }, []);

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
    } else {
      sessionStorage.removeItem('supabaseSession');
      navigate('/signin');
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="profile-container">
      <button className="profile-button" onClick={togglePopup}><User className="icon"/></button>
      {showPopup && <PopupMenu user={user} onSignOut={handleSignOut} />}
    </div>
  );
};

export default Profile;