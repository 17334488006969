import React from 'react';
import { Link } from 'react-router-dom';

function TopNav() {
  return (
    <nav className="top-nav">
      <img src="/logo_home.png" alt="Logo" style={{ marginLeft: "40px", marginRight: "auto", width: "160px" }} onClick={() => window.location.href = "https://www.shadowresearch.co/"} />
      <ul>
        {/* <li><Link to="/about" style={{ color: "white" }}>About</Link></li> */}
        <li className="start-button" style={{border: '1px solid #000'}}><Link to="/signin" style={{ color: "white" }}>Login</Link></li>

      </ul>
    </nav>
  );
}

export default TopNav;