import React, { useState, useEffect, useRef } from 'react';
import { sendToBackend } from '../api';
import './SaveThought.css'; // Make sure this path is correct
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import { useMyContext } from '../contexts/Context';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],  // Add font size dropdown
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']  // Remove formatting button
  ],
};

function SaveThought({state, id, update}) {
  const [input, setInput] = useState('');
  const [isSaved, setIsSaved] = useState(false); 
  const [showTitleInput, setShowTitleInput] = useState(false); 
  const [title, setTitle] = useState(""); 
  const { brain } = useMyContext();
  const timeoutRef = useRef(null);


  useEffect(() => {
    setInput(state);
  }, [state]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      update(id, input);
    }, 2000); // 2 seconds

    return () => clearTimeout(timeoutRef.current);
  }, [input]);

  const handleTitleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setShowTitleInput(false); // Hide the title input box
      handleSave(); // Proceed with saving
    }
  };

  const handleClick = () => {
    console.log("logging input:\n\n")
    console.log(input)

    sendToBackend(input, title, "text", brain)
      .then(response => {
        console.log(response.data);
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 3000);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSave = () => {
    if (!title) {
      setShowTitleInput(true); // Show the title input if no title is set
    } else {
      handleClick(); // If a title is already set, proceed with saving
    }
  };

  return (
    <div className='thought-container'>
      {isSaved && (
        <div className="saved-banner">
          Saved
        </div>
      )}
      {showTitleInput && (
        <input
          type="text"
          className="title-input"
          placeholder="Enter title..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onKeyDown={handleTitleKeyDown}
          autoFocus
        />
      )}
      {/* <button className='create-button' onClick={handleSave}>save</button> */}
      <ReactQuill value={input} placeholder="Project details..." onChange={(value) => {
          setInput(value);
        }} 
        modules={modules} 

      />
    </div>
  );
}

export default SaveThought;