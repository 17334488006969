import React, { useEffect, useRef } from 'react';
import { deleteBrain } from '../services/brainService'; // Adjust the import path as necessary
import './BrainPopupMenu.css';
import { callDeleteBrain } from '../api';

const BrainPopupMenu = ({ anchorEl, onClose, onDelete, brain, brains }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && anchorEl && !anchorEl.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl, onClose]);

  if (!anchorEl) return null;

  const { top, left, height } = anchorEl.getBoundingClientRect();

  const handleDelete = async () => {
    const brainToDelete = brains.find(b => b.title === brain);
    if (brainToDelete) {
      const success = await deleteBrain(brainToDelete.id);
      let user_id = brainToDelete.user_id;
      let id = brainToDelete.id;
      const concat = id + '-' + user_id;
      callDeleteBrain(concat);
      if (success) {
        onClose();
      } else {
        console.error('Failed to delete brain');
      }
    } else {
      console.error('Brain not found');
    }
  };

  return (
    <div ref={menuRef} className="brain-popup-menu" style={{ top: top + height, left }}>
      <button className='publish-button'>Publish</button>
      <button className='delete-button'onClick={handleDelete}>Delete</button>

    </div>
  );
};

export default BrainPopupMenu;