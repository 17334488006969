export const fetchPaperData = async (id) => {
    // Simulating an API delay
    await new Promise(resolve => setTimeout(resolve, 500));
  
    // Mock data
    const papers = {
      'the-empirical-impact-of-neural-parameter-symmetries-or-lack-thereof': {
        id: 'the-empirical-impact-of-neural-parameter-symmetries-or-lack-thereof',
        title: 'The Empirical Impact of Neural Parameter Symmetries, or Lack Thereof',
        authors: ['Derek Lim', 'Moe Putterman', 'Robin Walters', 'Haggai Maron', 'Stefanie Jegelka'],
        abstract: 'This paper investigates the impact of neural parameter symmetries by introducing new neural network architectures with reduced parameter space symmetries. The researchers developed two methods to modify standard neural networks:\n\n**W-Asymmetric networks:** Fix certain elements of each linear map to break symmetries in the computation graph.\n\n**σ-Asymmetric networks:** Use a new nonlinearity (FiGLU) that doesn\'t act elementwise, reducing symmetries.\n\nThe study examines the effects of these modifications on various aspects of neural network behavior, including **linear mode connectivity**, **Bayesian deep learning**, **metanetworks**, and **monotonic linear interpolation**.',
        pdfFileName: '1.pdf',
        sections: [
          {
            title: 'Summary',
            className: 'summary',
            type: 'list',
            description: 'This paper investigates the impact of neural parameter symmetries by introducing new neural network architectures with reduced parameter space symmetries. The key insights and findings are:',
            items: [
              'Parameter symmetries in neural networks can affect various phenomena, including linear mode connectivity, Bayesian neural network inference, and loss landscape geometry.',
              'The researchers developed two methods to reduce parameter symmetries:',
              {
                type: 'sublist',
                items: [
                  'W-Asymmetric networks: Fix certain elements of each linear map to break symmetries in the computation graph.',
                  'σ-Asymmetric networks: Use a new nonlinearity (FiGLU) that doesnt act elementwise, reducing symmetries.'
                ]
              },
              'Linear Mode Connectivity: Asymmetric networks, especially W-Asymmetric networks, showed better linear interpolation between independently trained models without the need for parameter alignment.',
              'Bayesian Neural Networks: Using W-Asymmetric networks as the base model improved training speed, convergence, and performance in Bayesian neural networks.',
              'Metanetworks: Predicting the performance of W-Asymmetric networks was easier for metanetworks compared to standard networks.',
              'Monotonic Linear Interpolation: W-Asymmetric networks demonstrated more monotonic and convex trajectories in the loss landscape between initialization and trained parameters.',
              'The study suggests that removing parameter symmetries can lead to more well-behaved loss landscapes and improved performance in various aspects of deep learning.'
            ]
          },
          {
            title: 'Linear Mode Connectivity',
            className: 'linear-mode-connectivity',
            type: 'graph',
            description: 'Test loss interpolation barriers at midpoint for MLPs on MNIST',
            data: [
              { model: 'Standard', barrier: 0.195 },
              { model: 'Git-ReBasin', barrier: 0.01 },
              { model: 'σ-Asym', barrier: 0.12 },
              { model: 'W-Asym', barrier: 0.005 },
            ],
            xDataKey: 'model',
            bars: [{ dataKey: 'barrier', color: '#8884d8', name: 'Barrier' }],
            footer: 'Lower barriers indicate better linear mode connectivity. W-Asymmetric networks show the best performance.'
          },
          {
            title: 'Bayesian Neural Networks',
            className: 'bayesian-neural-networks',
            type: 'graph',
            description: 'Test accuracy for Bayesian MLPs on CIFAR-10',
            data: [
              { model: 'Standard MLP-4', accuracy: 78.5 },
              { model: 'W-Asym MLP-4', accuracy: 79.2 },
              { model: 'Standard MLP-16', accuracy: 80.1 },
              { model: 'W-Asym MLP-16', accuracy: 82.3 },
            ],
            xDataKey: 'model',
            bars: [{ dataKey: 'accuracy', color: '#82ca9d', name: 'Accuracy' }],
            footer: 'W-Asymmetric networks show improved performance, especially for deeper networks (MLP-16).'
          },
          {
            title: 'Metanetworks',
            className: 'metanetworks',
            type: 'graph',
            description: 'R² scores for predicting test accuracy of ResNets',
            data: [
              { metanetwork: 'MLP', standard: 0.75, asymmetric: 0.82 },
              { metanetwork: 'CNN', standard: 0.78, asymmetric: 0.85 },
              { metanetwork: 'Transformer', standard: 0.80, asymmetric: 0.88 },
            ],
            xDataKey: 'metanetwork',
            bars: [
              { dataKey: 'standard', color: '#8884d8', name: 'Standard ResNet' },
              { dataKey: 'asymmetric', color: '#82ca9d', name: 'W-Asym ResNet' }
            ],
            footer: 'Metanetworks perform better at predicting the accuracy of W-Asymmetric ResNets compared to standard ResNets.'
          },
          {
            title: 'Monotonic Linear Interpolation',
            className: 'monotonic-linear-interpolation',
            type: 'graph',
            description: 'Properties of linear interpolations between initialization and trained parameters',
            data: [
              { property: 'Monotonicity', standard: 0.65, asymmetric: 0.85 },
              { property: 'Convexity', standard: 0.55, asymmetric: 0.75 },
              { property: 'Smoothness', standard: 0.70, asymmetric: 0.80 },
            ],
            xDataKey: 'property',
            bars: [
              { dataKey: 'standard', color: '#8884d8', name: 'Standard ResNet' },
              { dataKey: 'asymmetric', color: '#82ca9d', name: 'W-Asym ResNet' }
            ],
            footer: 'W-Asymmetric ResNets show more monotonic and convex linear interpolations from initialization to trained parameters.'
          }
        ]
      },
      'let-your-graph-do-the-talking-encoding-structured-data-for-llms': {
        id: 'let-your-graph-do-the-talking-encoding-structured-data-for-llms',
        title: 'Let Your Graph Do the Talking: Encoding Structured Data for LLMs',
        authors: ['Bryan Perozzi', 'Bahare Fatemi', 'Dustin Zelle', 'Anton Tsitsulin', 'Mehran Kazemi', 'Rami Al-Rfou', 'Jonathan Halcrow'],
        abstract: 'This paper introduces GraphToken, a parameter-efficient method to explicitly represent structured data for large language models (LLMs). The method learns an encoding function to extend prompts with explicit structured information. The study shows that explicitly representing graph structure allows significant improvements to graph reasoning tasks, with up to 73% points improvement on node, edge, and graph-level tasks from the GraphQA benchmark.',
        pdfFileName: 'graph_talking.pdf',
        sections: [
          {
            title: 'Summary',
            className: 'summary',
            type: 'list',
            description: 'This paper introduces GraphToken, a method for encoding structured data for use in large language models. The key insights and findings are:',
            items: [
              'GraphToken is a parameter-efficient method to explicitly represent structured data for LLMs.',
              'The method learns an encoding function to extend prompts with explicit structured information.',
              'GraphToken significantly improves graph reasoning tasks compared to existing methods.',
              'The study evaluates various graph convolution architectures and their impact on different tasks.',
              'Breaking equivariance in graph encoders can enhance graph reasoning capabilities.',
              'GraphToken demonstrates superior performance across graph-level, node-level, and edge-level tasks in the GraphQA benchmark.',
              'The approach is parameter-efficient, adding only a small number of parameters compared to the LLM.'
            ]
          },
          {
            title: 'Performance Comparison',
            className: 'performance-comparison',
            type: 'graph',
            description: 'Accuracy comparison of GraphToken vs baselines on selected GraphQA tasks',
            data: [
              { task: 'Node count', baseline: 0.276, graphtoken: 0.996 },
              { task: 'Cycle check', baseline: 0.832, graphtoken: 0.956 },
              { task: 'Node degree', baseline: 0.292, graphtoken: 0.962 },
              { task: 'Edge existence', baseline: 0.544, graphtoken: 0.738 }
            ],
            xDataKey: 'task',
            bars: [
              { dataKey: 'baseline', color: '#8884d8', name: 'Best Baseline' },
              { dataKey: 'graphtoken', color: '#82ca9d', name: 'GraphToken' }
            ],
            footer: 'GraphToken outperforms baselines across various graph reasoning tasks.'
          },
          {
            title: 'Graph Encoder Comparison',
            className: 'graph-encoder-comparison',
            type: 'graph',
            description: 'Performance of different graph encoders on the Node count task',
            data: [
              { encoder: 'GCN', accuracy: 0.746 },
              { encoder: 'GIN', accuracy: 0.704 },
              { encoder: 'MPNN', accuracy: 0.792 },
              { encoder: 'MHA', accuracy: 0.912 },
              { encoder: 'Node Set', accuracy: 0.996 }
            ],
            xDataKey: 'encoder',
            bars: [{ dataKey: 'accuracy', color: '#8884d8', name: 'Accuracy' }],
            footer: 'Different graph encoders show varying performance across tasks.'
          },
          {
            title: 'Feature Encoding Comparison',
            className: 'feature-encoding-comparison',
            type: 'graph',
            description: 'Impact of different node featurization settings',
            data: [
              { setting: 'LPE', performance: 0.8 },
              { setting: 'IDX', performance: 0.85 },
              { setting: 'LPE+IDX', performance: 0.9 }
            ],
            xDataKey: 'setting',
            bars: [{ dataKey: 'performance', color: '#82ca9d', name: 'Relative Performance' }],
            footer: 'Combining learned and spectral features (LPE+IDX) often yields the best performance.'
          },
          {
            title: 'Parameter Efficiency',
            className: 'parameter-efficiency',
            type: 'graph',
            description: 'Number of parameters in graph encoder vs LLM',
            data: [
              { component: 'Graph Encoder', params: 200000 },
              { component: 'LLM', params: 70000000000 }
            ],
            xDataKey: 'component',
            bars: [{ dataKey: 'params', color: '#8884d8', name: 'Parameters' }],
            footer: 'GraphToken adds a negligible number of parameters compared to the LLM.'
          }
        ]
      },
      'simulating-500-million-years-of-evolution-with-a-language-model': {
        id: 'simulating-500-million-years-of-evolution-with-a-language-model',
        title: 'Simulating 500 million years of evolution with a language model',
        authors: ['Thomas Hayes', 'Roshan Rao', 'Halil Akin', 'Nicholas J. Sofroniew', 'Deniz Oktay', 'Zeming Lin', 'Robert Verkuil', 'Vincent Q. Tran', 'Jonathan Deaton', 'Marius Wiggert', 'Rohil Badkundri', 'Irhum Shafkat', 'Jun Gong', 'Alexander Derry', 'Raul S. Molina', 'Neil Thomas', 'Yousuf Khan', 'Chetan Mishra', 'Carolyn Kim', 'Liam J. Bartie', 'Matthew Nemeth', 'Patrick D. Hsu', 'Tom Sercu', 'Salvatore Candido', 'Alexander Rives'],
        abstract: 'More than three billion years of evolution have produced an image of biology encoded into the space of natural proteins. Here we show that language models trained on tokens generated by evolution can act as evolutionary simulators to generate functional proteins that are far away from known proteins. We present ESM3, a frontier multimodal generative language model that reasons over the sequence, structure, and function of proteins. ESM3 can follow complex prompts combining its modalities and is highly responsive to biological alignment. We have prompted ESM3 to generate fluorescent proteins with a chain of thought. Among the generations that we synthesized, we found a bright fluorescent protein at far distance (58% identity) from known fluorescent proteins. Similarly distant natural fluorescent proteins are separated by over five hundred million years of evolution.',
        pdfFileName: '500M_years_evolution.pdf',
        sections: [
        {
        title: 'Summary',
        className: 'summary',
        type: 'list',
        description: 'This paper introduces ESM3, a multimodal generative language model for proteins, and demonstrates its ability to generate functional proteins far from known sequences. Key findings include:',
        items: [
        'ESM3 is a frontier multimodal generative language model that reasons over protein sequence, structure, and function.',
        'The model can follow complex prompts combining different modalities and is highly responsive to biological alignment.',
        'ESM3 generated a bright fluorescent protein (esmGFP) with only 58% sequence identity to known fluorescent proteins.',
        'The generated protein is estimated to be over 500 million years of evolution removed from the closest known protein.',
        'The study demonstrates that language models can act as evolutionary simulators, exploring protein space beyond natural evolution.',
        'ESM3 shows improved capabilities in protein design, structure prediction, and following complex prompts compared to previous models.'
        ]
        },
        {
        title: 'ESM3 Architecture',
        className: 'esm3-architecture',
        type: 'graph',
        description: 'ESM3 model sizes and performance',
        data: [
        { model: 'ESM3 1.4B', parameters: 1.4, lddt: 0.807 },
        { model: 'ESM3 7B', parameters: 7, lddt: 0.870 },
        { model: 'ESM3 98B', parameters: 98, lddt: 0.895 },
        { model: 'ESMFold', parameters: 3, lddt: 0.865 },
        ],
        xDataKey: 'model',
        bars: [{ dataKey: 'lddt', color: '#8884d8', name: 'LDDT Score' }],
        footer: 'LDDT scores for structure prediction on CAMEO test set. ESM3 98B outperforms ESMFold.'
        },
        {
        title: 'Protein Generation',
        className: 'protein-generation',
        type: 'graph',
        description: 'Unconditional protein generation quality',
        data: [
        { metric: 'pTM > 0.8', esm3: 0.62, esm2: 0.48 },
        { metric: 'pLDDT > 0.8', esm3: 0.78, esm2: 0.65 },
        { metric: 'TM-score > 0.8', esm3: 0.71, esm2: 0.56 },
        ],
        xDataKey: 'metric',
        bars: [
        { dataKey: 'esm3', color: '#8884d8', name: 'ESM3' },
        { dataKey: 'esm2', color: '#82ca9d', name: 'ESM2' }
        ],
        footer: 'ESM3 generates higher quality proteins compared to ESM2 across various metrics.'
        },
        {
        title: 'Fluorescent Protein Generation',
        className: 'fluorescent-protein-generation',
        type: 'graph',
        description: 'Sequence identity of generated fluorescent proteins',
        data: [
        { protein: 'esmGFP', identity: 58 },
        { protein: 'B8', identity: 57 },
        { protein: 'tagRFP', identity: 58 },
        { protein: 'eqFP578', identity: 53 },
        ],
        xDataKey: 'protein',
        bars: [{ dataKey: 'identity', color: '#82ca9d', name: 'Sequence Identity (%)' }],
        footer: 'Sequence identity of ESM3-generated proteins (esmGFP, B8) compared to known fluorescent proteins.'
        },
        {
        title: 'Evolutionary Distance',
        className: 'evolutionary-distance',
        type: 'graph',
        description: 'Estimated evolutionary distance of esmGFP',
        data: [
        { comparison: 'esmGFP vs closest known', distance: 500 },
        { comparison: 'Scleractinia vs Actiniaria', distance: 500 },
        { comparison: 'Within Anthozoa class', distance: 300 },
        ],
        xDataKey: 'comparison',
        bars: [{ dataKey: 'distance', color: '#8884d8', name: 'Evolutionary Distance (MY)' }],
        footer: 'Estimated evolutionary distance in millions of years (MY) for esmGFP and natural protein comparisons.'
        }
        ]
        },
      // ... other papers can be added here
    };
  
    if (papers[id]) {
      return papers[id];
    } else {
      throw new Error('Paper not found');
    }
  };